import { Redirect, useLocation } from '@reach/router';
import { useEffect } from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { FindJobsSearchBar } from '@xing-com/crate-jobs-pages-find-jobs-search-bar';
import { Head } from '@xing-com/crate-startpage-logged-out-head';
import { NativeAppsSection } from '@xing-com/crate-startpage-logged-out-native-apps-section';
import { SeoSection } from '@xing-com/crate-startpage-logged-out-seo-section';
import { useExperiment } from '@xing-com/hub';
import {
  Container as LayoutPrimitivesContainer,
  Section,
} from '@xing-com/layout-primitives';

import { AdSection } from './ad-section';
import { CompaniesSection } from './companies-section';
import { InspirationalCardsSection } from './inspirational-cards-section';
import { JobsSection } from './jobs-section';
import { PreferencesSection } from './preferences-section';
import { RecruitersSection } from './recruiters-section';
import { RegisterSection } from './register-section';
import {
  Container,
  Wrapper,
  StyledAiSearchBanner,
} from './startpage-logged-out.styles';
import { TeaserSection } from './teaser-section';
import {
  useTrackPageView,
  useTrackClickStoreButton,
  useTrackClickSerpCategory,
} from './tracking';

const rootLanguages = ['de', 'en', 'es', 'fr', 'it'];

const checkLanguageRootPaths = (pathname: string): string | undefined => {
  return rootLanguages.find((language) => {
    return [`/${language}`, `/${language}/`].includes(pathname);
  });
};

export const StartPageLoggedOut: React.FC = () => {
  const { isLoggedOut } = useLoginState();
  const trackClickStoreButton = useTrackClickStoreButton();
  const trackClickSerpCategory = useTrackClickSerpCategory();
  const { pathname } = useLocation();
  const trackPageView = useTrackPageView();
  const isAiSearchEnabled = useExperiment('ABACUS-443') === 'B';

  useEffect(() => {
    if (isLoggedOut) {
      trackPageView();
    }
  }, [isLoggedOut, trackPageView]);

  useEffect(() => {
    const newLocale = checkLanguageRootPaths(pathname);

    if (newLocale && rootLanguages.includes(newLocale)) {
      const now = new Date();
      const oneYearFromNowDate = new Date(
        now.setFullYear(now.getFullYear() + 1)
      );

      window.document.cookie = `language=${newLocale}; domain=.xing.com; expires=${oneYearFromNowDate.toUTCString()}`;
      window.document.cookie = `language=${newLocale}; expires=${oneYearFromNowDate.toUTCString()}`;
    }
  }, [pathname]);

  if (!isLoggedOut) {
    return <Redirect to="/jobs/find" />;
  } else {
    return (
      <>
        <Head page="startPage" />
        {isAiSearchEnabled ? (
          <StyledAiSearchBanner
            propActionOrigin="losp_job_search"
            scOParam="jobs_ai_assisted_search"
            nwtTracking={{
              page: 'welcome/start/index',
              sentBy: 'welcome',
            }}
          />
        ) : null}
        <Container>
          <FindJobsSearchBar
            withFilters
            withTeaser
            spacious
            isStartPage
            withKeywords
          />
          <JobsSection />
          <CompaniesSection />
          <PreferencesSection />
          <RecruitersSection />
          <Section noBlockPadding>
            <LayoutPrimitivesContainer variant="expanded">
              <Wrapper>
                <AdSection />
                <InspirationalCardsSection />
                <RegisterSection />
                <TeaserSection />
                <NativeAppsSection
                  trackClickStoreButton={trackClickStoreButton}
                />
                <SeoSection trackClickSerpCategory={trackClickSerpCategory} />
              </Wrapper>
            </LayoutPrimitivesContainer>
          </Section>
        </Container>
      </>
    );
  }
};
