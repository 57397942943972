import { useQuery } from '@apollo/client';

import { SEARCH_BOX_MAX_RECENT_SEARCHES } from '@xing-com/crate-jobs-constants';
import type { JobRecentSearchFragment } from '@xing-com/crate-jobs-graphql';
import { GetRecentSearchesDocument } from '@xing-com/crate-jobs-graphql';

type UseRecentSearchesArgs = { skip?: boolean };
type UseRecentSearchesResult = {
  isLoading: boolean;
  recentSearches: (JobRecentSearchFragment | null)[] | null | undefined;
};

export const useRecentSearches = ({
  skip,
}: UseRecentSearchesArgs): UseRecentSearchesResult => {
  const { data, loading } = useQuery(GetRecentSearchesDocument, {
    variables: {
      consumer: 'loggedin.web.jobs.search',
      limit: SEARCH_BOX_MAX_RECENT_SEARCHES,
    },
    skip,
  });

  return {
    isLoading: loading,
    recentSearches: data?.viewer?.jobRecentSearches?.searches,
  };
};
