import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { mediaTopBarHidden } from '@xing-com/layout-tokens';

/**
 * Returns `true` if the viewport size is one where the top bar is usually
 * hidden.
 *
 * **Caveats:**
 *   - It only checks browser window width and does not take into account the
 *     `showOnLargerScreens` prop or any other custom logic to show/hide the
 *     top bar.
 *   - It does not work on the server side, where it always returns `false`.
 *     Please prefer using media queries in CSS.
 */
export const useIsTopBarHidden = (): boolean => {
  const media = mediaTopBarHidden;

  const matches = useMatchMedia(media);

  return matches;
};
