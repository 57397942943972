import { FormattedMessage } from 'react-intl';

import { useBreakpoints } from '@xing-com/crate-jobs-hooks';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCross,
  IconTrash,
} from '@xing-com/icons';

import * as S from './action-buttons.styles';

type Props = {
  isBookmarked?: boolean;
  iconsOnly?: boolean;
  iconsAfter?: boolean;
  bottom?: boolean;
  onBookmark?: () => void;
  onDelete?: () => void;
  variant?: string;
  size?: string;
  noMargin?: boolean;
  className?: string;
};

export const ActionButtons: React.FC<Props> = ({
  isBookmarked,
  iconsOnly,
  iconsAfter,
  bottom,
  onBookmark,
  onDelete,
  variant = 'text',
  size = 'medium',
  noMargin = false,
  className,
}) => {
  const { isMediaSmallOrTiny } = useBreakpoints();

  const handleOnBookmark = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    onBookmark?.();
  };

  const handleOnDelete = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (onDelete) {
      onDelete();
    }
  };

  const ButtonComponent =
    variant === 'text' ? S.BottomTextButton : S.BottomButton;
  const buttonVariantProps = {
    ...(variant === 'text' ? { fontWeight: 'regular' } : { variant }),
  };

  return (
    <S.ActionButtons
      $isBottom={bottom}
      $noMargin={noMargin}
      className={className}
    >
      {onBookmark ? (
        // @ts-expect-error FIXME: SC6
        <ButtonComponent
          size={size}
          icon={isBookmarked ? IconBookmarkFilled : IconBookmark}
          iconAfter={iconsAfter}
          onClick={handleOnBookmark}
          {...buttonVariantProps}
          data-testid={
            isBookmarked
              ? 'bookmark-action-button-filled'
              : 'bookmark-action-button'
          }
        >
          {iconsOnly ? null : isBookmarked ? (
            <FormattedMessage id="JOBS_JOB_TEASER_SAVED" />
          ) : (
            <FormattedMessage id="JOBS_JOB_TEASER_SAVE" />
          )}
        </ButtonComponent>
      ) : null}

      {onDelete ? (
        // @ts-expect-error FIXME: SC6
        <ButtonComponent
          size={size}
          {...(variant === 'text' ? { fontWeight: 'regular' } : { variant })}
          icon={isMediaSmallOrTiny ? IconCross : IconTrash}
          iconAfter={iconsAfter}
          onClick={handleOnDelete}
          {...buttonVariantProps}
          $noMargin={isMediaSmallOrTiny}
          $isDelete
        >
          {iconsOnly ? null : <FormattedMessage id="JOBS_JOB_TEASER_REMOVE" />}
        </ButtonComponent>
      ) : null}
    </S.ActionButtons>
  );
};
