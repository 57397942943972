/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconSync = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconSync"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.751 13.716 1.917.572a8.003 8.003 0 0 1-13.669 3.004L6 20H4v-6h6v2l-2.472.001a6.003 6.003 0 0 0 10.224-2.285M20 4v6h-6V8h2.473a6.003 6.003 0 0 0-10.23 2.302l-1.918-.564a8.003 8.003 0 0 1 13.677-3.029L18 4z"
    />
  </svg>
);
export default IconSync;
