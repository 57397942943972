import { FormattedMessage } from 'react-intl';

import { BodyCopy } from '@xing-com/typography';

import * as S from './filters.styles';

type Props = {
  on: boolean;
  copyId: string;
  onClick: () => void;
};

export const Filter: React.FC<Props> = ({ on, onClick, copyId }) => (
  <S.Label>
    <S.Checkbox on={on} onClick={onClick} />
    <BodyCopy noMargin>
      <FormattedMessage id={copyId} />
    </BodyCopy>
  </S.Label>
);
