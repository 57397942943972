import styled, { css } from 'styled-components';

import { Carousel as BreweryCarousel } from '@xing-com/carousel';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  scale110,
  scale130,
  scale180,
  spaceXS,
  spaceS,
  spaceXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Hero, Headline, BodyCopy } from '@xing-com/typography';

import { CarouselControls as BaseCarouselControls } from './carousel-controls';

type HeaderProps = {
  $headerSize?: 'hero' | 'medium';
};

export const Styled = {
  Header: styled.header<HeaderProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ $headerSize }) => css`
      margin-bottom: ${$headerSize === 'medium' ? spaceS : scale180};

      @media ${mediaConfined} {
        flex-direction: row;
        margin-bottom: ${$headerSize === 'medium' ? spaceXL : scale180};
      }
    `}
  `,

  HeaderContent: styled.div`
    display: flex;
    align-items: center;
    gap: ${spaceS};
  `,

  TitleHero: styled(Hero)`
    font-size: ${scale110};
    margin-bottom: ${spaceXS};

    @media ${mediaConfined} {
      font-size: ${scale130};
    }
  `,

  Subtitle: styled(BodyCopy)`
    margin: ${spaceXS} 0 0;
    color: ${xdlColorTextSecondary};
  `,

  SubtitleHero: styled(Headline)`
    font-weight: 400;
    color: ${xdlColorTextSecondary};
  `,

  CarouselControls: styled(BaseCarouselControls)`
    align-self: flex-end;
  `,

  Carousel: styled(BreweryCarousel)`
    &::-webkit-scrollbar {
      display: none;
    }

    max-width: 100vw;
    scrollbar-width: none;
  `,
};
