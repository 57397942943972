import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';
import { Column, Row } from '@xing-com/xing-grid';

import { useTrackSignUpGoogle } from '../../tracking';
import { IconGoogle } from './icon-google';
import {
  Title,
  Subtitle,
  ButtonsContainer,
  RegisterButtonsWrapper,
} from './register-buttons-subsection.styles';

export const RegisterButtonsSubsection: React.FC = () => {
  const trackSignUpGoogle = useTrackSignUpGoogle();
  const { formatMessage } = useIntl();

  const urlWithoutTracking = '/start/signup';
  const urlWithTracking = `${urlWithoutTracking}?sc_o=losp_sign_up_button_click&sc_o_PropActionOrigin=losp_sign_up_button_click_cta_1&signup_channel=minireg_losp`;

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(urlWithTracking);
  };

  const onClickStoreButton = (): void => {
    trackSignUpGoogle();
  };

  return (
    <Row>
      <Column size={12}>
        <Title sizeConfined="medium" size="small" forwardedAs="h2">
          {formatMessage({ id: 'RECRUITERS_SECTION_FIRST_TITLE' })}
        </Title>
        <Subtitle size="xxlarge" forwardedAs="p">
          {formatMessage({ id: 'RECRUITERS_SECTION_FIRST_SUBTITLE' })}
        </Subtitle>
        <ButtonsContainer>
          <RegisterButtonsWrapper>
            <a
              href={urlWithoutTracking}
              onClick={handleAnchorClick}
              tabIndex={-1}
            >
              <Button variant="primary" size="large">
                {formatMessage({
                  id: 'RECRUITERS_SECTION_REGISTER_BUTTON',
                })}
              </Button>
            </a>
            <Button
              variant="secondary"
              icon={IconGoogle}
              to={
                '/signup/oauth?caller=losp&signup_channel=minireg_losp_oauth_google'
              }
              onClick={onClickStoreButton}
              size="large"
            >
              {formatMessage({
                id: 'RECRUITERS_SECTION_REGISTER_WITH_GOOGLE_BUTTON',
              })}
            </Button>
          </RegisterButtonsWrapper>
        </ButtonsContainer>
      </Column>
    </Row>
  );
};
