export default {
  startPageTitle: {
    id: 'LOGGED_OUT_STARTPAGE_META_TITLE_JOB_SEARCH',
  },
  startPageDescription: {
    id: 'LOGGED_OUT_STARTPAGE_META_DESCRIPTION_JOB_SEARCH',
  },
  startPageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
  },
  languagePageTitle: {
    id: 'WELCOME_LANGUAGE_PAGE_META_TITLE',
  },
  languagePageDescription: {
    id: 'LOGGED_OUT_STARTPAGE_META_DESCRIPTION_JOB_SEARCH',
  },
  languagePageSiteName: {
    id: 'WELCOME_STARTPAGE_OG_SITENAME',
  },
};
