import type { StyledComponent } from 'styled-components';
import styled from 'styled-components';

import {
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
  spaceXXL,
  spaceXXS,
} from '@xing-com/tokens';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnWithGap = (
  gap: string
): StyledComponent<'div', object, object, never> => styled(Col)`
  gap: ${gap};
`;

export const XxsGapCol = ColumnWithGap(spaceXXS);
export const XsGapCol = ColumnWithGap(spaceXS);
export const SGapCol = ColumnWithGap(spaceS);
export const MGapCol = ColumnWithGap(spaceM);
export const LGapCol = ColumnWithGap(spaceL);
export const XlGapCol = ColumnWithGap(spaceXL);
export const XxlGapCol = ColumnWithGap(spaceXXL);
