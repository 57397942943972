import type React from 'react';

import { InspirationalCard } from './inspirational-card';
import { INSPIRATIONAL_CARDS_DATA } from './inspirational-cards-data';
import { StyledRow } from './inspirational-cards-list.styles';

export const InspirationalCardsList: React.FC = () => (
  <StyledRow>
    {INSPIRATIONAL_CARDS_DATA.map((data, index) => (
      <div key={`card-${index}`}>
        <InspirationalCard {...data} />
      </div>
    ))}
  </StyledRow>
);
