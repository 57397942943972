import { useAds, Ad, SiteSections } from '@xing-com/crate-ads-native-ads';
import { AdTypes } from '@xing-com/crate-common-graphql-types';

import { StyledRow, StyledColumn } from './ad-section.styles';

export const AdSection: React.FC = () => {
  const { ads, isCompleted } = useAds({
    adCount: 1,
    adTypes: [AdTypes.WebsiteAd],
    channel: 'jobs_startpage_web_lo',
    isLoggedOut: true,
  });

  if (!isCompleted || !ads.length) {
    return null;
  }

  return (
    <StyledRow>
      <StyledColumn sizeWide={10} offsetWide={1} sizeConfined={12} size={12}>
        <Ad {...ads[0]} sectionId={SiteSections.StartPage} isLoggedOut />
      </StyledColumn>
    </StyledRow>
  );
};
