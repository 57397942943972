import styled, { css } from 'styled-components';

import { AutoComplete } from '@xing-com/auto-complete';
import { Button, TextButton } from '@xing-com/button';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaTopBarVisible,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { Menu, SingleSelect as BaseSingleSelect } from '@xing-com/menu';
import {
  scale080,
  scale090,
  scale150,
  scale180,
  scale280,
  spaceL,
  spaceM,
  spaceXL,
  xdlColorBorderStrong,
  xdlColorButtonStrong,
  xdlColorButtonStrongHover,
  xdlColorDisabledStrong,
  xdlColorTextInvert,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorTextTertiaryOnLight,
  xdlPaletteGrayPlain02,
  xdlPaletteGrayPlain04,
  xdlPaletteGrayPlain11,
  xdlPaletteWhite,
} from '@xing-com/tokens';

export const Form = styled.form<{
  $condensedOnMobile?: boolean;
}>`
  padding-left: 20px;
  padding-right: 20px;
  justify-items: stretch;
  align-items: center;
  gap: ${spaceL};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'key key'
    'loc but';

  ${({ $condensedOnMobile }) =>
    $condensedOnMobile &&
    css`
      @media ${mediaSmallOrTiny} {
        grid-template-columns: auto;
        grid-template-areas:
          'key'
          'loc';
      }

      @media ${mediaTopBarVisible} {
        gap: ${spaceM};
      }
    `};

  @media ${mediaWideNavless} {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'key loc but';
  }
`;

export const KeywordsAutoComplete = styled(AutoComplete)<{
  $condensedOnMobile?: boolean;
}>`
  grid-area: key;

  // Chrome shows an extra native "X" button in the input field
  & input::-webkit-search-cancel-button {
    display: none;
  }

  @media ${mediaTopBarVisible} {
    ${({ $condensedOnMobile }) =>
      $condensedOnMobile &&
      css`
        & input {
          height: ${scale150};
        }
      `}
  }
`;

export const LocationFieldsWrapper = styled.div`
  grid-area: loc;
  display: flex;
`;

export const LocationAutoComplete = styled(AutoComplete)<{
  $withFilters?: boolean;
  $condensedOnMobile?: boolean;
}>`
  && {
    flex: 1 1 auto;

    & ul {
      width: ${({ $withFilters, $condensedOnMobile }) =>
        $withFilters || $condensedOnMobile
          ? '100%'
          : `calc(100% + ${spaceL} + ${scale180})`};
    }

    & input::-webkit-search-cancel-button {
      display: none;
    }

    @media ${mediaConfined} {
      width: calc(100% - ${scale280});

      & ul {
        width: calc(100% + ${scale280});
      }

      & input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid ${xdlPaletteGrayPlain04};

        &:hover,
        &:focus {
          border-right: 1px solid ${xdlColorBorderStrong};
        }
      }
    }

    @media ${mediaTopBarVisible} {
      ${({ $condensedOnMobile }) =>
        $condensedOnMobile &&
        css`
          & input {
            height: ${scale150};
          }
        `}
    }
  }
`;

export const RadiusWrapper = styled.div`
  display: none;
  width: ${scale280};
  position: relative;

  @media ${mediaConfined} {
    display: block;
  }
`;

export const RadiusButton = styled(TextButton)<{
  $isFilled: boolean;
  $condensedOnMobile: boolean;
}>`
  && {
    height: ${scale180};
    font-size: ${scale090};
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0 20px;
    background-color: ${xdlPaletteWhite};
    color: ${xdlColorTextOnLight};
    border: 1px solid transparent;

    &:disabled > div {
      & > :last-child {
        color: ${xdlColorDisabledStrong};
      }

      & > :first-child {
        color: ${xdlColorDisabledStrong};
      }
    }

    &:hover {
      background-color: ${xdlPaletteGrayPlain02};
      border: 1px solid ${xdlColorBorderStrong};
    }

    & > div {
      width: 100%;
      justify-content: space-between;

      ${({ $isFilled }) =>
        !$isFilled &&
        css`
          & > :last-child {
            color: ${xdlColorTextTertiaryOnLight};
          }
        `}
    }

    @media ${mediaTopBarVisible} {
      ${({ $condensedOnMobile }) =>
        $condensedOnMobile &&
        css`
          height: ${scale150};
        `}
    }
  }
`;

export const RadiusMenu = styled(Menu)`
  min-width: 100%;
  white-space: nowrap;
  right: 0;
`;

export const SingleSelect = styled(BaseSingleSelect)`
  div {
    width: 100%;
    justify-content: space-between;
  }
`;

export const SubmitButton = styled(Button)`
  grid-area: but;
  color: ${xdlColorTextInvert};
  flex-shrink: 0;
  height: ${scale180};

  &:after {
    background-color: ${xdlColorButtonStrong};
  }

  &:hover:after {
    background-color: ${xdlColorButtonStrongHover};
  }

  &:active {
    color: ${xdlColorTextOnDark};

    &:after {
      background-color: ${xdlPaletteGrayPlain11};
    }
  }
`;

export const SubmitButtonBig = styled(SubmitButton)<{
  $withFilters?: boolean;
  $condensedOnMobile?: boolean;
}>`
  display: none;
  font-size: ${scale090};
  padding-inline: ${spaceXL};

  @media ${mediaConfined} {
    display: block;
  }

  @media ${mediaTopBarVisible} {
    ${({ $condensedOnMobile }) =>
      $condensedOnMobile &&
      css`
        height: ${scale150};
        font-size: ${scale080};
      `}
  }
`;

export const SubmitButtonSmall = styled(SubmitButton)<{
  $withFilters?: boolean;
  $condensedOnMobile?: boolean;
}>`
  display: ${({ $condensedOnMobile }) =>
    $condensedOnMobile ? 'none' : 'block'};
  padding-inline: ${scale090};

  @media ${mediaConfined} {
    display: none;
  }
`;
