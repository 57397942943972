import type { AdAuctionWinnersQuery } from '../auction-ads.gql-types';
import type { AdFields } from '../types';

export function getAds(data: AdAuctionWinnersQuery | undefined): AdFields[] {
  // Beware: Magical type casting ahead!
  // We will not get an AuctionPageAd or AuctionPostingAd,
  // when triggering the AdAuctionWinnersQuery from crate. Thus we may assume
  // the auction to return an AuctionVideoAd or AuctionWebsiteAd.
  // All those match the type definition of AdFields.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return (data?.adAuctionWinners?.collection || []) as AdFields[];
}
