import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  xdlColorError,
  xdlColorErrorSoft,
  spaceM,
  spaceL,
  scale110,
  spaceXXS,
  spaceS,
  motionTimeM,
  motionEasingEnter,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Banner = styled.div<{ $show?: boolean }>`
  background-color: ${xdlColorErrorSoft};
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  color: ${xdlColorError};
  display: flex;
  left: 0;
  padding-left: ${spaceM};
  padding-right: ${spaceM};
  position: fixed;
  transition: transform ${motionTimeM} ${motionEasingEnter};
  top: 0;
  width: 100%;
  z-index: ${zIndexElevatedLayer4};

  transform: translateY(${({ $show }) => ($show ? '0%' : '-100%')});
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: ${spaceL};
  padding-top: ${spaceL};
`;

export const Icon = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${scale110};
  justify-content: center;
  width: ${scale110};

  & > svg {
    height: ${scale110};
    width: ${scale110};
  }
`;

export const Text = styled(BodyCopy)`
  margin-bottom: 0;
  margin-top: ${spaceXXS};
  max-width: inherit;
  padding-left: ${spaceM};
`;

export const Close = styled(Button)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-left: ${spaceM};
  margin-top: ${spaceS};
  color: ${xdlColorError};
`;
