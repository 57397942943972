import { useMutation } from '@apollo/client';

import { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import type {
  DeleteJobBookmarkMutation,
  JobUpsertBookmarkMutation,
} from '@xing-com/crate-jobs-graphql';
import {
  DeleteJobBookmarkDocument,
  JobUpsertBookmarkDocument,
} from '@xing-com/crate-jobs-graphql';
import { useBookmarkTracking } from '@xing-com/crate-jobs-tracking';
import type {
  NwtTrackingProps,
  OmitFromUnion,
} from '@xing-com/crate-jobs-tracking/src/hooks/use-jobs-nwt-tracking';

type UseBookmarkOptions = {
  jobId: string;
  isBookmarked: boolean;
  nwtTrackingOptions: OmitFromUnion<NwtTrackingProps, 'event'>;
  adobeTrackingOptions?: Record<string, string | number>;
  onCompleted?: () => void;
  onError?: () => void;
  recommendationType?: string;
};

export const useJobBookmarkToggle = ({
  jobId,
  isBookmarked,
  nwtTrackingOptions,
  adobeTrackingOptions,
  onCompleted,
  onError,
  recommendationType,
}: UseBookmarkOptions): (() => void) => {
  const { trackNwtJobsBookmark, trackJobsBookmark } = useBookmarkTracking(
    {
      itemUrn: `surn:x-xing:jobs:posting:${jobId}`,
      ...nwtTrackingOptions,
    },
    adobeTrackingOptions
  );

  const optimisticResponse:
    | JobUpsertBookmarkMutation
    | DeleteJobBookmarkMutation = {
    __typename: 'Mutation',
    [isBookmarked ? 'jobDeleteBookmark' : 'jobUpsertBookmark']: {
      __typename: isBookmarked ? 'JobBookmarkMutationResult' : 'JobBookmark',
      job: {
        __typename: 'VisibleJob',
        id: jobId,
        userInteractions: {
          __typename: 'JobUserInteractions',
          bookmark: {
            __typename: 'JobBookmark',
            state: isBookmarked
              ? JobBookmarkState.NotBookmarked
              : JobBookmarkState.Saved,
          },
        },
      },
    },
  };

  const [upsertBookmarkMutation] = useMutation(JobUpsertBookmarkDocument, {
    variables: { id: jobId, state: JobBookmarkState.Saved },
    optimisticResponse,
    onCompleted,
    onError,
  });
  const [deleteBookmarkMutation] = useMutation(DeleteJobBookmarkDocument, {
    variables: { id: jobId },
    optimisticResponse,
    onCompleted,
    onError,
  });

  const bookmarkMutation = isBookmarked
    ? deleteBookmarkMutation
    : upsertBookmarkMutation;

  return () => {
    bookmarkMutation();
    trackNwtJobsBookmark(isBookmarked);
    trackJobsBookmark(isBookmarked, undefined, recommendationType);
  };
};
