import type { ExtendedContact } from '@xing-com/crate-jobs-helpers';

import { JobsXingletWrapper } from '../../../jobs-xinglet';

type CommonProps = {
  contact?: ExtendedContact;
  hint?: string;
};

type FirstDegreeContactProps = {
  connectionDegree: 1;
  messengerContextId: string;
  entryPoint: string;
};
type SecondDegreeContactProps = {
  connectionDegree: 2;
  messengerContextId?: never;
  entryPoint?: never;
};
export type JobContactProps = CommonProps &
  (FirstDegreeContactProps | SecondDegreeContactProps);

export const JobContact = JobsXingletWrapper<JobContactProps>(
  '@xing-com/crate-jobs-domain-job-details-job-contact'
);
