import { useInternalHost } from '@xing-com/crate-xinglet/internal';

export const useServerData = <T>(key: string, initialValue: T): T => {
  const host = useInternalHost();
  if (host.isServer) {
    host.storeServerData?.(key, () => JSON.stringify(initialValue));
    return initialValue;
  } else {
    const value = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      host.loadServerData?.(key) as string
    );

    return value;
  }
};
