import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BodyCopy } from '@xing-com/typography';

import { SerpsType, SeoData } from './seo-data';
import {
  StyledSection,
  Title,
  SerpTypeButtonContainer,
  SerpTypeButtonWrapper,
  StyledDivider,
  SerpTypeButton,
  StyledAnchor,
} from './seo-section.styles';

type Props = {
  trackClickSerpCategory: (action: string) => void;
  className?: string;
};

const serpTypes = Array.from(SerpsType.keys());

export const SeoSection: React.FC<Props> = ({
  trackClickSerpCategory,
  className,
}) => {
  const { formatMessage } = useIntl();
  const [serpActive, setSerpActive] = useState<string>(serpTypes[0]);

  const handleSerpCategory = (serpType: string): void => {
    setSerpActive(serpType);
    trackClickSerpCategory(SerpsType.get(serpType) ?? '');
  };

  const handleAnchorClick = (
    e: React.MouseEvent,
    url: string,
    trackingParam: string
  ): void => {
    e.preventDefault();

    window.location.assign(`https://xing.com${url}${trackingParam}`);
  };

  return (
    <StyledSection className={className}>
      <Title size="xxlarge">{formatMessage({ id: 'SEO_SECTION_TITLE' })}</Title>
      <SerpTypeButtonContainer>
        {serpTypes.map((serpType) => (
          <SerpTypeButtonWrapper key={serpType}>
            <SerpTypeButton
              size="small"
              onClick={() => handleSerpCategory(serpType)}
              // @ts-expect-error FIXME: SC6
              fontWeight={serpActive === serpType ? '' : 'regular'}
              isActive={serpActive === serpType}
            >
              <FormattedMessage
                id={`LOSP_SEO_SECTION_HEADER_${SerpsType.get(
                  serpType
                )?.toUpperCase()}`}
              />
            </SerpTypeButton>
          </SerpTypeButtonWrapper>
        ))}
      </SerpTypeButtonContainer>
      <StyledDivider />
      {SeoData?.map((serp) =>
        serp ? (
          <StyledAnchor
            href={`https://xing.com${serp.url}`}
            key={`${serp.serpType}-${
              serp.anchorKey ? serp.anchorKey : serp.anchor
            }`}
            onClick={(e) => handleAnchorClick(e, serp.url, serp.trackingParam)}
            isSerpActive={serp.serpType === serpActive}
          >
            <BodyCopy>
              {serp.anchorKey
                ? formatMessage({ id: serp.anchorKey })
                : serp.anchor}
            </BodyCopy>
          </StyledAnchor>
        ) : null
      )}
    </StyledSection>
  );
};
