import styled from 'styled-components';

import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { scale130, scale190 } from '@xing-com/tokens';
import { Row, Column } from '@xing-com/xing-grid';

export const StyledRow = styled(Row)`
  margin-bottom: ${scale190};
  margin-top: ${scale190};

  @media ${mediaSmallOrTiny} {
    padding: 0 !important;
    margin-top: ${scale130};
  }
`;

export const StyledColumn = styled(Column)`
  @media ${mediaSmallOrTiny} {
    padding: 0 !important;
  }
`;
