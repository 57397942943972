import { type FC, type RefObject, useCallback } from 'react';

import { IconButton } from '@xing-com/button';
import { IconArrowLeft, IconArrowRight } from '@xing-com/icons';

export enum CarouselControlsState {
  LEFT_DISABLED,
  RIGHT_DISABLED,
  BOTH_ENABLED,
  BOTH_DISABLED,
}

type CarouselControlsProps = {
  cardSize: number;
  carouselControlsState: CarouselControlsState;
  containerRef: RefObject<HTMLElement>;
  isHandheld: boolean;
  className?: string;
};

export const CarouselControls: FC<CarouselControlsProps> = ({
  cardSize,
  carouselControlsState,
  containerRef,
  isHandheld,
  className,
}) => {
  const handleScroll = useCallback(
    (scrollRight = false) => {
      const container = containerRef.current;
      const carousel = container?.querySelector<HTMLElement>(
        '[data-xds="Carousel"]'
      );
      const scrollWidth = isHandheld
        ? cardSize
        : carousel?.clientWidth || cardSize;

      carousel?.scrollBy({
        top: 0,
        left: scrollRight ? scrollWidth : -scrollWidth,
        behavior: 'smooth',
      });
    },
    [cardSize, containerRef, isHandheld]
  );

  return (
    <nav className={className} aria-label="Carousel Navigation">
      <IconButton
        size="large"
        icon={IconArrowLeft}
        aria-label="previous-button"
        data-qa="carousel-previous"
        onClick={() => handleScroll()}
        disabled={
          carouselControlsState === CarouselControlsState.LEFT_DISABLED ||
          carouselControlsState === CarouselControlsState.BOTH_DISABLED
        }
      />
      <IconButton
        size="large"
        icon={IconArrowRight}
        aria-label="next-button"
        data-qa="carousel-next"
        onClick={() => handleScroll(true)}
        disabled={
          carouselControlsState === CarouselControlsState.RIGHT_DISABLED ||
          carouselControlsState === CarouselControlsState.BOTH_DISABLED
        }
      />
    </nav>
  );
};
