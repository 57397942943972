import { Filter } from './filter';
import * as S from './filters.styles';

type Props = {
  isFullRemote: boolean;
  setIsFullRemote: (value: boolean) => void;
  isPartTime: boolean;
  setIsPartTime: (value: boolean) => void;
  isFlexitime: boolean;
  setIsFlexitime: (value: boolean) => void;
};

export const Filters: React.FC<Props> = ({
  isFullRemote,
  setIsFullRemote,
  isPartTime,
  setIsPartTime,
  isFlexitime,
  setIsFlexitime,
}) => {
  return (
    <S.Container>
      <Filter
        on={isFullRemote}
        onClick={() => setIsFullRemote(!isFullRemote)}
        copyId="JOBS_FIND_JOBS_REMOTE_OPTION_FULL_REMOTE"
      />
      <Filter
        on={isPartTime}
        onClick={() => setIsPartTime(!isPartTime)}
        copyId="JOBS_FIND_JOBS_PART_TIME_OPTION_PART_TIME"
      />
      <Filter
        on={isFlexitime}
        onClick={() => setIsFlexitime(!isFlexitime)}
        copyId="JOBS_FIND_JOBS_FLEXITIME_OPTION_FLEXITIME"
      />
    </S.Container>
  );
};
