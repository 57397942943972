import styled from 'styled-components';

import { Salary } from '@xing-com/crate-jobs-components-salary';
import type {
  JobKeyfactV2Fragment,
  JobMatchingHighlightsV2Fragment,
  JobTeaserVisibleJobFragment,
} from '@xing-com/crate-jobs-graphql';
import { selectJobData } from '@xing-com/crate-jobs-helpers';
import { IconStarFilled } from '@xing-com/icons';
import { Marker } from '@xing-com/marker';
import { spaceXS } from '@xing-com/tokens';

import { FactMarker } from './fact-marker';

export const MarkerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceXS};
`;

type Props = {
  job: JobTeaserVisibleJobFragment;
  matchingHighlights: JobMatchingHighlightsV2Fragment | null | undefined;
  showEmploymentType?: boolean;
  showRatingMarker?: boolean;
};

const hasEmploymentTypeFact = (facts: JobKeyfactV2Fragment[]): boolean =>
  facts.findIndex(
    (fact) =>
      fact.__typename === 'JobMatchingHighlightsJobKeyfactStringV2' &&
      fact.type === 'job_keyfact_employment_type'
  ) !== -1;

const hasSalaryFact = (facts: JobKeyfactV2Fragment[]): boolean =>
  facts.findIndex(
    (fact) => fact.__typename === 'JobMatchingHighlightsJobKeyfactSalaryV2'
  ) !== -1;

export const JobTeaserFacts: React.FC<Props> = ({
  job,
  matchingHighlights,
  showEmploymentType,
  showRatingMarker,
}) => {
  const { salary, showSalary, ratingAverage, employmentTypeValue } =
    selectJobData(job);

  const includesEmploymentTypeFact =
    matchingHighlights &&
    (hasEmploymentTypeFact(matchingHighlights.matchingFacts) ||
      hasEmploymentTypeFact(matchingHighlights.nonMatchingFacts));

  const showEmploymentTypeFromJob =
    showEmploymentType && employmentTypeValue && !includesEmploymentTypeFact;

  const includesSalaryFact =
    matchingHighlights &&
    (hasSalaryFact(matchingHighlights.matchingFacts) ||
      hasSalaryFact(matchingHighlights.nonMatchingFacts));

  const showSalaryFromJob = salary && showSalary && !includesSalaryFact;

  const matchingFacts = matchingHighlights
    ? matchingHighlights.matchingFacts.map((fact, i) => (
        <FactMarker key={i} variant="match" fact={fact} />
      ))
    : null;

  const nonMatchingFacts = matchingHighlights
    ? matchingHighlights.nonMatchingFacts.map((fact, i) => (
        <FactMarker key={i} variant="default" fact={fact} />
      ))
    : null;

  const employmentTypeFromJob = showEmploymentTypeFromJob ? (
    <Marker variant="default">{employmentTypeValue}</Marker>
  ) : null;

  const salaryFromJob = showSalaryFromJob ? (
    <Marker variant="default">
      <Salary salary={salary} />
    </Marker>
  ) : null;

  const rating =
    showRatingMarker && ratingAverage ? (
      <Marker icon={IconStarFilled} variant="default">
        {(Math.round(ratingAverage * 10) / 10).toFixed(1)}
      </Marker>
    ) : null;

  return (
    <MarkerContainer>
      {rating}
      {nonMatchingFacts}
      {matchingFacts}
      {employmentTypeFromJob}
      {salaryFromJob}
    </MarkerContainer>
  );
};
