import type { Maybe } from '@xing-com/crate-common-graphql-types';
import type {
  JobMatchingHighlightsV2Fragment,
  JobTeaserVisibleJobFragment,
} from '@xing-com/crate-jobs-graphql';

import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';
import { JobTeaserListItemSkeleton } from './job-teaser-list-item-skeleton';

export type JobTeaserListItemProps = {
  job: JobTeaserVisibleJobFragment;
  isNew?: boolean;
  url: string;
  routerState?: unknown;
  highlight?: string | null;
  matchingHighlights?: Maybe<JobMatchingHighlightsV2Fragment>;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onContextMenu?: () => void;
  onAuxClick?: () => void;
  onMouseDown?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  actions?: React.ReactElement;
  showEmploymentType?: boolean;
  className?: string;
  'data-testid'?: string;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  showRefreshedDate?: boolean;
};

export type JobTeaserListItemXinglet = XingletInterface<JobTeaserListItemProps>;

export const JobTeaserListItem = JobsXingletWrapper<JobTeaserListItemProps>(
  '@xing-com/crate-jobs-components-job-teaser-list-item',
  <JobTeaserListItemSkeleton />
);
