import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@xing-com/button';
import {
  SingleSizeIllustrationHeroJobGrow,
  SingleSizeIllustrationHeroSkillsTalent,
} from '@xing-com/illustrations';
import { Container } from '@xing-com/layout-primitives';
import { PopUpWindow } from '@xing-com/pop-up-window';

import {
  useTrackClickFenced,
  useTrackSaveClick,
  useTrackSignupExperimentClick,
} from '../tracking/tracking';
import { PreferencesForm } from './preferences-form';
import {
  useCreateLoggedOutPreference,
  useReadLoggedOutPreferences,
} from './preferences-hooks';
import {
  StyledIllustrationContainer,
  StyledPopupBodyCopy,
  StyledPopupContent,
  StyledPopupHeadline,
  StyledSection,
  Subtitle,
  Title,
  UpperContainer,
} from './preferences-section.styles';
import type { Preferences } from './types';

enum Popup {
  NONE,
  MISSING_DATA,
  FENCED,
}

export const PreferencesSection: React.FC = () => {
  const [preferences, setPreferences] = useState<Preferences>({});
  const [showPopup, setShowPopUp] = useState<Popup>(Popup.NONE);

  const trackClickFenced = useTrackClickFenced();
  const trackSignup = useTrackSignupExperimentClick();
  const trackSaveClick = useTrackSaveClick();

  const { loading: loadingPreferences, preferences: fetchedPreferences } =
    useReadLoggedOutPreferences();
  const { create: createPreference } = useCreateLoggedOutPreference();

  useEffect(() => {
    if (!loadingPreferences && fetchedPreferences) {
      setPreferences(fetchedPreferences);
    }
  }, [fetchedPreferences, loadingPreferences]);

  const onFencedClickHelper = (target: string): void => {
    trackClickFenced(target);
    setShowPopUp(Popup.FENCED);
  };

  const onSubmit = (): void => {
    const isPreferencesEmpty = !Object.keys(preferences).some((key) =>
      Boolean(preferences[key])
    );

    if (isPreferencesEmpty) {
      trackSaveClick(true);

      setShowPopUp(Popup.MISSING_DATA);
    } else {
      trackSaveClick(false);

      window.location.assign(
        '/start/signup?signup_channel=minireg_losp_preferences'
      );
    }
  };

  return (
    <>
      <StyledSection>
        <Container variant="expanded">
          <UpperContainer>
            <Title size="small" forwardedAs="h2">
              <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_HEADLINE" />
            </Title>
            <Subtitle size="medium" sizeConfined="large" forwardedAs="p">
              <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SUBHEADLINE" />
            </Subtitle>
          </UpperContainer>

          <PreferencesForm
            preferences={loadingPreferences ? {} : preferences}
            onChange={(key, value) => {
              if (!loadingPreferences) {
                createPreference(key, value);
                setPreferences((oldPrefs) => ({ ...oldPrefs, [key]: value }));
              }
            }}
            onFencedClick={onFencedClickHelper}
          />

          <Button
            size="large"
            variant="secondary"
            onClick={onSubmit}
            data-qa="losp-preferences-save"
          >
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SAVE" />
          </Button>
        </Container>
      </StyledSection>

      <PopUpWindow
        isShown={showPopup === Popup.MISSING_DATA}
        hide={() => setShowPopUp(Popup.NONE)}
        onDimmerClick={() => setShowPopUp(Popup.NONE)}
      >
        <StyledPopupContent>
          <StyledIllustrationContainer>
            <SingleSizeIllustrationHeroJobGrow width={200} />
          </StyledIllustrationContainer>
          <StyledPopupHeadline size="xxlarge">
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_HEADLINE" />
          </StyledPopupHeadline>
          <StyledPopupBodyCopy size="large">
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_BODY" />
          </StyledPopupBodyCopy>
          <Button variant="secondary" onClick={() => setShowPopUp(Popup.NONE)}>
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_EMPTY_POPUP_BUTTON" />
          </Button>
        </StyledPopupContent>
      </PopUpWindow>

      <PopUpWindow
        isShown={showPopup === Popup.FENCED}
        hide={() => setShowPopUp(Popup.NONE)}
        onDimmerClick={() => setShowPopUp(Popup.NONE)}
      >
        <StyledPopupContent>
          <StyledIllustrationContainer>
            <SingleSizeIllustrationHeroSkillsTalent width={200} />
          </StyledIllustrationContainer>
          <StyledPopupHeadline size="xxlarge">
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_HEADLINE" />
          </StyledPopupHeadline>
          <StyledPopupBodyCopy size="large">
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_BODY" />
          </StyledPopupBodyCopy>
          <Button
            variant="primary"
            onClick={() => {
              trackSignup();

              window.location.assign(
                '/start/signup?signup_channel=minireg_losp_preferences'
              );
            }}
          >
            <FormattedMessage id="LOSP_PREFERENCES_EXPERIMENT_SAVE_POPUP_BUTTON" />
          </Button>
        </StyledPopupContent>
      </PopUpWindow>
    </>
  );
};
