import styled from 'styled-components';

import {
  mediaConfined,
  mediaConfinedOnly,
  mediaSmallOrTiny,
  mediaWide,
} from '@xing-com/layout-tokens';
import {
  SkeletonBody,
  SkeletonCard,
  SkeletonHeadline,
} from '@xing-com/skeleton';
import { spaceL, spaceM, spaceS, spaceXS } from '@xing-com/tokens';

const Header = styled(SkeletonHeadline)`
  width: 250px; // German copy width approximation
  margin-top: ${spaceXS};
  margin-bottom: ${spaceM};
`;

const Carousel = styled.div`
  display: flex;
  overflow-x: auto;
  gap: ${spaceS};
  margin-right: -20px;
  padding-right: 20px;

  @media ${mediaSmallOrTiny}, ${mediaConfinedOnly} {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media ${mediaConfined} {
    margin-right: -18px;
    padding-right: 18px;
  }

  @media ${mediaWide} {
    gap: ${spaceL};
    margin-right: 0;
    padding-right: 0;
    padding-bottom: ${spaceM};
  }
`;

// Use the same values as the @xing-com/crate-jobs-search-alerts-carousel xinglet
const CardSkeleton = styled(SkeletonCard)`
  height: 81px;
  min-width: 80%;

  @media ${mediaConfined} {
    min-width: 40%;
  }

  @media ${mediaWide} {
    min-width: calc((100% - ${spaceL}*2) / 3);
  }
`;

export const SearchAlertsCarouselSkeleton: React.FC = () => {
  return (
    <div>
      <Header amountRows={1} size="small" />
      <Carousel>
        <CardSkeleton>
          <SkeletonBody amountRows={2} />
        </CardSkeleton>
        <CardSkeleton>
          <SkeletonBody amountRows={2} />
        </CardSkeleton>
        <CardSkeleton>
          <SkeletonBody amountRows={2} />
        </CardSkeleton>
      </Carousel>
    </div>
  );
};
