import type {
  WebsiteAdFieldsFragment,
  VideoAdFieldsFragment,
  LeadAdFieldsFragment,
} from './auction-ads.gql-types';

export enum SiteSections {
  DiscoExplore = 'disco-explore',
  DiscoUpdates = 'disco-updates',
  SupiNetworkHome = 'supi-network-home',
  JobsRecommendation = 'jobs-recommendation',
  JobsRecommendationMyJobs = 'jobs-recommendation-my-jobs',
  JobsJobList = 'jobs-job-list',
  JobsInline = 'jobs-inline',
  StartPage = 'startpage',
  Birthdays = 'birthdays',
  Profile = 'profile',
  Logout = 'logout',
}

export type AdFields =
  | WebsiteAdFieldsFragment
  | VideoAdFieldsFragment
  | LeadAdFieldsFragment;

export type AdsContext = {
  isCompleted: boolean;
  ads: AdFields[];
};
