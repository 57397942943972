import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import type {
  InteractionTrackingEvent,
  PageViewTrackingEvent,
} from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { useIsTopBarHidden } from '@xing-com/crate-hooks-use-is-top-bar-hidden';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';
import {
  IllustrationEmptyStateError,
  IllustrationSpotError,
} from '@xing-com/illustrations';
import { mediaTopBarHidden } from '@xing-com/layout-tokens';
import {
  cornerRadiusL,
  spaceS,
  spaceXL,
  xdlColorTextSecondary,
  xdlColorBackgroundTertiary,
} from '@xing-com/tokens';
import { BodyCopy, Headline } from '@xing-com/typography';

const ErrorContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spaceXL};
  justify-content: center;
  padding: ${spaceXL};
  text-align: center;
  @media ${mediaTopBarHidden} {
    background-color: ${xdlColorBackgroundTertiary};
    border-radius: ${cornerRadiusL};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceS};
  text-align: center;

  @media ${mediaTopBarHidden} {
    color: ${xdlColorTextSecondary};
  }
`;

type AdobeTrackingEvent =
  | PageViewTrackingEvent
  | InteractionTrackingEvent<{
      PropFormList: string;
      PropTrackActionList: string;
    }>;

export const SearchAlertsListLoadingError: React.FC = () => {
  const { track: trackAdobeEvent } = useTracking<AdobeTrackingEvent>();
  const trackNwtEvent = useJobsNwtTracking();
  const isLargeScreen = useIsTopBarHidden();
  const Illustration = isLargeScreen
    ? IllustrationEmptyStateError
    : IllustrationSpotError;

  useEffect(() => {
    // NWT viewed_screen/pageview implemented on upper xinglet (my jobs)
    trackAdobeEvent({
      type: 'pageview',
      channel: 'wbm/jobs',
      page: 'wbm/jobs/your_jobs/search_alerts',
    });
    trackNwtEvent({
      eventSchema: 'basic',
      page: 'jobs/your_jobs/search_alerts',
      event: 'error_shown',
      context: 'your_jobs_loaded_state',
    });
    trackAdobeEvent({
      type: 'interaction',
      event: 'PropTrackAction',
      PropFormList: 'jobs_your_jobs_error',
      PropTrackActionList: 'search_alerts_error_loaded_state',
    });
  }, [trackAdobeEvent, trackNwtEvent]);

  return (
    <ErrorContainer>
      <Illustration size="xlarge" />
      <TextWrapper>
        <Headline size="xxlarge" noMargin>
          <FormattedMessage id="JOBS_SEARCH_ALERT_LIST_XINGLET_LOADING_ERROR_TITLE" />
        </Headline>
        <BodyCopy size="medium" noMargin>
          <FormattedMessage id="JOBS_SEARCH_ALERT_LIST_XINGLET_LOADING_ERROR_DESCRIPTION" />
        </BodyCopy>
      </TextWrapper>
    </ErrorContainer>
  );
};
