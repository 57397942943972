import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { cornerRadiusXL, spaceL, spaceM, spaceXS } from '@xing-com/tokens';
import { Headline, BodyCopy } from '@xing-com/typography';

export const StyledAnchor = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

export const StyledCard = styled(Card)<{ backgroundColor: string }>`
  border-radius: ${cornerRadiusXL};
  border: 0;
  background-color: ${(props) => props.backgroundColor};
  height: 100%;
  margin-bottom: calc(${spaceL} + ${spaceXS});
  justify-content: flex-start;
  width: 100%;
`;

export const StyledIconWrapper = styled.span<{ backgroundColor: string }>`
  margin-bottom: ${spaceL};

  & svg {
    color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export const StyledHeadline = styled(Headline)<{ textColor: string }>`
  color: ${(props) => props.textColor};
  margin-bottom: ${spaceM};
`;

export const StyledBodyCopy = styled(BodyCopy)<{ textColor: string }>`
  color: ${(props) => props.textColor};
`;
