import { useQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { preferencesData } from './data';
import { CreateCareerLevelPreferenceDocument } from './mutations/create-career-level-preference.mutation.gql-types';
import { CreateLocationsPreferenceDocument } from './mutations/create-locations-preference.mutation.gql-types';
import { CreateSalaryExpectationsPreferenceDocument } from './mutations/create-salary-expectations-preference.mutation.gql-types';
import { ReadLoggedOutPreferencesDocument } from './mutations/read-preferences.query.gql-types';
import type { Preferences } from './types';
import { getPreferenceKey } from './utils';

export function useReadLoggedOutPreferences(): {
  loading: boolean;
  preferences: Preferences | null;
} {
  const [isSSR, setIsSSR] = useState(true);
  useEffect(() => setIsSSR(false), []);

  const { data, loading } = useQuery(ReadLoggedOutPreferencesDocument, {
    skip: isSSR,
  });

  const result = useMemo(() => {
    if (loading || !data) return { loading, preferences: null };

    return {
      loading,
      preferences: {
        preferred_locations: getPreferenceKey(
          'preferred_locations',
          data.viewer?.locationsPreference?.cities[0]?.id
        ),
        career_level: getPreferenceKey(
          'career_level',
          data.viewer?.careerLevelPreference?.minimum?.toString()
        ),
        salary_expectations: getPreferenceKey(
          'salary_expectations',
          data.viewer?.salaryExpectationsPreference?.salaryExpectations?.toString()
        ),
      },
    };
  }, [loading, data]);

  return result;
}

type CreateLoggedOutPreferencesResult = {
  create: (key: string, value: string, oldValue?: string) => void;
  loading: boolean;
};

export function useCreateLoggedOutPreference(): CreateLoggedOutPreferencesResult {
  const [
    createSalaryExpectationsPreference,
    { loading: loadingSalaryExpectations },
  ] = useMutation(CreateSalaryExpectationsPreferenceDocument);
  const [createLocationsPreference, { loading: loadingLocationsExpectations }] =
    useMutation(CreateLocationsPreferenceDocument);
  const [createCareerLevelPreference, { loading: loadingCareerLevel }] =
    useMutation(CreateCareerLevelPreferenceDocument);

  const createFn = useCallback(
    (key: string, value: string) => {
      const preferenceData = preferencesData.options.find(
        (preference) => preference.preference === key
      );

      if (!preferenceData) return;

      if (key === 'salary_expectations') {
        createSalaryExpectationsPreference({
          variables: {
            input: {
              salaryExpectations: parseInt(
                preferenceData.possibleValues[value]
              ),
            },
          },
        });
      } else if (key === 'career_level') {
        createCareerLevelPreference({
          variables: {
            input: { minimum: parseInt(preferenceData.possibleValues[value]) },
          },
        });
      } else if (key === 'preferred_locations') {
        createLocationsPreference({
          variables: {
            input: { cities: [preferenceData.possibleValues[value]] },
          },
        });
      }
    },
    [
      createSalaryExpectationsPreference,
      createCareerLevelPreference,
      createLocationsPreference,
    ]
  );

  return {
    create: createFn,
    loading:
      loadingSalaryExpectations ||
      loadingLocationsExpectations ||
      loadingCareerLevel,
  };
}
