export const IconSWIMP: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2168 15C13.7292 15 16 18.4157 16 21H14C14 20.5394 13.6555 19.4851 13.1193 18.6786C12.4049 17.604 11.4518 17 10.2168 17H7.7832C6.54819 17 5.59506 17.604 4.88067 18.6786C4.34449 19.4851 4 20.5394 4 21H2C2 18.4157 4.27078 15 7.7832 15H10.2168ZM9 3C11.7616 3 14 5.23838 14 8C14 10.7616 11.7616 13 9 13C6.23838 13 4 10.7616 4 8C4 5.23838 6.23838 3 9 3ZM9 5C7.343 5 6 6.34295 6 8C6 9.65705 7.343 11 9 11C10.657 11 12 9.65705 12 8C12 6.34295 10.657 5 9 5Z"
      fill="#1D2124"
    />
    <path
      d="M16.0166 10.4253L16.3535 11.4983C16.4574 11.9308 16.7943 12.276 17.2351 12.3799L18.3039 12.7127L17.231 13.0495C16.7985 13.1535 16.4533 13.4903 16.3493 13.9312L16.0166 15L15.6797 13.927C15.5758 13.4945 15.2389 13.1493 14.7981 13.0454L13.7292 12.7127L14.8022 12.3758C15.2348 12.2718 15.5799 11.935 15.6839 11.4941L16.0166 10.4253Z"
      fill="#1D2124"
    />
    <path
      d="M18.6932 3L19.2342 4.7232C19.4012 5.41781 19.9422 5.97217 20.6502 6.13914L22.3667 6.67346L20.6435 7.21445C19.9489 7.38142 19.3945 7.92242 19.2275 8.63039L18.6932 10.3469L18.1522 8.62371C17.9853 7.9291 17.4443 7.37474 16.7363 7.20777L15.0198 6.67346L16.743 6.13246C17.4376 5.96549 17.9919 5.42449 18.1589 4.71652L18.6932 3Z"
      fill="#1D2124"
    />
  </svg>
);
