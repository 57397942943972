import throttle from 'lodash/throttle';
import type { PropsWithChildren, FC, ReactNode } from 'react';
import { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { type CarouselProps } from '@xing-com/carousel';
import { IconStarFilled } from '@xing-com/icons';
import { Marker } from '@xing-com/marker';
import { Headline } from '@xing-com/typography';
import { useMediaListener, BREAKPOINTS } from '@xing-com/util';

import { CarouselControlsState } from './carousel-controls';
import { Styled } from './carousel-section.styles';

const Title: FC<{
  children: React.ReactNode;
  headerSize?: 'hero' | 'medium';
}> = ({ children, headerSize }) => {
  if (headerSize === 'hero') {
    return (
      <Styled.TitleHero size="small" forwardedAs="h1">
        {children}
      </Styled.TitleHero>
    );
  }
  return (
    <Headline size="xxlarge" sizeConfined="xxxlarge" noMargin>
      {children}
    </Headline>
  );
};
const Subtitle: FC<{
  children: React.ReactNode;
  headerSize?: 'hero' | 'medium';
}> = ({ children, headerSize }) => {
  if (headerSize === 'hero') {
    return (
      <Styled.SubtitleHero
        size="medium"
        sizeConfined="large"
        forwardedAs="p"
        noMargin
      >
        {children}
      </Styled.SubtitleHero>
    );
  }
  return <Styled.Subtitle sizeConfined="large">{children}</Styled.Subtitle>;
};

export type CarouselSectionProps = CarouselProps & {
  variant?: 'hero' | 'medium' | 'custom';
  headerSize?: 'hero' | 'medium';
  markerKey?: string;
  titleKey?: string;
  headerElement?: ReactNode;
  subtitleKey?: string;
  useFullWidth?: boolean;
};

export const CarouselSection: FC<PropsWithChildren<CarouselSectionProps>> = ({
  variant = 'custom',
  headerSize = 'medium',
  markerKey,
  titleKey,
  headerElement,
  subtitleKey,
  children,
  itemWidth,
  itemMaxWidth,
  itemMinWidth,
  useFullWidth,
  className,
  ...props
}) => {
  const MIN_CARD_SIZE =
    variant === 'hero' ? 650 : variant === 'medium' ? 350 : itemMinWidth;
  const MAX_CARD_SIZE =
    variant === 'hero' ? 752 : variant === 'medium' ? 559 : itemMaxWidth;
  const [childWidth, setChildWidth] = useState(itemWidth);

  const breakpoint = useMediaListener();
  const isHandheld = breakpoint === BREAKPOINTS.handheld;
  const [carouselControlsState, setCarouselControlsState] =
    useState<CarouselControlsState>(CarouselControlsState.LEFT_DISABLED);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleResizeHelper = (
    container: HTMLElement,
    carousel: HTMLElement
  ): void => {
    const marginLeft = container.getBoundingClientRect().left;

    carousel.style.marginRight = `-${marginLeft}px`;
    carousel.style.marginLeft = `-${marginLeft}px`;
    carousel.style.paddingLeft = `${marginLeft}px`;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const carouselLastChild = carousel.lastChild as HTMLElement;

    if (carouselLastChild) {
      carouselLastChild.style.marginRight = `0px`;

      const existingSpacing = container?.querySelector<HTMLElement>(
        '[data-spacing="true"]'
      );

      const spacing = existingSpacing
        ? existingSpacing
        : document.createElement('div');
      spacing.style.minWidth = `${marginLeft}px`;
      spacing.style.height = '1px';
      spacing.style.order = '1';
      spacing.dataset.spacing = 'true';

      if (!existingSpacing) carousel.append(spacing);
    }
  };

  const updateCarouselControls = (carousel: HTMLElement): void => {
    const scrollLeft = carousel.scrollLeft;

    const isScrolledToLeft = scrollLeft === 0;
    const isScrolledToRight =
      scrollLeft + carousel.clientWidth === carousel.scrollWidth;

    const isScrolledNeeded = carousel.scrollWidth > carousel.clientWidth;

    if (!isScrolledNeeded) {
      setCarouselControlsState(CarouselControlsState.BOTH_DISABLED);
    } else if (isScrolledToLeft) {
      setCarouselControlsState(CarouselControlsState.LEFT_DISABLED);
    } else if (isScrolledToRight) {
      setCarouselControlsState(CarouselControlsState.RIGHT_DISABLED);
    } else {
      setCarouselControlsState(CarouselControlsState.BOTH_ENABLED);
    }
  };

  const getChildWidth = (): void => {
    if (containerRef.current) {
      const [child] = Array.from(containerRef.current.children);
      const [carousel] = Array.from(child.children);

      const [firstChildContainer, secondChildContainer] = Array.from(
        carousel?.children ?? []
      );

      const isElementSpacing =
        firstChildContainer?.getAttribute('data-spacing');

      const childContainer = isElementSpacing
        ? secondChildContainer
        : firstChildContainer;

      const [firstChild] = Array.from(childContainer?.children ?? []);

      if (firstChild) {
        const { width } = firstChild.getBoundingClientRect();
        setChildWidth(`${width}px`);

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        updateCarouselControls(carousel as HTMLElement);
      }
    }
  };

  useEffect(getChildWidth, [children, breakpoint]);

  const cardSize = childWidth ? parseInt(childWidth.replace('px', '')) : 350;

  const handleCarouselScrollHelper = (carousel: HTMLElement): void => {
    updateCarouselControls(carousel);
  };

  useEffect(() => {
    const container = containerRef.current;
    const carousel = container?.querySelector<HTMLElement>(
      '[data-xds="Carousel"]'
    );
    if (!container || !carousel) return;

    const handleResize = throttle(
      () => handleResizeHelper(container, carousel),
      100
    );

    const handleCarouselScroll = throttle(
      () => handleCarouselScrollHelper(carousel),
      100
    );

    carousel.addEventListener('scroll', handleCarouselScroll);
    handleCarouselScroll();

    if (variant === 'hero' || useFullWidth) {
      window.addEventListener('resize', handleResize, false);
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      carousel.removeEventListener('scroll', handleCarouselScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  return (
    <section className={className}>
      <Styled.Header $headerSize={headerSize}>
        {!!headerElement && headerElement}
        {!headerElement && (
          <div>
            <Styled.HeaderContent>
              {titleKey && (
                <Title headerSize={headerSize}>
                  <FormattedMessage id={titleKey} />
                </Title>
              )}
              {markerKey && (
                <Marker icon={IconStarFilled}>
                  <FormattedMessage id={markerKey} />
                </Marker>
              )}
            </Styled.HeaderContent>

            {subtitleKey && (
              <Subtitle headerSize={headerSize}>
                <FormattedMessage id={subtitleKey} />
              </Subtitle>
            )}
          </div>
        )}

        <Styled.CarouselControls
          cardSize={cardSize}
          carouselControlsState={carouselControlsState}
          containerRef={containerRef}
          isHandheld={isHandheld}
        />
      </Styled.Header>
      <div ref={containerRef}>
        <Styled.Carousel
          itemWidth={childWidth}
          itemMaxWidth={MAX_CARD_SIZE}
          itemMinWidth={MIN_CARD_SIZE}
          itemWidthConfined={variant === 'medium' && '22%'}
          itemWidthWide={variant === 'medium' && '23%'}
          hidePageButtons={true}
          {...props}
        >
          {children}
        </Styled.Carousel>
      </div>
    </section>
  );
};
