import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM, spaceL, scale210, scale250 } from '@xing-com/tokens';

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${scale210};
  row-gap: ${spaceM};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${spaceL};
    margin-top: ${scale250};
  }
`;
