import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { DEFAULT_RADIUS } from '@xing-com/crate-jobs-constants';
import { GetBusinessCityDocument } from '@xing-com/crate-jobs-graphql';

export type LocationSuggestion = {
  cityId: string;
  name: string;
  meta: string;
};

type UseSearchLocationArgs = {
  initialLocation?: string | null;
  initialRadius?: number | null;
  initialCityId?: string | null;
  skipBusinessCityQuery?: boolean;
  onChange?: () => void;
};

type UseSearchLocationResult = {
  location: string;
  radius: number;
  cityId: string;
  onChangeLocation: (location: string) => void;
  onSelectLocation: (suggestion: LocationSuggestion) => void;
  onSelectRadius: (radius: number) => void;
};

export const useSearchLocation = ({
  initialLocation,
  initialRadius,
  initialCityId,
  skipBusinessCityQuery,
  onChange,
}: UseSearchLocationArgs): UseSearchLocationResult => {
  const locationValue = initialLocation ?? '';
  const cityIdValue = initialCityId ?? '';
  const radiusValue = initialRadius ?? DEFAULT_RADIUS;

  const [location, setLocation] = useState(locationValue);
  const [cityId, setCityId] = useState(cityIdValue);
  const [radius, setRadius] = useState(radiusValue);

  // Check if the URL params have been changed (i.e. by resetting the filters)
  useEffect(() => {
    setRadius(radiusValue);
  }, [radiusValue]);

  useEffect(() => {
    setLocation(locationValue);
  }, [locationValue]);

  useEffect(() => {
    setCityId(cityIdValue);
  }, [cityIdValue]);

  useEffect(() => {
    // while on a SERP page, initialLocation isn't available on first render
    if (!location && initialLocation) {
      setLocation(initialLocation);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocation]);

  useQuery(GetBusinessCityDocument, {
    skip: skipBusinessCityQuery,
    onCompleted: ({ viewer }) => {
      const businessCity = viewer?.xingId?.location?.city;
      if (businessCity) {
        setLocation(businessCity);
        setRadius(20);
      }
    },
  });

  const handleOnChangeLocation = (name: string): void => {
    onChange?.();
    setLocation(name);
    setCityId('');
  };

  const handleOnSelectLocation = ({
    name,
    cityId,
  }: LocationSuggestion): void => {
    onChange?.();
    setLocation(name);
    setCityId(cityId);
  };

  const handleOnSelectRadius = (radius: number): void => {
    onChange?.();
    setRadius(radius);
  };

  return {
    location,
    radius,
    cityId,
    onChangeLocation: handleOnChangeLocation,
    onSelectLocation: handleOnSelectLocation,
    onSelectRadius: handleOnSelectRadius,
  };
};
