const JobRolesSerps: Array<Record<string, string>> = [
  {
    serpType: 'Job Roles',
    anchor: 'Minijobs |',
    url: '/jobs/t-minijob',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Freelancer Jobs |',
    url: '/jobs/t-freelancer',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Aushilfe Jobs |',
    url: '/jobs/t-aushilfe',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Banker Jobs |',
    url: '/jobs/t-banker',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Lkw Fahrer Jobs |',
    url: '/jobs/t-lkw-fahrer',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Werkstudent Jobs |',
    url: '/jobs/t-werkstudent',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Marketer Jobs |',
    url: '/jobs/t-marketing-manager',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Hausmeister Jobs |',
    url: '/jobs/t-hausmeister',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Gesundheitsmanager Jobs |',
    url: '/jobs/t-gesundheitsmanager',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Mediengestalter Jobs |',
    url: '/jobs/t-mediengestalter',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Flugbegleiter Jobs |',
    url: '/jobs/t-flugbegleiter',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Controller Jobs |',
    url: '/jobs/t-controller',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Erzieher Jobs |',
    url: '/jobs/t-erzieher',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Außendienstler Jobs |',
    url: '/jobs/t-aussendienstler',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Psychologen Jobs |',
    url: '/jobs/t-psychologen',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Buchhalter Jobs |',
    url: '/jobs/t-buchhalter',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Logistiker Jobs |',
    url: '/jobs/t-logistiker',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Staplerfahrer Jobs |',
    url: '/jobs/t-staplerfahrer',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Industriemechaniker Jobs |',
    url: '/jobs/t-indus­trie­me­cha­niker',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Social Media Manager Jobs |',
    url: '/jobs/t-social-media-manager',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Data Analyst Jobs |',
    url: '/jobs/t-data-analyst',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Projektmanagement Jobs |',
    url: '/jobs/t-projektmanagement',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
  {
    serpType: 'Job Roles',
    anchor: 'Projektmanager Jobs',
    url: '/jobs/t-projektmanager',
    trackingParam: '?sc_o=losp_serp_category_job_roles_link_click',
  },
];

const LocationSerps: Array<Record<string, string>> = [
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Berlin |',
    url: '/jobs/jobs-in-berlin',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Hamburg |',
    url: '/jobs/jobs-in-hamburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs München |',
    url: '/jobs/jobs-in-münchen',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Leipzig |',
    url: '/jobs/jobs-in-leipzig',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Bremen |',
    url: '/jobs/jobs-in-bremen',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Dresden |',
    url: '/jobs/jobs-in-dresden',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Hannover |',
    url: '/jobs/jobs-in-hannover',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Köln |',
    url: '/jobs/jobs-in-köln',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Augsburg |',
    url: '/jobs/jobs-in-augsburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Nürnberg |',
    url: '/jobs/jobs-in-nürnberg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Regensburg |',
    url: '/jobs/jobs-in-regensburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Rostock |',
    url: '/jobs/jobs-in-rostock',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Stuttgart |',
    url: '/jobs/jobs-in-stuttgart',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Dortmund |',
    url: '/jobs/jobs-in-dortmund',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Düsseldorf |',
    url: '/jobs/jobs-in-düsseldorf',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Lübeck |',
    url: '/jobs/jobs-in-lübeck',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Ulm |',
    url: '/jobs/jobs-in-ulm',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Bielefeld |',
    url: '/jobs/jobs-in-bielefeld',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Braunschweig |',
    url: '/jobs/jobs-in-braunschweig',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Chemnitz |',
    url: '/jobs/jobs-in-chemnitz',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Erfurt |',
    url: '/jobs/jobs-in-erfurt',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Göttingen |',
    url: '/jobs/jobs-in-göttingen',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Karlsruhe |',
    url: '/jobs/jobs-in-karlsruhe',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Kassel |',
    url: '/jobs/jobs-in-kassel',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Kiel |',
    url: '/jobs/jobs-in-kiel',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Magdeburg |',
    url: '/jobs/jobs-in-magdeburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Mannheim |',
    url: '/jobs/jobs-in-mannheim',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Münster |',
    url: '/jobs/jobs-in-münster',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Oldenburg |',
    url: '/jobs/jobs-in-oldenburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Osnabrück |',
    url: '/jobs/jobs-in-osnabrück',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
  {
    serpType: 'Top Locations',
    anchor: 'Jobs Würzburg',
    url: '/jobs/jobs-in-würzburg',
    trackingParam: '?sc_o=losp_serp_category_top_locations_link_click',
  },
];

const TopSkillsSerps: Array<Record<string, string>> = [
  {
    serpType: 'Top Skills',
    anchor: 'Feuerwehr Jobs |',
    url: '/jobs/skill/feuerwehr',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Bank Jobs |',
    url: '/jobs/skill/bank',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Büro Jobs |',
    url: '/jobs/skill/büro',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Vertrieb Jobs |',
    url: '/jobs/skill/vertrieb',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Callcenter Jobs |',
    url: '/jobs/skill/callcenter',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Logistik Jobs |',
    url: '/jobs/skill/logistik',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Sicherheitsdienst Jobs |',
    url: '/jobs/skill/sicherheitsdienst',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Flughafen Jobs |',
    url: '/jobs/skill/flughafen',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Nachhaltigkeit Jobs |',
    url: '/jobs/skill/nachhaltigkeit',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Promotion Jobs |',
    url: '/jobs/skill/promotion',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Kreativität Jobs |',
    url: '/jobs/skill/kreativität',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Soziale Arbeit Jobs |',
    url: '/jobs/skill/soziale-arbeit',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Sozialpädagogik Jobs |',
    url: '/jobs/skill/sozialpädagogik',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Chemie Jobs |',
    url: '/jobs/skill/chemie',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Design Jobs |',
    url: '/jobs/skill/design',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Hauswirtschaft Jobs |',
    url: '/jobs/skill/hauswirtschaft',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Stiftung Jobs |',
    url: '/jobs/skill/stiftung',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Gastronomie Jobs |',
    url: '/jobs/skill/gastronomie',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Museum Jobs |',
    url: '/jobs/skill/museum',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
  {
    serpType: 'Top Skills',
    anchor: 'Verwaltung Jobs |',
    url: '/jobs/skill/verwaltung',
    trackingParam: '?sc_o=losp_serp_category_top_skills_link_click',
  },
];

const VerzeichnisseSerps = [
  {
    serpType: 'Verzeichnisse',
    anchorKey: 'LOSP_SEO_SECTION_PEOPLE_DIRECTORY',
    url: '/people',
    trackingParam: '?sc_o=losp_serp_category_directories_link_click',
  },
  {
    serpType: 'Verzeichnisse',
    anchorKey: 'LOSP_SEO_SECTION_JOBS_DIRECTORY',
    url: '/jobs/directory/a',
    trackingParam: '?sc_o=losp_serp_category_directories_link_click',
  },
  {
    serpType: 'Verzeichnisse',
    anchorKey: 'LOSP_SEO_SECTION_COMPANIES_DIRECTORY',
    url: '/jobs/c-directory/a',
    trackingParam: '?sc_o=losp_serp_category_directories_link_click',
  },
];

export const SeoData: Array<Record<string, string>> = [
  ...JobRolesSerps,
  ...LocationSerps,
  ...TopSkillsSerps,
  ...VerzeichnisseSerps,
];

export const SerpsType = new Map<string, string>([
  ['Job Roles', 'job_roles'],
  ['Top Locations', 'top_locations'],
  ['Top Skills', 'top_skills'],
  ['Verzeichnisse', 'directories'],
]);
