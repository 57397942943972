import fetch from 'cross-fetch';

import { getTrackingHost } from './get-tracking-host';

/**
 * tracks an ad impression on the backend
 *
 * @param {number} id Ad id
 * @param {string} trackingToken tracking token (provided by backend in fetch-ads)
 * @param {string} scrambledId scrambled id (s_id2 cookie) for logged out ads
 */
export async function trackImpression(
  id: number,
  trackingToken: string,
  scrambledId?: string
): Promise<string> {
  try {
    // Mock response on localhost, e.g. for demo app
    if (location.hostname === 'localhost') {
      console.info(
        `Tracking impression for ad #${id} with token "${trackingToken}"`
      );
      return Promise.resolve('stub');
    }

    let requestParams = {
      token: trackingToken,
    };

    let trackingUrl = `/xas/api/ads/${id}/impressions`;

    if (scrambledId) {
      requestParams = {
        ...requestParams,
        ...{ scrambled_id: scrambledId },
      };

      trackingUrl = `${getTrackingHost()}/xas/api/ads/${id}/impressions`;
    }

    await fetch(trackingUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(requestParams),
    });
  } catch (error) {
    throw new Error(error);
  }

  return Promise.resolve('ok');
}
