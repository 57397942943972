export const line1 = [
  {
    id: 'LOSP_KEYWORDS_FOUNDATION',
    href: 'https://www.xing.com/jobs/skill/stiftung',
  },
  {
    id: 'LOSP_KEYWORDS_SOCIALWORKER',
    href: 'https://www.xing.com/jobs/t-sozialpädagoge',
  },
  {
    id: 'LOSP_KEYWORDS_ADMINISTRATION',
    href: 'https://www.xing.com/jobs/skill/verwaltung',
  },
  {
    id: 'LOSP_KEYWORDS_WORKINGSTUDENT',
    href: 'https://www.xing.com/jobs/t-werkstudent',
  },
  {
    id: 'LOSP_KEYWORDS_MARKETING',
    href: 'https://www.xing.com/jobs/skill/marketing',
  },
  {
    id: 'LOSP_KEYWORDS_HEALTH',
    href: 'https://www.xing.com/jobs/t-gesundheitsmanager',
  },
  {
    id: 'LOSP_KEYWORDS_LOGISTICS',
    href: 'https://www.xing.com/jobs/skill/logistik',
  },
  {
    id: 'LOSP_KEYWORDS_CAREERCHANGER',
    href: 'https://www.xing.com/jobs/t-quereinsteiger',
  },
  {
    id: 'LOSP_KEYWORDS_PSYCHOLOGIST',
    href: 'https://www.xing.com/jobs/t-psychologe',
  },
  {
    id: 'LOSP_KEYWORDS_SOCIALWORKER2',
    href: 'https://www.xing.com/jobs/t-sozialarbeiter',
  },
  {
    id: 'LOSP_KEYWORDS_DESIGN',
    href: 'https://www.xing.com/jobs/skill/design',
  },
  {
    id: 'LOSP_KEYWORDS_OFFICECLERK',
    href: 'https://www.xing.com/jobs/t-bürokauffrau',
  },
  {
    id: 'LOSP_KEYWORDS_SALES',
    href: 'https://www.xing.com/jobs/t-aussendienstler',
  },
  {
    id: 'LOSP_KEYWORDS_ACCOUNTANT',
    href: 'https://www.xing.com/jobs/t-buchhalter',
  },
  {
    id: 'LOSP_KEYWORDS_FREELANCE',
    href: 'https://www.xing.com/jobs/t-freelancer',
  },
];

export const line2 = [
  {
    id: 'LOSP_KEYWORDS_PROJECTMANAGER',
    href: 'https://www.xing.com/jobs/t-projektmanager',
  },
  {
    id: 'LOSP_KEYWORDS_TRUCK',
    href: 'https://www.xing.com/jobs/t-lkw-fahrer',
  },
  {
    id: 'LOSP_KEYWORDS_CALLCENTER',
    href: 'https://www.xing.com/jobs/skill/callcenter',
  },
  {
    id: 'LOSP_KEYWORDS_NURSERY',
    href: 'https://www.xing.com/jobs/t-erzieher',
  },
  {
    id: 'LOSP_KEYWORDS_ANALYST',
    href: 'https://www.xing.com/jobs/t-data-analyst',
  },
  {
    id: 'LOSP_KEYWORDS_CARETAKER',
    href: 'https://www.xing.com/jobs/t-hausmeister',
  },
  {
    id: 'LOSP_KEYWORDS_MUSEUM',
    href: 'https://www.xing.com/jobs/skill/museum',
  },
  {
    id: 'LOSP_KEYWORDS_FORKLIFT',
    href: 'https://www.xing.com/jobs/t-staplerfahrer',
  },
  {
    id: 'LOSP_KEYWORDS_FLIGHTATTENDANT',
    href: 'https://www.xing.com/jobs/t-flugbegleiter',
  },
  {
    id: 'LOSP_KEYWORDS_SUSTAINABILITY',
    href: 'https://www.xing.com/jobs/skill/nachhaltigkeit',
  },
  {
    id: 'LOSP_KEYWORDS_SALES2',
    href: 'https://www.xing.com/jobs/skill/vertrieb',
  },
  {
    id: 'LOSP_KEYWORDS_SOCIALMEDIA',
    href: 'https://www.xing.com/jobs/t-social-media-manager',
  },
  {
    id: 'LOSP_KEYWORDS_SECURITY',
    href: 'https://www.xing.com/jobs/skill/sicherheitsdienst',
  },
  {
    id: 'LOSP_KEYWORDS_BANKER',
    href: 'https://www.xing.com/jobs/t-banker',
  },
  {
    id: 'LOSP_KEYWORDS_CONTROLLER',
    href: 'https://www.xing.com/jobs/t-controller',
  },
];
