import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceXS,
  spaceL,
  spaceXXL,
  space3XL,
  space6XL,
  scale110,
  scale130,
  scale190,
  scale230,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Headline, Hero } from '@xing-com/typography';

export const StyledHero = styled(Hero)`
  font-size: ${scale110};
  margin-bottom: ${spaceXS};

  @media ${mediaConfined} {
    font-size: ${scale130};
  }
`;

export const StyledSection = styled.section`
  margin-top: ${scale190};
  margin-bottom: ${scale230};

  @media ${mediaConfined} {
    margin-bottom: ${space6XL};
  }
`;

export const StyledSubline = styled(Headline)`
  font-weight: 400;
  margin: ${spaceXS} 0 ${spaceXXL};
  color: ${xdlColorTextSecondary};
`;

export const LowerSectionWrapper = styled.div`
  text-align: center;
  margin-top: calc(${spaceL} + ${space3XL});
`;

export const StyledHeadline = styled(Headline)`
  font-weight: 700;
`;
