import type React from 'react';
import { useIntl } from 'react-intl';

import { useHost } from '@xing-com/crate-xinglet';

import {
  StyledAnchor,
  StyledCard,
  StyledIconWrapper,
  StyledHeadline,
  StyledBodyCopy,
} from './inspirational-card.styles';

type InspirationalCard = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  link: string;
  backgroundColor: string;
  textColor: string;
  trackingParam?: string;
};

export const InspirationalCard: React.FC<InspirationalCard> = ({
  icon: Icon,
  title,
  description,
  link,
  backgroundColor,
  textColor,
  trackingParam = '',
}) => {
  const { formatMessage } = useIntl();
  const { isPreview } = useHost();

  const finalLink =
    link === 'https://login.xing.com/'
      ? isPreview
        ? 'https://login.preview.xing.com'
        : 'https://login.xing.com/'
      : link;

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(`${finalLink}${trackingParam}`);
  };

  return (
    <StyledAnchor onClick={handleAnchorClick} href={link}>
      <StyledCard backgroundColor={backgroundColor} minHeight="200px">
        <StyledIconWrapper backgroundColor={textColor}>
          <Icon height={32} width={32} />
        </StyledIconWrapper>
        <StyledHeadline size="xxlarge" textColor={textColor} forwardedAs="h3">
          {formatMessage({ id: title })}
        </StyledHeadline>
        <StyledBodyCopy textColor={textColor} size="large">
          {formatMessage({ id: description })}
        </StyledBodyCopy>
      </StyledCard>
    </StyledAnchor>
  );
};
