import styled from 'styled-components';

import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  scale080,
  scale130,
  scale210,
  spaceM,
  spaceL,
  spaceXXL,
  xdlPaletteWhite,
  scale140,
  spaceXL,
  xdlColorTextOnLight,
} from '@xing-com/tokens';
import { Hero, Headline } from '@xing-com/typography';
import { Butterfly } from '@xing-com/xing-logo';

import background from './assets/background.svg';

export const Background = styled.section`
  background: #a7e770;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: 180% 60%;
  background-size: 110% 110%;
  border-radius: ${scale130};
  padding: ${scale210} 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: ${scale210};

  @media ${mediaConfined} {
    border-radius: ${scale130};
    height: 406px;
    text-align: left;
    justify-content: space-evenly;
  }
`;

export const QrCodeWrapper = styled.div`
  position: relative;
  display: none;

  @media ${mediaConfined} {
    display: flex;
    background: ${xdlPaletteWhite};
    border-radius: ${scale080};
    height: 286px;
    width: 286px;
    margin-left: ${spaceXL};
  }

  @media ${mediaWide} {
    margin-left: 0;
  }
`;

export const StyledHero = styled(Hero)`
  color: ${xdlColorTextOnLight};
  margin-bottom: ${spaceL};
`;

export const StyledHeadline = styled(Headline)`
  color: ${xdlColorTextOnLight};
  font-weight: normal;
  margin-bottom: ${spaceXXL};
`;

export const ServiceStoreImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceM};

  @media ${mediaConfined} {
    flex-direction: row;
    gap: ${spaceL};
  }
`;

export const ServiceStoreImage = styled.img`
  height: 70px;
  width: 210px;

  @media ${mediaConfined} {
    height: 48px;
    width: 144px;
  }
`;

export const LeftContainer = styled.div`
  max-width: 330px;
`;

export const RightContainer = styled.div`
  &&& {
    display: flex;
    align-items: center;
  }
`;

export const StyledButterfly = styled(Butterfly)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 53px;
`;

export const StyledQRCode = styled.img`
  padding: ${scale140};
`;
