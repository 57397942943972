import type {
  FormatNumberOptions,
  IntlFormatters,
  IntlShape,
} from 'react-intl';

import type { Currency, JobSalary } from '@xing-com/crate-common-graphql-types';
import type {
  Salary,
  SalaryFragment,
  SalaryEstimateFragment,
  SalaryRangeFragment,
} from '@xing-com/crate-jobs-graphql';

export type SalaryValid = Omit<SalaryFragment, 'amount' | 'currency'> & {
  amount: number;
  currency: Currency;
};

export type SalaryEstimateValid = Omit<
  SalaryEstimateFragment,
  'maximum' | 'minimum' | 'currency'
> & {
  maximum: number;
  minimum: number;
  median?: number;
  currency: Currency;
};

export type SalaryRangeValid = Omit<
  SalaryRangeFragment,
  'maximum' | 'minimum' | 'currency'
> & {
  maximum: number;
  minimum: number;
  currency: Currency;
};

export const getSalaryFormatOptions = (
  currency: Currency
): FormatNumberOptions => ({
  style: 'currency',
  currency: currency,
  minimumFractionDigits: 0,
});

export const formatSalaryRange = (
  formatNumber: IntlFormatters['formatNumber'],
  [min, max]: number[],
  currency: Currency
): string => {
  const options = getSalaryFormatOptions(currency);

  return `${formatNumber(min, options)} – ${formatNumber(max, options)}`;
};

export function formatSalary(intl: IntlShape, salary: JobSalary): string {
  if (isValidSalary(salary)) {
    const { amount, currency } = salary;
    return intl.formatNumber(amount, getSalaryFormatOptions(currency));
  }

  if (isValidSalaryRange(salary) || isValidSalaryEstimate(salary)) {
    const { minimum, maximum, currency, __typename } = salary;

    return `${formatSalaryRange(
      intl.formatNumber,
      [minimum, maximum],
      currency
    )}${
      __typename === 'SalaryEstimate'
        ? ` ${intl.formatMessage({
            id: 'JOBS_SALARY_XING_ESTIMATED',
          })}`
        : ''
    }`;
  }

  return '';
}

export const isValidSalary = (salary?: Salary | null): salary is SalaryValid =>
  salary?.__typename === 'Salary' &&
  typeof salary.amount === 'number' &&
  !!salary.currency;

export const isValidSalaryEstimate = (
  salary?: Salary | null
): salary is SalaryEstimateValid =>
  salary?.__typename === 'SalaryEstimate' &&
  typeof salary.maximum === 'number' &&
  typeof salary.minimum === 'number' &&
  !!salary.currency;

export const isValidSalaryRange = (
  salary?: Salary | null
): salary is SalaryRangeValid =>
  salary?.__typename === 'SalaryRange' &&
  typeof salary.maximum === 'number' &&
  typeof salary.minimum === 'number' &&
  !!salary.currency;
