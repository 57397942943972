import { GridItem } from '@xing-com/grid';
import { SkeletonBody, SkeletonCard } from '@xing-com/skeleton';
import { spaceXL } from '@xing-com/tokens';

import * as S from './search-inspiration-skeleton.styles';

const Content: React.FC = () => (
  <>
    <S.Image height={spaceXL} width={spaceXL} />
    <S.ContentHeadline amountRows={1} size="small" />
    <SkeletonBody amountRows={2} />
  </>
);

type Props = {
  hideHeadline?: boolean;
};

export const SearchInspirationSkeleton: React.FC<Props> = ({
  hideHeadline = false,
}) => {
  return (
    <S.Container data-testid="search-inspiration-skeleton">
      {hideHeadline ? null : (
        <S.HeadlineContainer>
          <S.Headline size="medium" amountRows={1} />
          <S.BodyCopy amountRows={1} />
        </S.HeadlineContainer>
      )}
      <S.Carousel>
        <S.CarouselCard>
          <Content />
        </S.CarouselCard>
        <S.CarouselCard>
          <Content />
        </S.CarouselCard>
      </S.Carousel>
      <S.ConfinedContainer variant="FullSize">
        {[...Array(4)].map((_, i) => (
          <GridItem key={i} span={6} spanLarge={3}>
            <SkeletonCard>
              <Content />
            </SkeletonCard>
          </GridItem>
        ))}
      </S.ConfinedContainer>
      <S.ShowMoreButton height="20px" width="120px" />
    </S.Container>
  );
};
