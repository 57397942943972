import type { GetJobRecommendationsQuery } from '@xing-com/crate-jobs-graphql';

import type { XingletInterface } from '../../../jobs-xinglet';
import { JobsXingletWrapper } from '../../../jobs-xinglet';
import { RecommendationsSkeleton } from './recommendations-skeleton';

export type RecommendationsProps = {
  onCompleted: (data: GetJobRecommendationsQuery) => void;
};

export type RecommendationsXinglet = XingletInterface<RecommendationsProps>;

export const Recommendations = JobsXingletWrapper<RecommendationsProps>(
  '@xing-com/crate-jobs-domain-recommendations-grid',
  <RecommendationsSkeleton />
);
