import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import shuffle from 'lodash/shuffle';
import type React from 'react';
import { useIntl } from 'react-intl';

import type { ButtonProps } from '@xing-com/button';
import { CarouselSection } from '@xing-com/crate-x4e-carousel-section';
import { EntityCard } from '@xing-com/crate-x4e-entity-card';
import { Container } from '@xing-com/layout-primitives';
import type { MarkerProps, MarkerVariant } from '@xing-com/marker';
import { scale430, xdlColorBackgroundSecondary } from '@xing-com/tokens';

import { StyledIcon, StyledAnchor } from '../card/card.styles';
import { useServerData } from '../hooks/useServerData';
import { StyledCarouselSection } from '../startpage-logged-out.styles';
import { CompaniesData } from './data/companies-data';
import { StartPageCompaniesDocument } from './graphql/queries/start-page-companies.gql-types';

const tracking = {
  companies: {
    card: 'losp_employer_card_click',
    button: 'losp_employer_card_jobs_button_click',
  },
};

export const CompaniesSection: React.FC = () => {
  const { search } = useLocation();
  const { formatMessage } = useIntl();

  const queryParams = new URLSearchParams(search);

  const initialValue =
    queryParams.get('allCompanies') === 'true'
      ? CompaniesData.sort((a, b) => a.position - b.position)
      : shuffle(CompaniesData).slice(0, 20);

  const { data } = useQuery(StartPageCompaniesDocument, {
    variables: {
      ids: CompaniesData.map(({ id }) => id),
    },
  });

  const companiesJobsCount =
    data?.companies?.map((company) => ({
      jobsCount: company?.jobsCount ?? 0,
      id: company?.id,
    })) ?? [];

  const serverData = initialValue.map((company) => ({
    ...company,
    jobsCount: companiesJobsCount.find(({ id }) => id === company.id)
      ?.jobsCount,
  }));

  const companies = useServerData('companiesStartPage', serverData);

  const handleTracking = ({
    url,
    trackingId,
    cardPosition,
  }: {
    url: string;
    trackingId: string;
    cardPosition: number;
  }): void => {
    const fullUrl = `${url}?sc_o=${trackingId}&sc_o_PropActionOrigin=losp_recruiter_and_employer_card_position_${cardPosition}`;
    window.location.assign(fullUrl);
  };

  const DescriptionIcon = (): JSX.Element => (
    <StyledIcon width={20} height={25} />
  );

  const defaultMarker = {
    variant: 'highlight' as MarkerVariant,
    children: formatMessage({
      id: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    }),
  };

  return (
    <StyledCarouselSection $bgColor={xdlColorBackgroundSecondary}>
      <Container variant="expanded">
        <CarouselSection
          titleKey={'COMPANIES_SECTION_TITLE'}
          subtitleKey={'COMPANIES_SECTION_SUBTITLE'}
          itemWidth="346px"
          itemMinWidth="346px"
          itemMaxWidth="346px"
          // @ts-expect-error FIXME: SC6
          itemHeight={scale430}
          itemMinHeight={scale430}
          itemMaxHeight={scale430}
          itemSnapPosition="center"
          useFullWidth={true}
        >
          {companies.map((cardData, index) => {
            const buttonUrl = `${cardData.profileUrl}/jobs`;
            const buttonProps: ButtonProps = {
              variant: 'tertiary',
              href: buttonUrl,
              // @ts-expect-error FIXME: SC6
              onClick: (event: MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();

                handleTracking({
                  cardPosition: index + 1,
                  url: buttonUrl,
                  trackingId: tracking.companies.button,
                });
              },
              children: formatMessage(
                {
                  id:
                    cardData.jobsCount && cardData.jobsCount === 1
                      ? 'CP_TOP_COMPANIES_CARD_BUTTON.one'
                      : 'CP_TOP_COMPANIES_CARD_BUTTON.other',
                },
                { value: cardData.jobsCount ? cardData.jobsCount : '' }
              ),
            };

            const markers: MarkerProps[] = cardData.rating
              ? [defaultMarker, { children: cardData.rating }]
              : [defaultMarker];
            const image = {
              src: cardData.photoUrl,
            };
            const description = formatMessage({
              id: cardData.insight,
            });

            const onCardClick = (event: React.MouseEvent): void => {
              event.preventDefault();
              event.stopPropagation();

              handleTracking({
                cardPosition: index + 1,
                url: cardData.profileUrl,
                trackingId: tracking.companies.card,
              });
            };

            return (
              <StyledAnchor
                key={cardData.id}
                href={cardData.profileUrl}
                onClick={onCardClick}
              >
                <EntityCard
                  headline={cardData.name}
                  copy1={cardData.jobTitle}
                  profileImage={image}
                  markers={markers}
                  button={buttonProps}
                  description={description}
                  descriptionIcon={DescriptionIcon}
                />
              </StyledAnchor>
            );
          })}
        </CarouselSection>
      </Container>
    </StyledCarouselSection>
  );
};
