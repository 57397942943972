import { SkeletonRectangle } from '@xing-com/skeleton';

import * as S from '../../shared.styles';

type Props = {
  hideHeadline?: boolean;
  dataTestId: string;
};

export const JobsRecommendationsSkeletons: React.FC<Props> = ({
  hideHeadline = false,
  dataTestId,
}) => {
  return (
    <S.Container data-testid={dataTestId}>
      {hideHeadline ? null : (
        <S.HeadlineContainer>
          <S.Headline size="medium" amountRows={1} />
          <S.BodyCopy amountRows={1} />
        </S.HeadlineContainer>
      )}
      <S.Carousel>
        <S.JobTeaser />
        <S.JobTeaser />
        <S.JobTeaser />
      </S.Carousel>
      <S.ShowMoreButton>
        <SkeletonRectangle height="20px" width="120px" />
      </S.ShowMoreButton>
    </S.Container>
  );
};
