import {
  AdTypes,
  AdSenderTypes,
  LeadAdFormSections,
  LeadAdFormFields,
} from '@xing-com/crate-common-graphql-types';
import { createContentReportMock } from '@xing-com/crate-hooks-use-content-report/src/mocks';
import type { msw } from '@xing-com/crate-xinglet';

import type { AdAuctionWinnerAdPreviewQuery } from './auction-ad-preview.gql-types';
import type {
  AdAuctionWinnersQuery,
  WebsiteAdFieldsFragment,
  VideoAdFieldsFragment,
  LeadAdFieldsFragment,
} from './auction-ads.gql-types';
import type { LeadAdFormSubmissionCreateMutation } from './lead-ad-form-submit.gql-types';
import type { LeadAdFormQuery } from './lead-ad-form.gql-types';

const IMAGE_PROFILE_ORIGINAL = '/test-assets/1024x1024x000xfff.png';
const VIDEO_FILE = '/test-assets/1-sample-vid.mp4';
const AD_IMAGE_LARGE = '/test-assets/1920x1080xccc.png';
const AD_IMAGE_LEAD_AD = '/test-assets/1200x612fff00.png';
const AD_DESCRIPTION =
  'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. ';
const AD_TARGET_URL = 'https://apod.nasa.gov/apod/ap200118.html';
const AD_SENDER_LINK = '/test-assets/128x128xdddx000.png';

export const websiteAd: WebsiteAdFieldsFragment = {
  sid: '54.afe3',
  __typename: 'AuctionWebsiteAd',
  type: AdTypes.WebsiteAd,
  surn: 'surn:x-xing:activities:activity:30092072.daabca',
  description: AD_DESCRIPTION,
  image: {
    __typename: 'AdImage',
    small: AD_IMAGE_LARGE,
    large: null,
  },
  title: 'My new Website Ad creation - whole lotta craziness',
  trackingToken:
    '554987a97b206a8f.YWRfZGVsaXZlcnlfaWQ9MzIwNiZjaGFubmVsPXN0YXJ0cGFnZV93ZWImcHJpY2U9MC4wMTAwMCZ0aW1lPTE2MDQ0MjUyMjY',
  targetUrl: AD_TARGET_URL,
  siteName: 'https://apod.nasa.gov/apod/ap200118.html',
  sender: {
    __typename: 'AdSender',
    image: IMAGE_PROFILE_ORIGINAL,
    name: 'The Kensington Arms with a veeeeeeeeeeeeeeery loooooooooooooooooooooooooooooooooooooooong nameeeeeeeeeeeeeeeeeeeee - phew!',
    link: AD_SENDER_LINK,
    urn: 'urn',
    jobDescription:
      'New work SE - for a better working life - working better is very important so do better than yesterday as this also helps the poor and unheard - can I hear an amen?',
    followersCount: null,
  },
};

export const videoAd: VideoAdFieldsFragment = {
  sid: '75.afe3',
  __typename: 'AuctionVideoAd',
  type: AdTypes.VideoAd,
  surn: 'surn:x-xing:activities:activity:30092072.daabca',
  description: AD_DESCRIPTION,
  title: 'My new Video Ad creation - whole lotta craziness',
  trackingToken:
    '554987a97b206a8f.YWRfZGVsaXZlcnlfaWQ9MzIwNiZjaGFubmVsPXN0YXJ0cGFnZV93ZWImcHJpY2U9MC4wMTAwMCZ0aW1lPTE2MDQ0MjUyMjY',
  targetUrl: AD_TARGET_URL,
  sender: {
    __typename: 'AdSender',
    image: IMAGE_PROFILE_ORIGINAL,
    name: 'The Kensington Arms with a veeeeeeeeeeeeeeery loooooooooooooooooooooooooooooooooooooooong nameeeeeeeeeeeeeeeeeeeee - phew!',
    link: AD_SENDER_LINK,
    urn: 'urn',
    jobDescription:
      'New work SE - for a better working life - working better is very important so do better than yesterday as this also helps the poor and unheard - can I hear an amen?',
    followersCount: null,
  },
  siteName: 'https://apod.nasa.gov/apod/ap200118.html',
  videoId: '46.b866d0',
};

export const leadAd: LeadAdFieldsFragment = {
  sid: '130.afe3',
  __typename: 'AuctionLeadAd',
  title: 'My lead ad 3',
  type: AdTypes.LeadAd,
  surn: 'surn:x-xing:xas:ad:23381.1e37e6',
  trackingToken:
    '554987a97b206a8f.YWRfZGVsaXZlcnlfaWQ9MzIwNiZjaGFubmVsPXN0YXJ0cGFnZV93ZWImcHJpY2U9MC4wMTAwMCZ0aW1lPTE2MDQ0MjUyMjY',
  description: 'My lead ad description test',
  image: {
    __typename: 'AdImage',
    small: AD_IMAGE_LEAD_AD,
    large: null,
  },
  sender: {
    __typename: 'AdSender',
    image: IMAGE_PROFILE_ORIGINAL,
    name: 'Fancy Company name',
    link: AD_SENDER_LINK,
    urn: 'urn',
    jobDescription:
      'New work SE - for a better working life - working better is very important so do better than yesterday as this also helps the poor and unheard?',
  },
  buttonCopy: 'Abonnieren',
  leadAdFormId: '154.ced724',
};

export const leadAdFormData: Extract<
  LeadAdFormQuery['leadAdForm'],
  { __typename?: 'LeadAdForm' }
> = {
  __typename: 'LeadAdForm',
  id: '154.ced724',
  urn: 'surn:x-xing:xas:lead_ad_form:154.ced724',
  headline: 'Lead ad form headline',
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type spesadasd salök dl.",
  actionCopy: 'Please complete all of the fields.',
  buttonCopy: 'Subscribe',
  image: AD_IMAGE_LEAD_AD,
  formSections: [
    {
      __typename: 'LeadAdFormSection',
      id: '353.ab0e31',
      name: 'Your details:',
      type: LeadAdFormSections.CustomFields,
      fields: [
        {
          __typename: 'LeadAdFormField',
          id: '558.f6e4fc',
          name: 'Age',
          type: LeadAdFormFields.Text,
          prefilledValue: null,
          linkUrl: null,
          linkTextCopy: null,
        },
        {
          __typename: 'LeadAdFormField',
          id: '559.5c2bd0',
          name: 'City',
          type: LeadAdFormFields.Text,
          prefilledValue: null,
          linkUrl: null,
          linkTextCopy: null,
        },
        {
          __typename: 'LeadAdFormField',
          id: '560.58eb66',
          name: 'Another question',
          type: LeadAdFormFields.Text,
          prefilledValue: null,
          linkUrl: null,
          linkTextCopy: null,
        },
      ],
    },
    {
      __typename: 'LeadAdFormSection',
      id: '352.ba1e72',
      name: 'Your details',
      type: LeadAdFormSections.Address,
      fields: [
        {
          __typename: 'LeadAdFormField',
          id: '555.64cd05',
          name: 'First name',
          type: LeadAdFormFields.Text,
          prefilledValue: 'Sascha',
          linkUrl: null,
          linkTextCopy: null,
        },
        {
          __typename: 'LeadAdFormField',
          id: '556.a4b148',
          name: 'Last name',
          type: LeadAdFormFields.Text,
          prefilledValue: 'Cacqueux',
          linkUrl: null,
          linkTextCopy: null,
        },
        {
          __typename: 'LeadAdFormField',
          id: '557.712e7d',
          name: 'E-mail',
          type: LeadAdFormFields.Email,
          prefilledValue: 'sascha.cacqueux@xing.com',
          linkUrl: null,
          linkTextCopy: null,
        },
      ],
    },
    {
      __typename: 'LeadAdFormSection',
      id: '354.b536ec',
      name: 'Data processing information',
      type: LeadAdFormSections.TermsAndConditions,
      fields: [
        {
          __typename: 'LeadAdFormField',
          id: '308.15e535',
          name: "XING will save your data and make it available to the promoter of this ad who is responsible for using and processing your data. Contact us anytime if you'd like the data transfer to be stopped. If you want your data to be deleted or withdraw your permission for the processing of your data, please get in touch with the promoter of this ad.",
          type: LeadAdFormFields.Information,
          prefilledValue: 'true',
          linkUrl: 'https://privacy.xing.com/de/datenschutzerklaerung',
          linkTextCopy: 'XING Privacy Policy',
        },
        {
          __typename: 'LeadAdFormField',
          id: '307.f3b90a',
          name: 'Policies',
          type: LeadAdFormFields.Boolean,
          prefilledValue: 'false',
          linkUrl: 'https://my-policy.com',
          linkTextCopy: "XING Sales AG's privacy policy",
        },
      ],
    },
  ],
  thanksTitle: 'Thanks for your interest!',
  thanksText:
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when an unknown printer took a galley',
  thanksButtonCopy: 'XAM_LEAD_AD_FORM_THANKS_CTA_VISIT_XING_PAGE',
  thanksButtonUrl: '',
  sender: {
    __typename: 'LeadAdFormSender',
    image: IMAGE_PROFILE_ORIGINAL,
    name: 'XING Sales AG',
    urn: 'surn:x-xing:companies:company:28372.9f8d05',
    type: AdSenderTypes.Company,
    link: 'https://preview.xing.com/pages/xingsalesag',
  },
};

export const leadAdForm: LeadAdFormQuery = {
  __typename: 'Query',
  leadAdForm: leadAdFormData,
};

export function getAuctionWinnersMock({
  adTypes = [AdTypes.WebsiteAd],
  adCount = 1,
}: {
  adTypes: AdTypes[];
  adCount: number;
}): AdAuctionWinnersQuery {
  return {
    __typename: 'Query',
    adAuctionWinners: {
      __typename: 'AdAuctionWinners',
      collection: Array(adCount)
        .fill(undefined)
        .map((_, i) => {
          const adType = adTypes[i % adTypes.length];
          switch (adType) {
            case AdTypes.WebsiteAd:
              return websiteAd;
            case AdTypes.VideoAd:
              return videoAd;
            case AdTypes.LeadAd:
              return leadAd;
            default:
              return websiteAd;
          }
        }),
    },
  };
}

export function getAuctionWinnerAdPreviewMock(
  adId: number
): AdAuctionWinnerAdPreviewQuery {
  return {
    __typename: 'Query',
    adAuctionWinnerAdPreview: {
      ...([websiteAd, videoAd].find(
        ({ sid }) => parseInt(sid.split('.')[0]) === adId
      ) || websiteAd),
    },
  };
}

export function setupMocking({ worker, graphql }: msw): void {
  worker.use(
    graphql.mutation<LeadAdFormSubmissionCreateMutation>(
      'LeadAdFormSubmissionCreate',
      (_, res, ctx) => {
        return res(
          ctx.data({
            __typename: 'Mutation',
            leadAdFormSubmissionCreate: {
              __typename: 'LeadAdFormSubmissionCreateSuccess',
              status: 'ok',
            },
          })
        );
      }
    ),

    graphql.query<AdAuctionWinnersQuery>(
      'AdAuctionWinners',
      (req, res, ctx) => {
        return res(
          ctx.data(getAuctionWinnersMock(req.variables.auctionWinnersInput))
        );
      }
    )
  );

  worker.use(
    graphql.query<AdAuctionWinnerAdPreviewQuery>(
      'AdAuctionWinnerAdPreview',
      (req, res, ctx) => {
        return res(ctx.data(getAuctionWinnerAdPreviewMock(req.variables.adId)));
      }
    )
  );

  worker.use(
    graphql.query<LeadAdFormQuery>('LeadAdForm', (req, res, ctx) => {
      return res(ctx.data(leadAdForm));
    })
  );

  worker.use(
    graphql.query('Video', (_req, res, ctx) => {
      return res(
        ctx.data({
          __typename: 'Query',
          videoV2: {
            ref: '37.8c0ac9',
            globalId: 'surn:x-xing:videos:video:46.b866d0',
            ownerGlobalId: 'surn:x-xing:users:user:194.c66fb8',
            status: 'AVAILABLE',
            sources: [
              {
                __typename: 'VideoSource',
                format: 'application/x-mpegURL',
                source: VIDEO_FILE,
              },
              {
                __typename: 'VideoSource',
                format: 'application/dash+xml',
                source: VIDEO_FILE,
              },
            ],
            subtitles: null,
            defaultThumbnails: [
              {
                __typename: 'VideoThumbnail',
                sources: [
                  {
                    __typename: 'VideoImageSource',
                    source: AD_IMAGE_LARGE,
                    width: 1200,
                    height: 700,
                  },
                ],
              },
            ],
            customThumbnails: [
              {
                __typename: 'VideoThumbnail',
                sources: [
                  {
                    __typename: 'VideoImageSource',
                    source: AD_IMAGE_LARGE,
                    width: 1200,
                    height: 700,
                  },
                ],
              },
            ],
            sprites: [
              {
                __typename: 'VideoSprite',
                sources: [
                  {
                    __typename: 'VideoImageSource',
                    height: 45,
                    width: 80,
                    source: AD_IMAGE_LARGE,
                  },
                  {
                    __typename: 'VideoImageSource',
                    height: 90,
                    width: 160,
                    source: AD_IMAGE_LARGE,
                  },
                ],
              },
            ],
            __typename: 'VideoV2',
          },
        })
      );
    })
  );

  worker.use(
    graphql.mutation('createVideoTrackingEvent', (_req, res, ctx) => {
      return res(
        ctx.data({
          videoTracking: {
            __typename: 'VideoTrackingMutationResult',
            error: null,
          },
        })
      );
    })
  );

  worker.use(
    graphql.mutation('createContentReport', (_req, res, ctx) => {
      return res(ctx.delay(200), ctx.data(createContentReportMock()));
    })
  );
}
