import type { SVGProps } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { IconArrowRight } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { Marker as BaseMarker } from '@xing-com/marker';
import {
  cornerRadiusL,
  cornerRadiusXXL,
  motionEasingStandard,
  motionTimeS,
  scale040,
  scale060,
  spaceM,
  spaceS,
  spaceXS,
  spaceXXS,
  xdlColorTextOnDark,
  xdlPaletteOrange06,
  xdlPalettePurpleRain05,
  xdlPalettePurpleRain06,
} from '@xing-com/tokens';

export const Container = styled(Link)`
  &:hover,
  &:focus {
    text-decoration: none;

    & svg:only-of-type {
      transform: translateX(${spaceXXS});

      @media (prefers-reduced-motion) {
        transform: none;
      }
    }
  }
`;

export const Background = styled.div<{ $variant?: 'medium' | 'small' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  color: ${xdlColorTextOnDark};

  ${({ $variant }) =>
    $variant === 'small'
      ? css`
          padding: ${spaceS} ${spaceM};
          border-radius: ${cornerRadiusXXL};
        `
      : css`
          padding: ${spaceM} 20px;

          @media ${mediaConfined} {
            border-bottom-left-radius: ${cornerRadiusL};
            border-bottom-right-radius: ${cornerRadiusL};
          }
        `};

  // Animation magic from Silke
  background-color: ${xdlPalettePurpleRain06};
  --x1: 0.21;
  --y1: 0.79;
  --r1: 150;

  --x2: 0.5;
  --y2: 0.21;
  --r2: 198;

  --x3: 0.5;
  --y3: 0.21;
  --r3: 299;
  background-image: radial-gradient(
      circle calc(var(--r1) * 1px) at calc(var(--x1) * 100%)
        calc(var(--y1) * 100%),
      ${xdlPalettePurpleRain05} 0%,
      rgba(189, 133, 251, 0) 100%
    ),
    radial-gradient(
      circle calc(var(--r2) * 1px) at calc(var(--x2) * 100%)
        calc(var(--y2) * 100%),
      ${xdlPalettePurpleRain06} 0%,
      rgba(164, 86, 246, 0) 100%
    ),
    radial-gradient(
      circle calc(var(--r3) * 1px) at calc(var(--x3) * 100%)
        calc(var(--y3) * 100%),
      ${xdlPaletteOrange06} 0%,
      rgba(254, 154, 149, 0) 100%
    );

  @media (prefers-reduced-motion) {
    background-image: none;
  }
`;

export const Sparkles = styled.div`
  display: flex;
  padding: 1px;
`;

const Sparkle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M8 16L6.15456 11.4233C5.86851 10.7128 5.2872 10.1315 4.5767 9.84544L0 8L4.5767 6.15456C5.2872 5.86851 5.86851 5.2872 6.15456 4.5767L8 0L9.84544 4.5767C10.1315 5.2872 10.7128 5.86851 11.4233 6.15456L16 8L11.4233 9.84544C10.7128 10.1315 10.1315 10.7128 9.84544 11.4233L8 16Z"
      fill="white"
    />
  </svg>
);

const sparkles = keyframes`
  0% {
    transform: scale(1);
  }
  2% {
    transform: scale(0.2);
  }
  4% {
    transform: scale(1.2);
  }
  8% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Sparkle1 = styled(Sparkle)`
  width: ${scale060};
  height: ${scale060};
  animation: ${sparkles} 10s ease-in-out 2s infinite;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const Sparkle2 = styled(Sparkle)`
  width: ${scale040};
  height: ${scale040};
  margin-left: -${spaceXS};
  margin-top: ${spaceS};
  animation: ${sparkles} 10s ease-in-out 2.2s infinite;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const Marker = styled(BaseMarker)`
  flex-shrink: 0;
`;

export const MobileMarkerText = styled.span`
  @media ${mediaConfined} {
    display: none;
  }
`;

export const DesktopMarkerText = styled.span`
  display: none;

  @media ${mediaConfined} {
    display: block;
  }
`;

export const RightArrow = styled(IconArrowRight)`
  transition: transform ${motionTimeS} ${motionEasingStandard};
`;
