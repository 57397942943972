/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconIndustries = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconIndustries"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17 16v-2h-2v2zM17 18v2h-2v-2zM7 16h2v-2H7zM9 10v2H7v-2zM9 20v-2H7v2z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 2h2v4.557l8-3v7l8-3V22H3zm10 18v-7.307l6-2.25V20zM11 6.443V20H5V8.693z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconIndustries;
