export const trendingJobsStaticData = {
  queryTime: 1716276035710,
  data: [
    {
      id: 26866065,
      url: 'https://www.xing.com/jobs/duesseldorf-fachkraefte-fuer-systemadministration-120031000',
      country: 'DE',
      companyId: 1150353,
      companyUrl:
        'https://www.xing.com/pages/landeskriminalamt-nordrhein-westfalen',
      companyHasLogo: 1,
      numVisitors: 708,
      numVisitorsBefore: 8,
      hotnessScore: 60.67580803134182,
    },
    {
      id: 26915634,
      url: 'https://www.xing.com/jobs/nuernberg-produktions-lagermitarbeiter-120073947',
      country: 'DE',
      companyId: 408875,
      companyUrl:
        'https://www.xing.com/pages/h-o-tharte-undoberflachentechnikgmbh-co-kg',
      companyHasLogo: 1,
      numVisitors: 101,
      numVisitorsBefore: 1,
      hotnessScore: 9.892262487757101,
    },
    {
      id: 26915633,
      url: 'https://www.xing.com/jobs/wien-account-manager-120044117',
      country: 'AT',
      companyId: 141096,
      companyUrl: 'https://www.xing.com/pages/kununu',
      companyHasLogo: 1,
      numVisitors: 111,
      numVisitorsBefore: 4,
      hotnessScore: 2.9081782566111656,
    },
    {
      id: 26915630,
      url: 'https://www.xing.com/jobs/cham-assistenz-geschaeftsfuehrung-bueroassistenz-buerokauffrau-119980032',
      country: 'DE',
      companyId: 1961237,
      companyUrl: 'https://www.xing.com/pages/elegance-hair',
      companyHasLogo: 1,
      numVisitors: 197,
      numVisitorsBefore: 22,
      hotnessScore: 1.53481435312973,
    },
    {
      id: 26915631,
      url: 'https://www.xing.com/jobs/bremen-mitarbeiter-partnership-sales-120134291',
      country: 'DE',
      companyId: 308479,
      companyUrl: 'https://www.xing.com/pages/svwerderbremengmbh-cokgaa',
      companyHasLogo: 1,
      numVisitors: 1021,
      numVisitorsBefore: 0,
      hotnessScore: 1,
    },
    {
      id: 26915632,
      url: 'https://www.xing.com/jobs/hamburg-sachbearbeiter-vertriebsinnendienst-119893399',
      country: 'DE',
      companyId: 1972389,
      companyUrl: 'https://www.xing.com/pages/theodor-rumoeller-e-k',
      companyHasLogo: 1,
      numVisitors: 227,
      numVisitorsBefore: 44,
      hotnessScore: 0.9246950405128662,
    },
    {
      id: 26915635,
      url: 'https://www.xing.com/jobs/salmtal-technischer-vertriebskoordinator-119950616',
      country: 'DE',
      companyId: 1933421,
      companyUrl: 'https://www.xing.com/pages/coboworxgmbh',
      companyHasLogo: 1,
      numVisitors: 53,
      numVisitorsBefore: 3,
      hotnessScore: 0.8651648710414628,
    },
    {
      id: 13,
      url: 'https://www.xing.com/jobs/hamburg-office-manager-119966578',
      country: 'DE',
      companyId: 1540344,
      companyUrl: 'https://www.xing.com/pages/deutsche-investment',
      companyHasLogo: 1,
      numVisitors: 59,
      numVisitorsBefore: 5,
      hotnessScore: 0.6240940254652302,
    },
    {
      id: 2,
      url: 'https://www.xing.com/jobs/willich-leitung-soziale-dienste-119466711',
      country: 'DE',
      companyId: 1973925,
      companyUrl: 'https://www.xing.com/pages/onlyfy-talentservice',
      companyHasLogo: 1,
      numVisitors: 107,
      numVisitorsBefore: 17,
      hotnessScore: 0.5548193812294752,
    },
    {
      id: 2,
      url: 'https://www.xing.com/jobs/meerbusch-product-owner-119934846',
      country: 'DE',
      companyId: 69443,
      companyUrl: 'https://www.xing.com/pages/riegesoftwareinternationalgmbh',
      companyHasLogo: 1,
      numVisitors: 66,
      numVisitorsBefore: 7,
      hotnessScore: 0.5448439904855183,
    },
  ],
};
