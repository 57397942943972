import { setupMocking } from '@xing-com/crate-ads-native-ads/src/mocks';
import type { Xinglet, msw } from '@xing-com/crate-xinglet';

import { StartPageLoggedOut } from './startpage-logged-out';

export default class Entry implements Xinglet {
  public async registerMocks(msw: msw): Promise<void> {
    (await import('./mocks')).setupMocking(msw);

    setupMocking(msw);
  }

  public getComponent() {
    return (): JSX.Element => <StartPageLoggedOut />;
  }
}
