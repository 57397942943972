import styled from 'styled-components';

import { Checkbox as BaseCheckbox } from '@xing-com/checkbox';
import { Label as BaseLabel } from '@xing-com/label';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceL,
  xdlPaletteWhite,
  spaceXL,
  xdlColorText,
} from '@xing-com/tokens';

export const Container = styled.div`
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-content: center;
  gap: ${spaceXL};
  margin: ${spaceXL} 0 ${spaceL} 0;

  @media ${mediaConfined} {
    display: flex;
  }
`;

export const Checkbox = styled(BaseCheckbox)`
  background: ${xdlPaletteWhite};
`;

export const Label = styled(BaseLabel)`
  display: flex;
  gap: ${spaceM};
  cursor: pointer;
  color: ${xdlColorText};
`;
