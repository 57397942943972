import { SEARCH_STATE_PARAMS } from '@xing-com/crate-jobs-constants';
import type {
  FilterParamName,
  FilterPropertyName,
} from '@xing-com/crate-jobs-domain-search-types';

export const PROPERTY_NAME_TO_PARAM: Record<
  FilterPropertyName,
  FilterParamName
> = {
  employmentType: SEARCH_STATE_PARAMS.employmentType,
  remoteOption: SEARCH_STATE_PARAMS.remoteOption,
  careerLevel: SEARCH_STATE_PARAMS.careerLevel,
  discipline: SEARCH_STATE_PARAMS.discipline,
  industry: SEARCH_STATE_PARAMS.industry,
  country: SEARCH_STATE_PARAMS.country,
  city: SEARCH_STATE_PARAMS.city,
  benefit: SEARCH_STATE_PARAMS.benefit,
  benefitWorkingCulture: SEARCH_STATE_PARAMS.benefit,
  benefitEmployeePerk: SEARCH_STATE_PARAMS.benefit,
  company: SEARCH_STATE_PARAMS.company,
};

type FilterTypename =
  | 'JobFilterBenefit'
  | 'JobFilterBenefitEmployeePerk'
  | 'JobFilterBenefitWorkingCulture'
  | 'JobFilterCareerLevel'
  | 'JobFilterCity'
  | 'JobFilterCompany'
  | 'JobFilterCountry'
  | 'JobFilterDiscipline'
  | 'JobFilterEmploymentType'
  | 'JobFilterIndustry'
  | 'JobFilterRemoteOption';

export const FILTER_TYPENAME_TO_PARAM: Record<FilterTypename, FilterParamName> =
  {
    JobFilterBenefit: SEARCH_STATE_PARAMS.benefit,
    JobFilterBenefitEmployeePerk: SEARCH_STATE_PARAMS.benefit,
    JobFilterBenefitWorkingCulture: SEARCH_STATE_PARAMS.benefit,
    JobFilterCareerLevel: SEARCH_STATE_PARAMS.careerLevel,
    JobFilterCity: SEARCH_STATE_PARAMS.city,
    JobFilterCompany: SEARCH_STATE_PARAMS.company,
    JobFilterCountry: SEARCH_STATE_PARAMS.country,
    JobFilterDiscipline: SEARCH_STATE_PARAMS.discipline,
    JobFilterEmploymentType: SEARCH_STATE_PARAMS.employmentType,
    JobFilterIndustry: SEARCH_STATE_PARAMS.industry,
    JobFilterRemoteOption: SEARCH_STATE_PARAMS.remoteOption,
  };

export const FILTER_TYPENAME_TO_PROPERTY_NAME: Record<
  FilterTypename,
  FilterPropertyName
> = {
  JobFilterBenefit: SEARCH_STATE_PARAMS.benefit,
  JobFilterBenefitEmployeePerk: 'benefitEmployeePerk',
  JobFilterBenefitWorkingCulture: 'benefitWorkingCulture',
  JobFilterCareerLevel: SEARCH_STATE_PARAMS.careerLevel,
  JobFilterCity: SEARCH_STATE_PARAMS.city,
  JobFilterCompany: SEARCH_STATE_PARAMS.company,
  JobFilterCountry: SEARCH_STATE_PARAMS.country,
  JobFilterDiscipline: SEARCH_STATE_PARAMS.discipline,
  JobFilterEmploymentType: SEARCH_STATE_PARAMS.employmentType,
  JobFilterIndustry: SEARCH_STATE_PARAMS.industry,
  JobFilterRemoteOption: SEARCH_STATE_PARAMS.remoteOption,
};
