import type { SearchAlertFragment } from '@xing-com/crate-jobs-graphql';

import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';
import { SearchAlertsCarouselSkeleton } from './search-alerts-carousel-skeleton';

export type SearchAlertsCarouselProps = {
  alerts: SearchAlertFragment[];
};

export type SearchAlertsCarouselXinglet =
  XingletInterface<SearchAlertsCarouselProps>;

export const SearchAlertsCarousel =
  JobsXingletWrapper<SearchAlertsCarouselProps>(
    '@xing-com/crate-jobs-domain-search-alerts-carousel',
    <SearchAlertsCarouselSkeleton />
  );
