import type { QueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Cookie from 'js-cookie';

import type { AdTypes } from '@xing-com/crate-common-graphql-types';

import type { AdAuctionWinnersQuery } from '../auction-ads.gql-types';
import { AdAuctionWinnersDocument } from '../auction-ads.gql-types';

export type AdSettings = {
  adCount: number;
  adTypes: AdTypes[];
  channel: string;
  isLoggedOut?: boolean;
  skipQuery?: boolean;
};

export const useAdsNetwork = (
  settings: AdSettings
): QueryResult<AdAuctionWinnersQuery, Record<string, unknown>> => {
  const scrambledId = Cookie.get('s_id2');

  // Prevent delivery of ads in case we can't identify the logged out user.
  const skipLoggedOutQuery = settings.isLoggedOut && !scrambledId;
  const addScrambledId = settings.isLoggedOut && scrambledId;
  const skipQuery = settings.skipQuery;

  delete settings.isLoggedOut;
  delete settings.skipQuery;

  return useQuery<AdAuctionWinnersQuery>(AdAuctionWinnersDocument, {
    variables: {
      auctionWinnersInput: {
        ...settings,
        ...(addScrambledId && { scrambledId }),
      },
    },
    fetchPolicy: 'network-only',
    skip: skipQuery || skipLoggedOutQuery,
  });
};
