import { useIntl } from 'react-intl';

import { IconStatusUnread, IconTick } from '@xing-com/icons';
import { BodyCopy, Meta } from '@xing-com/typography';
import { Row } from '@xing-com/xing-grid';

import background_image from './assets/background-register-section.webp';
import {
  Container,
  UpperSectionCardContainer,
  IconWrapper,
  Image,
  StyledCard,
  LowerSectionCardContainer,
  PillWrapper,
  PillTag,
} from './image-subsection.styles';

export const ImageSubsection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Row>
      <Container size={9} sizeConfined={12}>
        <Image src={background_image} alt="register-section-background" />
        <StyledCard noPadding>
          <UpperSectionCardContainer>
            <IconWrapper>
              <IconStatusUnread height={18} width={18} />
              <BodyCopy
                size="small"
                sizeConfined="medium"
                fontWeight="bold"
                noMargin
              >
                {formatMessage({ id: 'REGISTER_SECTION_HEADLINE' })}
              </BodyCopy>
            </IconWrapper>
            <Meta size="xsmall" sizeConfined="small" noMargin>
              {formatMessage({ id: 'REGISTER_SECTION_DATE_MARK' })}
            </Meta>
          </UpperSectionCardContainer>
          <LowerSectionCardContainer>
            <PillWrapper>
              <IconTick height={13} width={13} />
              <PillTag noMargin sizeConfined="xsmall" sizeWide="small">
                {formatMessage({ id: 'REGISTER_SECTION_FIRST_TAG' })}
              </PillTag>
            </PillWrapper>
            <PillWrapper>
              <IconTick height={13} width={13} />
              <PillTag noMargin sizeConfined="xsmall" sizeWide="small">
                {formatMessage({ id: 'REGISTER_SECTION_SECOND_TAG' })}
              </PillTag>
            </PillWrapper>
            <PillWrapper>
              <IconTick height={13} width={13} />
              <PillTag noMargin sizeConfined="xsmall" sizeWide="small">
                {formatMessage({ id: 'REGISTER_SECTION_THIRD_TAG' })}
              </PillTag>
            </PillWrapper>
          </LowerSectionCardContainer>
        </StyledCard>
      </Container>
    </Row>
  );
};
