import styled from 'styled-components';

import { mediaNavExtended } from '@xing-com/layout-tokens';
import { Tag } from '@xing-com/tag';
import {
  scale030,
  spaceM,
  spaceS,
  xdlColorBackgroundTertiary,
  xdlColorBorderSoft,
  xdlColorTextSecondary,
  xdlColorTextTertiary,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const HideMobile = styled.span`
  display: none;

  @media ${mediaNavExtended} {
    display: flex;
  }
`;

export const HideDesktop = styled.span`
  display: flex;

  @media ${mediaNavExtended} {
    display: none;
  }
`;

export const StyledPillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${spaceM};

  @media ${mediaNavExtended} {
    flex-wrap: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledHeader = styled(BodyCopy)`
  color: ${xdlColorTextSecondary};

  &&& {
    margin-bottom: ${scale030};
  }
`;

export const StyledPill = styled(Tag)`
  border-radius: 100px 100px 100px 100px;
  border: 1px solid ${xdlColorBorderSoft};
  padding: 16px;
  height: 44px;
  margin-right: ${spaceS};
  display: inline-flex;

  @media ${mediaNavExtended} {
    margin-right: 0;
  }
`;

export const StyledFirstPill = styled(StyledPill)`
  &&& {
    border-radius: 100px 0 0 100px;
  }
`;

export const StyledMiddlePill = styled(StyledPill)`
  &&& {
    border-radius: 0 0 0 0;
    border-left: none;
  }
`;

export const StyledLastPill = styled(StyledPill)`
  &&& {
    border-left: none;
    border-radius: 0 100px 100px 0;
    padding: 10px 16px 10px 8px;
    background: ${xdlColorBackgroundTertiary};

    & svg,
    & div,
    & span {
      width: 24px;
      height: 24px;
      color: ${xdlColorTextTertiary};
    }
  }
`;
