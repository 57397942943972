import styled from 'styled-components';

import { MGapCol, SGapCol } from '@xing-com/crate-jobs-components-columns';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import {
  SkeletonBody,
  SkeletonButton,
  SkeletonHeadline,
  SkeletonPillShape,
  SkeletonProfileImage,
} from '@xing-com/skeleton';
import {
  scale090,
  scale200,
  scale210,
  scale260,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXS,
} from '@xing-com/tokens';

const ListItemContainer = styled.article`
  display: flex;
  padding: ${spaceL} 0;

  @media ${mediaWide} {
    padding: ${spaceXL} ${spaceM};
  }
`;

const Image = styled(SkeletonProfileImage)`
  flex-shrink: 0;
`;

const ListItemText = styled.div`
  margin-left: ${spaceM};
  width: 100%;
`;

const BodyHeadline = styled(SkeletonHeadline)`
  & > div {
    height: ${scale090};
    margin-bottom: ${spaceS};
  }
`;

const Body = styled(SkeletonBody)`
  & > div {
    width: 66%;
  }
`;

const PillContainer = styled.div`
  display: flex;
  gap: ${spaceXS};
  flex-wrap: wrap;
`;

const Date = styled(SkeletonBody)`
  width: ${scale210};

  & > div {
    margin-bottom: ${spaceS};
    height: ${scale090};
  }

  @media ${mediaConfined} {
    display: none;
  }
`;

const RightColumn = styled.div`
  display: none;

  @media ${mediaConfined} {
    display: flex;
    flex-direction: column;
    gap: ${spaceL};
    margin-left: ${scale200};
    align-items: flex-end;
  }
`;

const Button = styled(SkeletonButton)`
  width: ${scale260};
`;

type Props = {
  className?: string;
};

export const JobTeaserListItemSkeleton: React.FC<Props> = ({ className }) => {
  return (
    <ListItemContainer
      data-testid="job-teaser-list-item-skeleton"
      className={className}
    >
      <Image size="small" sizeConfined="large" />
      <ListItemText>
        <MGapCol>
          <SkeletonPillShape height="20px" width="33%" />
          <SGapCol>
            <BodyHeadline amountRows={2} />
            <Body amountRows={2} />
          </SGapCol>
          <PillContainer>
            <SkeletonPillShape height="20px" width="50%" />
            <SkeletonPillShape height="20px" width="33%" />
            <SkeletonPillShape height="20px" width="33%" />
          </PillContainer>
          <Date amountRows={1} />
        </MGapCol>
      </ListItemText>
      <RightColumn>
        <Date amountRows={1} />
        <Button size="small" />
      </RightColumn>
    </ListItemContainer>
  );
};
