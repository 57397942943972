import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import type { AutocompletionLocationsCitySuggestionFragment } from '@xing-com/crate-jobs-graphql';
import { GetLocationSuggestionsDocument } from '@xing-com/crate-jobs-graphql';
import type { LocationSuggestion } from '@xing-com/crate-jobs-hooks';

type UseLocationAutocompletion = {
  locationSuggestions: LocationSuggestion[];
  fetchLocationSuggestions: (query: string) => void;
};

type UseLocationAutocompletionProps = {
  limit?: number;
  debounceTime?: number;
  countries?: Array<string>;
  consumer?: string;
};

const DEFAULT_LIMIT = 5;
const DEFAULT_DEBOUNCE_TIME_MS = 250;

/**
 * Reduces the location suggestions to a map of duplicated suggestions.
 * In order to know if a location is duplicated, the item is converted to a string
 * with the following format: `name, adminArea, formattedCountry`.
 */
const reduceDupSuggestionsToMap = (
  current: Record<string, boolean>,
  item: AutocompletionLocationsCitySuggestionFragment | null
): Record<string, boolean> => {
  const { name, adminArea, formattedCountry } = item || {};
  const key = `${name}, ${adminArea}, ${formattedCountry}`;
  return { ...current, [key]: current[key] !== undefined };
};

/**
 * Reduces the location suggestions to a list of unique suggestions.
 * Uses the `duplicatedSuggestionsMap` to check if a suggestion is duplicated.
 */
const reduceLocationSuggestions =
  (duplicatedSuggestionsMap: Record<string, boolean>) =>
  (
    current: LocationSuggestion[],
    item: AutocompletionLocationsCitySuggestionFragment | null
  ): LocationSuggestion[] => {
    const {
      cityIdWithScrambling,
      name,
      adminArea3,
      adminArea,
      formattedCountry,
    } = item || {};

    if (!cityIdWithScrambling || !name || !adminArea || !formattedCountry) {
      return current;
    }

    const suggestionKey = `${name}, ${adminArea}, ${formattedCountry}`;
    const isDuplicated = duplicatedSuggestionsMap[suggestionKey];
    const meta =
      isDuplicated && adminArea3
        ? `${adminArea3}, ${adminArea}, ${formattedCountry}`
        : `${adminArea}, ${formattedCountry}`;

    return [
      ...current,
      {
        cityId: cityIdWithScrambling,
        name,
        meta,
      },
    ];
  };

export const useLocationAutocompletion = ({
  limit = DEFAULT_LIMIT,
  debounceTime = DEFAULT_DEBOUNCE_TIME_MS,
  countries,
  consumer = 'react.location.search.jobs.form',
}: UseLocationAutocompletionProps = {}): UseLocationAutocompletion => {
  const [locationSuggestions, setLocationSuggestions] = useState<
    LocationSuggestion[]
  >([]);
  const { locale: language } = useIntl();
  const [getSuggestions, { data, loading }] = useLazyQuery(
    GetLocationSuggestionsDocument
  );

  const collection = data?.autocompletionLocationsCity?.collection;

  const fetchLocationSuggestions = useMemo(
    () =>
      debounce((query: string): void => {
        getSuggestions({
          variables: {
            consumer,
            text: query,
            language,
            limit: DEFAULT_LIMIT,
            countries,
          },
        });
      }, debounceTime),
    [debounceTime, getSuggestions, consumer, language, countries]
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!collection || collection.length === 0) {
      setLocationSuggestions([]);
      return;
    }

    const duplicatedSuggestionsMap = collection.reduce(
      reduceDupSuggestionsToMap,
      {}
    );
    setLocationSuggestions(
      collection.reduce(reduceLocationSuggestions(duplicatedSuggestionsMap), [])
    );
  }, [loading, collection, limit]);
  return { locationSuggestions, fetchLocationSuggestions };
};
