// import AirbusPhoto from '../assets/airbus-photo.webp';
import AmazonPhoto from '../assets/amazon-photo.webp';
// import AudiPhoto from '../assets/audi-photo.webp';
import BahneGrothPhoto from '../assets/bahne-groth-photo.webp';
import BdoPhoto from '../assets/bdo-photo.webp';
import BechtlePhoto from '../assets/bechtle-photo.webp';
// import BoschPhoto from '../assets/bosch-photo.webp';
import CarolaLudewigPhoto from '../assets/carola-ludewig-photo.webp';
import DeutscheBahnPhoto from '../assets/db-photo.webp';
// import DeutscheBankPhoto from '../assets/deutsche-bank-photo.webp';
import DisPhoto from '../assets/dis-photo.webp';
import DsvPhoto from '../assets/dsv-photo.webp';
import EdekaPhoto from '../assets/edeka-photo.webp';
import FatmaKocPhoto from '../assets/fatma-koc-photo.webp';
import FinjaPhoto from '../assets/finja-struber.256x256.webp';
import FlaschenpostPhoto from '../assets/flaschenpost-photo.webp';
import HaysPhoto from '../assets/hays-photo.webp';
import HolmesPhoto from '../assets/holmes-photo.webp';
import HuseyinYalcinPhoto from '../assets/huseyin-photo.webp';
import IsabelStruschkaPhoto from '../assets/isabel-photo.webp';
import JanPhoto from '../assets/jan-gotze.256x256.webp';
import JanSchengberPhoto from '../assets/jan-schengber-photo.webp';
import JohannaPhoto from '../assets/johanna-eckert.256x256.webp';
import JonasPhoto from '../assets/jonas-masanneck.256x256.webp';
import JuliaReichhoferPhoto from '../assets/julia-reichhofer-photo.webp';
import KosterPhoto from '../assets/koster-photo.webp';
import KrakenhausPhoto from '../assets/krakenhaus-photo.webp';
import KristinPhoto from '../assets/kristin-semelka.256x256.webp';
import MaltePhoto from '../assets/malte-balmer.256x256.webp';
import MariaPhoto from '../assets/maria-heucke.256x256.webp';
// import MeinestadtPhoto from '../assets/meinestadt-photo.webp';
import MigrosPhoto from '../assets/migros-photo.webp';
import MiriamPhoto from '../assets/miriam--djambou.256x256.webp';
import OetkerPhoto from '../assets/oetker-photo.webp';
import OnlyFyPhoto from '../assets/onlyfy-photo.webp';
import OttoPhoto from '../assets/otto-photo.webp';
import OttobockPhoto from '../assets/ottobock-photo.webp';
import PwcPhoto from '../assets/pwc-photo.webp';
import RalphOstermeierPhoto from '../assets/ralph-ostermeier-photo.webp';
import RobinMaurerPhoto from '../assets/robin-maurer-photo.webp';
import RossmanPhoto from '../assets/rossman-photo.webp';
import SarahPhoto from '../assets/sarah-baudzus.256x256.webp';
import SchmersalPhoto from '../assets/schmersal-photo.webp';
import StephaniePhoto from '../assets/stefanie-klock.256x256.webp';
import TerbergPhoto from '../assets/terberg-photo.webp';
import VanessaPhoto from '../assets/vanessa-strauch.256x256.webp';
import VeronikaPhoto from '../assets/veronika-gersdorf.256x256.webp';

export interface Recruiter {
  id: number;
  photoUrl: string;
  flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS';
  name: string;
  companyLogo: string;
  jobTitle: string;
  insight: string;
  company: string;
  profileUrl: string;
}

export const RecruitersData: Recruiter[] = [
  {
    id: 1,
    photoUrl: FinjaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Finja Strüber',
    companyLogo: RossmanPhoto,
    jobTitle: 'Talent Acquisition & Employer Branding',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_FINJA_STRUBER',
    company: 'Dirk Rossmann',
    profileUrl: 'https://www.xing.com/profile/Finja_Strueber',
  },
  {
    id: 2,
    photoUrl: MaltePhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Malte Balmer',
    companyLogo: OttoPhoto,
    jobTitle: 'Senior HR Manager Recruiting',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MALTE_BALMER',
    company: 'OTTO',
    profileUrl: 'https://www.xing.com/profile/Malte_Balmer',
  },
  {
    id: 3,
    photoUrl: JanPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Jan Götze',
    companyLogo: DeutscheBahnPhoto,
    jobTitle: 'Leiter Active Sourcing IT-Akademiker·innen',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JAN_GOTZE',
    company: 'DB',
    profileUrl: 'https://www.xing.com/profile/Jan_Goetze8',
  },
  {
    id: 4,
    photoUrl: StephaniePhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Stefanie Klöck',
    companyLogo: HaysPhoto,
    jobTitle: 'Senior Active Sourcing Specialist',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_STEFANIE_KLOCK',
    company: 'Hays',
    profileUrl: 'https://www.xing.com/profile/Stefanie_Kloeck',
  },
  {
    id: 5,
    photoUrl: JohannaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Johanna Eckert',
    companyLogo: BdoPhoto,
    jobTitle: 'Recruiting Specialist',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JOHANNA_ECKERT',
    company: 'BDO',
    profileUrl: 'https://www.xing.com/profile/Johanna_Eckert6',
  },
  {
    id: 6,
    photoUrl: MiriamPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Miriam Djambou',
    companyLogo: DisPhoto,
    jobTitle: 'Recruiterin',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MIRIAM_DJAMBOU',
    company: 'DIS AG',
    profileUrl: 'https://www.xing.com/profile/Miriam_Djambou042705',
  },
  {
    id: 7,
    photoUrl: SarahPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Sarah Baudzus',
    companyLogo: KrakenhausPhoto,
    jobTitle: 'Recruiterin',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SARAH_BAUDZUS',
    company: 'Krankenhaus Reinbek St. Adolf-Stift',
    profileUrl: 'https://www.xing.com/profile/Sarah_Baudzus',
  },
  {
    id: 8,
    photoUrl: KristinPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Kristin Semelka',
    companyLogo: TerbergPhoto,
    jobTitle: 'Recruiting',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_KRISTIN_SEMELKA',
    company: 'TERBERG Spezialfahrzeuge',
    profileUrl: 'https://www.xing.com/profile/Kristin_Semelka',
  },
  {
    id: 9,
    photoUrl: MariaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Maria Heucke',
    companyLogo: OttobockPhoto,
    jobTitle: 'Recruiterin',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MARIA_HEUCKE',
    company: 'Ottobock',
    profileUrl: 'https://www.xing.com/profile/Maria_Heucke',
  },
  {
    id: 10,
    photoUrl: VanessaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Vanessa Strauch',
    companyLogo: DsvPhoto,
    jobTitle: 'Recruiting Specialist DACH',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_VANESSA_STRAUCH',
    company: 'DSV',
    profileUrl: 'https://www.xing.com/profile/Vanessa_Strauch15',
  },
  {
    id: 11,
    photoUrl: JonasPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Jonas Masanneck',
    companyLogo: OnlyFyPhoto,
    jobTitle: 'Recruiting Consultant',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JONAS_MASANNECK',
    company: 'onlyfy TalentService',
    profileUrl: 'https://www.xing.com/profile/Jonas_Masanneck',
  },
  {
    id: 12,
    photoUrl: VeronikaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Veronika Gersdorf',
    companyLogo: HolmesPhoto,
    jobTitle: 'Recruiting & Employer Branding Specialist',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_VERONIKA_GERSDORF',
    company: 'Holmes Place',
    profileUrl: 'https://www.xing.com/profile/Veronika_Gersdorf',
  },
  {
    id: 13,
    photoUrl: RalphOstermeierPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Ralph Ostermeier',
    companyLogo: EdekaPhoto,
    jobTitle: 'Tech Recruiter',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_RALPH_OSTERMEIER',
    company: 'EDEKA DIGITAL',
    profileUrl: 'https://www.xing.com/profile/Ralph_Ostermeier/',
  },
  {
    id: 14,
    photoUrl: JanSchengberPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Jan Schengber',
    companyLogo: KosterPhoto,
    jobTitle: 'HR Manager',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JAN_SCHENGBER',
    company: 'Köster GmbH',
    profileUrl: 'https://www.xing.com/profile/Jan_Schengber',
  },
  {
    id: 15,
    photoUrl: HuseyinYalcinPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Hüseyin Yalcin',
    companyLogo: BechtlePhoto,
    jobTitle: 'Teamkoordinator Active Sourcing',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_HUSEYIN_YALCIN',
    company: 'Bechtle',
    profileUrl: 'https://www.xing.com/profile/Hueseyin_Yalcin24/',
  },
  {
    id: 16,
    photoUrl: FatmaKocPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Fatma Koc',
    companyLogo: PwcPhoto,
    jobTitle: 'Associate - Talent Acquisition',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_FATMA_KOC',
    company: 'PwC Deutschland',
    profileUrl: 'https://www.xing.com/profile/FatmaGuel_Koc3/',
  },
  {
    id: 17,
    photoUrl: RobinMaurerPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Robin Maurer',
    companyLogo: MigrosPhoto,
    jobTitle: 'Teamleiter Recruiting',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ROBIN_MAURER',
    company: 'Migros',
    profileUrl: 'https://www.xing.com/profile/Robin_Maurer10',
  },
  {
    id: 18,
    photoUrl: BahneGrothPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Bahne Groth',
    companyLogo: FlaschenpostPhoto,
    jobTitle: 'Talent Acquisition Manager',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BAHNE_GROTH',
    company: 'flaschenpost SE',
    profileUrl: 'https://www.xing.com/profile/Bahne_Groth2',
  },
  {
    id: 19,
    photoUrl: IsabelStruschkaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Isabel Struschka',
    companyLogo: OetkerPhoto,
    jobTitle: 'Professional Process & Recruiting',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ISABEL_STRUSCHKA',
    company: 'Dr. Oetker',
    profileUrl: 'https://www.xing.com/profile/Isabel_Struschka/',
  },
  {
    id: 20,
    photoUrl: CarolaLudewigPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Carola Ludewig',
    companyLogo: AmazonPhoto,
    jobTitle: 'Talent Acquisition Candidate Partner (TACP)',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_CAROLA_LUDEWIG',
    company: 'Amazon',
    profileUrl: 'https://www.xing.com/profile/Carola_Ludewig/',
  },
  {
    id: 21,
    photoUrl: JuliaReichhoferPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_RECRUITERS',
    name: 'Julia Reichhofer',
    companyLogo: SchmersalPhoto,
    jobTitle: 'Senior Recruiter',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_JULIA_REICHHOFER',
    company: 'K.A. Schmersal GmbH & Co. KG',
    profileUrl: 'https://www.xing.com/profile/Julia_Reichhofer',
  },
];
