export const TeaserCardsData = [
  {
    id: 1,
    headline: 'LEBENSLAUF_TEASER_CARD_TITLE',
    description: 'LEBENSLAUF_TEASER_CARD_DESCRIPTION',
    trackingParam: '?sc_o=losp_lebenslauf_teaser_click',
    accessibilityDescription: 'Lebenslauf',
    url: '/lebenslauf',
  },
  {
    id: 2,
    headline: 'PROFILE_TEASER_CARD_TITLE',
    description: 'PROFILE_TEASER_CARD_DESCRIPTION',
    trackingParam:
      '?sc_o=losp_sign_up_button_click&sc_o_PropActionOrigin=losp_create_profile_teaser&signup_channel=minireg_losp',
    accessibilityDescription: 'Profile',
    url: '/start/signup',
  },
  {
    id: 3,
    headline: 'CAREER_HUB_TEASER_CARD_TITLE',
    description: 'CAREER_HUB_TEASER_CARD_DESCRIPTION',
    trackingParam: '?sc_o=losp_career_hub_teaser_click',
    accessibilityDescription: 'Career Hub',
    url: '/karriere-ratgeber',
  },
];
