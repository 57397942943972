import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  DE_JOB_MANAGER_URL,
  EN_JOB_MANAGER_URL,
} from '@xing-com/crate-jobs-constants';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { spaceM, spaceXL, xdlColorTextOnLight } from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

const StyledBodyCopy = styled(BodyCopy)<{ $withBanner?: boolean }>`
  display: none;
  text-align: center;
  color: ${xdlColorTextOnLight};

  @media ${mediaConfined} {
    display: block;
    padding-top: ${({ $withBanner }) => ($withBanner ? spaceM : spaceXL)};
  }
`;

const RecruitersLink = styled(Link)`
  color: ${xdlColorTextOnLight};
  font-weight: 700;
`;

type Props = {
  withBanner: boolean;
};

export const Teaser: React.FC<Props> = ({ withBanner }) => {
  const { locale } = useIntl();
  const url = locale === 'en' ? EN_JOB_MANAGER_URL : DE_JOB_MANAGER_URL;

  return (
    <StyledBodyCopy noMargin $withBanner={withBanner}>
      <FormattedMessage
        id="JOBS_FIND_JOBS_RECRUITERS_LINK"
        values={{
          a: (chunks) => (
            <RecruitersLink
              to={`${url}?sc_o=jobs_post_job_ad&sc_cmp=oowecdf8a53`}
            >
              {chunks}
            </RecruitersLink>
          ),
        }}
      />
    </StyledBodyCopy>
  );
};
