import styled from 'styled-components';

import { mediaWideNavless } from '@xing-com/layout-tokens';
import { space3XL } from '@xing-com/tokens';

export const Box = styled.div`
  max-width: 739px;
  margin: 0 auto;
  padding-bottom: ${space3XL};

  @media ${mediaWideNavless} {
    max-width: 972px;
  }
`;
