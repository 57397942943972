import type * as React from 'react';
import type {
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components';
import styled, { css } from 'styled-components';

import {
  GUTTER,
  NUM_COLS,
  MIN_WIDTH,
  CONTAINER_WIDE,
  CONTAINER_REGULAR,
  CONTAINER_NARROW,
} from '@xing-com/layout-primitives';

import { MEDIA_QUERIES, PADDING_LOGGED_OUT } from './legacy-constants';

const CONTAINER_WIDTHS = {
  LoggedOut: CONTAINER_WIDE,
  FullSize: CONTAINER_REGULAR,
  TwoCol: CONTAINER_REGULAR,
  Narrow: CONTAINER_NARROW,
};

export type Variant = 'LoggedOut' | 'FullSize' | 'TwoCol' | 'Narrow';

type VariantPropsStyled = {
  $variant?: Variant;
};

type VariantProps = {
  variant?: Variant;
};

// used for the grid structure no width awareness
const gridStyles = css<VariantPropsStyled>`
  display: grid;
  gap: ${GUTTER};
  grid-template-columns: repeat(${NUM_COLS}, 1fr);
  width: 100%;
  ${MEDIA_QUERIES.MEDIA_MEDIUM} {
    margin: 0 auto;
  }
`;

// defines outer wrapper styles
// i.e. 2 columns for TwoCol or 12 for FullSize
const wrapperStyles = ({
  $variant = 'FullSize',
}): FlattenInterpolation<ThemedStyledProps<VariantPropsStyled, unknown>> => {
  switch ($variant) {
    case 'LoggedOut':
      return css`
        ${gridStyles}
        padding: 0 ${PADDING_LOGGED_OUT}px;
      `;
    case 'FullSize':
      return gridStyles;

    case 'TwoCol':
      return css`
        ${MEDIA_QUERIES.MEDIA_MEDIUM} {
          display: grid;
          gap: ${GUTTER}px;
          grid-template-rows: auto;
          // note fr declaration nr needs to match
          // template areas nr of declarations
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: 'main main aside';
          margin: 0 auto;
        }
      `;

    case 'Narrow':
      return gridStyles;

    default:
      return gridStyles;
  }
};

// only defines widths
const widthStyles = css<VariantPropsStyled>`
  width: 100%;
  min-width: ${MIN_WIDTH}px;
  /* build max-width mediaquery css */
  ${({ $variant = 'FullSize' }) => css`
    max-width: ${CONTAINER_WIDTHS[$variant]}px;
  `}
`;

const StyledContainer = styled.section<VariantPropsStyled>`
  ${widthStyles}
  ${wrapperStyles}
`;

type GridContainerProps = {
  children?: React.ReactNode | undefined;
} & VariantProps;

export const LegacyGridContainer: React.FC<GridContainerProps> = ({
  children,
  variant,
  ...props
}) => {
  return (
    <StyledContainer $variant={variant} {...props}>
      {children}
    </StyledContainer>
  );
};
