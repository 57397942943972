import type { CreateContentReportMutation } from './create-content-report-mutation.gql-types';

export const createContentReportMock = (
  id = '1'
): CreateContentReportMutation => ({
  __typename: 'Mutation',
  complaintsCreateContentReport: {
    __typename: 'ComplaintsCreateContentReportResult',
    success: {
      __typename: 'ComplaintsCreateContentReportSuccess',
      report: {
        __typename: 'ComplaintsReport',
        id,
      },
    },
  },
});
