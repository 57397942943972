import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { GradientMarker } from '@xing-com/crate-jobs-components-gradient-marker';
import { IconAIFilled } from '@xing-com/icons';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  xdlColorTextOnLight,
  spaceXL,
  spaceXXL,
  space3XL,
  spaceXS,
  spaceL,
} from '@xing-com/tokens';
import { Hero as BaseHero } from '@xing-com/typography';

type Props = {
  spacious?: boolean;
  isAiSearchForced: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Marker = styled(GradientMarker)`
  margin-top: ${spaceXL};
  margin-bottom: -${spaceXL};
  @media ${mediaConfined} {
    margin-bottom: -${spaceXS};
  }
`;

const Hero = styled(BaseHero)<{ $spacious?: boolean }>`
  padding-top: ${spaceXXL};
  text-align: center;
  color: ${xdlColorTextOnLight};
  margin: 0 20px ${({ $spacious }) => ($spacious ? spaceXXL : spaceXL)} 20px;
  text-wrap: balance;

  @media ${mediaConfined} {
    padding-top: ${spaceL};
    margin-bottom: ${({ $spacious }) => ($spacious ? space3XL : spaceXL)};
  }
`;

export const Title: React.FC<Props> = ({ spacious, isAiSearchForced }) => {
  return isAiSearchForced ? (
    <Container>
      <Marker icon={IconAIFilled} variant="strong">
        <FormattedMessage id="JOBS_SEARCH_AI_FLAG" />
      </Marker>
      <Hero size="small" sizeConfined="medium">
        <FormattedMessage id="JOBS_SEARCH_AI_HEADLINE" />
      </Hero>
    </Container>
  ) : (
    <Hero size="small" sizeConfined="medium" $spacious={spacious}>
      <FormattedMessage id="JOBS_FIND_JOBS_HEADER_TITLE" />
    </Hero>
  );
};
