import type { ButtonHTMLAttributes } from 'react';

import { IconAIFilled } from '@xing-com/icons';
import { Marker } from '@xing-com/marker';
import { BodyCopy } from '@xing-com/typography';

import * as S from './suggestion.style';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  condensed?: boolean;
};

export const SearchSuggestion: React.FC<Props> = ({
  Icon,
  title,
  subtitle,
  condensed,
  ...props
}) => (
  <S.Suggestion {...props} $condensed={condensed} type="button">
    <Icon width={24} height={24} />
    <S.SuggestionText>
      <S.SuggestionTitle>
        <BodyCopy size="large" noMargin>
          {title}
        </BodyCopy>
        <Marker icon={IconAIFilled} variant="strong">
          New
        </Marker>
      </S.SuggestionTitle>
      <S.SuggestionSubtitle size="small" noMargin>
        {subtitle}
      </S.SuggestionSubtitle>
    </S.SuggestionText>
  </S.Suggestion>
);
