import { useMatch } from '@reach/router';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { DEFAULT_RADIUS, RADIUS_OPTIONS } from '@xing-com/crate-jobs-constants';
import type { JobRecentSearchFragment } from '@xing-com/crate-jobs-graphql';
import { useRecentSearches } from '@xing-com/crate-jobs-hooks';
import type { LocationSuggestion } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useFeatureSwitch } from '@xing-com/hub';
import {
  IconArrowDown,
  IconArrowRight,
  IconArrowUp,
  IconLocationPin,
  IconSearch,
  IconTick,
} from '@xing-com/icons';
import { mediaSmallOrTiny } from '@xing-com/layout-tokens';
import { usePopOver } from '@xing-com/pop-over';
import { LightMode } from '@xing-com/theme-mode-switcher';

import { useJobRoleAutocompletion, useLocationAutocompletion } from './hooks';
import {
  keywordRenderer,
  locationRenderer,
  recentSearchRenderer,
} from './renderers';
import * as S from './search-box.styles';
import { Suggestions, type SuggestionValues } from './suggestions';

type DefaultAutocompleteContent =
  | SuggestionValues
  | JobRecentSearchFragment
  | null;

const countries = ['de', 'at', 'ch'];
const locationSuggestionToString = (item: LocationSuggestion): string =>
  item.name;
const defaultContentToString = (item: DefaultAutocompleteContent): string => {
  if (typeof item === 'string' || item === null) {
    return '';
  }

  return item.searchQuery.guid || '';
};
const defaultContentRenderer = (
  item: DefaultAutocompleteContent
): React.ReactNode => {
  if (item === null) {
    return null;
  }

  if (typeof item === 'string') {
    // Render custom options
    return <Suggestions suggestion={item} />;
  }

  return recentSearchRenderer(item);
};

type Props = {
  keywordsInput: string;
  locationInput: string;
  radiusInput?: number;
  isLocationDisabled?: boolean;
  condensedOnMobile?: boolean;
  locationInputRef?: React.RefObject<HTMLInputElement | undefined>;
  withRecentSearches?: boolean;
  withFilters?: boolean;
  landingFilters?: React.ReactNode;
  onChangeKeywords: (value: string) => void;
  onSelectKeywords: (value: string) => void;
  onChangeLocation: (value: string) => void;
  onSelectLocation: (item: LocationSuggestion) => void;
  onSelectRadius: (value: number) => void;
  onSubmit: () => void;
};

export const SearchBox: React.FC<Props> = ({
  keywordsInput,
  locationInput,
  radiusInput,
  isLocationDisabled = false,
  condensedOnMobile = false,
  locationInputRef,
  withRecentSearches,
  withFilters,
  landingFilters,
  onChangeKeywords,
  onSelectKeywords,
  onChangeLocation,
  onSelectLocation,
  onSelectRadius,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const popOver = usePopOver();
  const { jobRoleSuggestions, fetchJobRoleSuggestions } =
    useJobRoleAutocompletion();
  const { locationSuggestions, fetchLocationSuggestions } =
    useLocationAutocompletion({ countries });
  const { recentSearches } = useRecentSearches({ skip: !withRecentSearches });
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);

  const isFindJobs = useMatch(ROUTES.findJobs);
  const enabledSWIMPFindJobs =
    useFeatureSwitch('enable_swimp_find_jobs') && Boolean(isFindJobs);

  const showDefaultContent = withRecentSearches && keywordsInput.length === 0;
  const defaultContent: DefaultAutocompleteContent[] = [
    enabledSWIMPFindJobs ? ('SWIMP' as const) : null,
    ...(recentSearches ?? []).filter(
      (recentSearch) => !!recentSearch?.searchQuery
    ),
    // Filter to remove possible null values
  ].filter(Boolean);

  const handleOnChangeKeywords = (value: string): void => {
    onChangeKeywords(value);
    if (value) {
      fetchJobRoleSuggestions(value.trim());
    }
  };

  const handleOnChangeLocation = (value: string): void => {
    onChangeLocation(value);
    if (value) {
      fetchLocationSuggestions(value.trim());
    }
  };

  const onClickRadiusButton = (): void => {
    popOver.togglePopOver();
  };

  const onClickRadiusOption = (radiusOption: number) => (): void => {
    onSelectRadius(radiusOption);
    popOver.handleHide();
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit();

    if (isSmallScreen && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  return (
    <LightMode>
      <S.Form
        onSubmit={handleOnSubmit}
        $condensedOnMobile={condensedOnMobile}
        // FIXME: action="." is needed to show the search button on the iOS keyboards: https://stackoverflow.com/a/26287843}
        action="."
      >
        {/* @ts-expect-error FIXME: SC6 */}
        <S.KeywordsAutoComplete
          type="search"
          variant="inputBar"
          textFieldVariant="plain"
          size="xlarge"
          value={keywordsInput}
          placeholder={formatMessage({
            id: 'JOBS_FIND_JOBS_SEARCH_PLACEHOLDER',
          })}
          suggestions={showDefaultContent ? defaultContent : jobRoleSuggestions}
          onChange={handleOnChangeKeywords}
          onSelect={showDefaultContent ? undefined : onSelectKeywords}
          data-testid="search-keywords-autocomplete"
          id="keywords"
          icon={IconSearch}
          itemRenderer={
            showDefaultContent
              ? defaultContentRenderer
              : keywordRenderer(keywordsInput)
          }
          suggestionToString={
            showDefaultContent ? defaultContentToString : undefined
          }
          $condensedOnMobile={condensedOnMobile}
        />
        <S.LocationFieldsWrapper>
          {/* @ts-expect-error FIXME: SC6 */}
          <S.LocationAutoComplete
            type="search"
            variant="inputBar"
            textFieldVariant="plain"
            size="xlarge"
            value={locationInput}
            placeholder={formatMessage({
              id: 'JOBS_FIND_JOBS_SEARCH_LOCATION_PLACEHOLDER',
            })}
            suggestions={locationSuggestions}
            onChange={handleOnChangeLocation}
            onSelect={onSelectLocation}
            itemRenderer={locationRenderer(locationInput)}
            suggestionToString={locationSuggestionToString}
            data-testid="search-location-autocomplete"
            id="location"
            icon={IconLocationPin}
            disabled={isLocationDisabled}
            innerRef={locationInputRef}
            $withFilters={withFilters}
            $condensedOnMobile={condensedOnMobile}
          />

          <S.RadiusWrapper>
            <S.RadiusButton
              type="button"
              fontWeight="regular"
              ellipsis
              iconAfter
              icon={popOver.show ? IconArrowUp : IconArrowDown}
              onClick={onClickRadiusButton}
              innerRef={popOver.triggerRef}
              data-testid="search-radius-dropdown"
              // @ts-expect-error FIXME: SC6
              $isFilled={radiusInput}
              $condensedOnMobile={condensedOnMobile}
              disabled={isLocationDisabled}
            >
              {radiusInput === undefined ? (
                <FormattedMessage id="JOBS_FIND_JOBS_SEARCH_RADIUS_PLACEHOLDER" />
              ) : radiusInput === DEFAULT_RADIUS ? (
                <FormattedMessage id="JOBS_SEARCH_LOCATION_NO_RADIUS" />
              ) : (
                `${radiusInput} km`
              )}
            </S.RadiusButton>

            <S.RadiusMenu
              isInFlow
              onOutsideClick={popOver.handleHide}
              triggerRef={popOver.triggerRef}
              show={popOver.show}
              showCloseButton={false}
            >
              {RADIUS_OPTIONS.map((radiusOption) => (
                <S.SingleSelect
                  type="button"
                  key={radiusOption}
                  onClick={onClickRadiusOption(radiusOption)}
                  {...(radiusOption === radiusInput && {
                    selected: true,
                    icon: IconTick,
                    iconAfter: true,
                  })}
                >
                  {radiusOption === DEFAULT_RADIUS ? (
                    <FormattedMessage id="JOBS_SEARCH_LOCATION_NO_RADIUS" />
                  ) : (
                    `${radiusOption} km`
                  )}
                </S.SingleSelect>
              ))}
            </S.RadiusMenu>
          </S.RadiusWrapper>
        </S.LocationFieldsWrapper>

        <S.SubmitButtonSmall
          id="search-button-small"
          variant="tertiary"
          type="submit"
          data-testid="search-button-small"
          icon={IconArrowRight}
          $withFilters={withFilters}
          $condensedOnMobile={condensedOnMobile}
          aria-label={formatMessage({ id: 'JOBS_FIND_JOBS_SEARCH_BUTTON' })}
        />

        <S.SubmitButtonBig
          id="search-button"
          variant="tertiary"
          type="submit"
          data-testid="search-button"
          $withFilters={withFilters}
          $condensedOnMobile={condensedOnMobile}
        >
          <FormattedMessage id="JOBS_FIND_JOBS_SEARCH_BUTTON" />
        </S.SubmitButtonBig>
      </S.Form>

      {withFilters ? landingFilters : null}
    </LightMode>
  );
};
