import styled from 'styled-components';

import { mediaConfined, mediaWideNavless } from '@xing-com/layout-tokens';
import { spaceXS, spaceL } from '@xing-com/tokens';

export const StyledRow = styled.div`
  display: grid;
  gap: ${spaceL};
  grid-template-columns: repeat(1, 1fr);

  @media ${mediaConfined} {
    gap: calc(${spaceXS} + ${spaceL});
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${mediaWideNavless} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
