import { useIntl } from 'react-intl';

import { BodyCopy } from '@xing-com/typography';

import {
  StyledHeader,
  StyledTag,
  StyledItemsContainer,
} from './disabled-preferences.styles';

type Preference = {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  copy: string;
  id: string;
};

export type DisabledPreferencesProps = {
  header?: React.ReactNode;
  items: Preference[];
  more?: number;
  onClick?: (target: string) => void;
};

const Items: React.FC<
  Pick<DisabledPreferencesProps, 'items' | 'onClick' | 'more'>
> = ({ items, onClick, more = 0 }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {items.map((item) => (
        <StyledTag
          key={item.copy}
          onClick={() => onClick && onClick(item.id)}
          leadingIcon={item.icon || undefined}
        >
          <BodyCopy lineHeight="dense" noMargin>
            {item.copy}
          </BodyCopy>
        </StyledTag>
      ))}

      <StyledTag onClick={() => onClick && onClick('show_more')}>
        <BodyCopy lineHeight="dense" noMargin>
          {more > 0
            ? `+${more}`
            : formatMessage({ id: 'LOSP_PREFERENCES_EXPERIMENT_SEE_MORE' })}
        </BodyCopy>
      </StyledTag>
    </>
  );
};

export const DisabledPreferences: React.FC<DisabledPreferencesProps> = ({
  header,
  items,
  more,
  onClick,
}) => {
  return (
    <>
      {header ? (
        <>
          {/* @ts-expect-error FIXME: SC6 */}
          <StyledHeader noMargin small>
            {header}
          </StyledHeader>
          <StyledItemsContainer>
            <Items items={items} onClick={onClick} more={more} />
          </StyledItemsContainer>
        </>
      ) : (
        <Items items={items} onClick={onClick} more={more} />
      )}
    </>
  );
};
