import styled from 'styled-components';

import { SkeletonRectangle } from '@xing-com/skeleton';
import { cornerRadiusL, scale320, spaceXL } from '@xing-com/tokens';

const StyledSkeletonRectangle = styled(SkeletonRectangle)`
  margin: ${spaceXL} 0;
`;

export const PreferencesSkeleton: React.FC = () => {
  return (
    <StyledSkeletonRectangle
      height={scale320}
      width="100%"
      cornerRadius={cornerRadiusL}
    />
  );
};
