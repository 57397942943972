import type * as React from 'react';
import styled from 'styled-components';

import { SMALL_BREAK } from '@xing-com/layout-primitives';

const GridContentStyles = styled.div`
  @media (max-width: ${SMALL_BREAK}px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const GridContent: React.FC<
  React.PropsWithChildren<{
    type: string;
  }>
> = ({ children, type }) => {
  return (
    <>
      {type === 'mobile-full-span' ? (
        <GridContentStyles>{children}</GridContentStyles>
      ) : (
        { children }
      )}
    </>
  );
};
