import type React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@xing-com/button';

import {
  StyledHero,
  StyledSection,
  StyledSubline,
  LowerSectionWrapper,
  StyledHeadline,
} from './inspirational-cards-section.styles';
import { InspirationalCardsList } from './inspirational-cards/inspirational-cards-list';

const trackingParams =
  '?sc_o=losp_sign_up_button_click&sc_o_PropActionOrigin=losp_sign_up_button_click_cta_3&signup_channel=minireg_losp';

export const InspirationalCardsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  const signUpUrl = '/start/signup';

  const handleAnchorClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    window.location.assign(`${signUpUrl}${trackingParams}`);
  };

  return (
    <StyledSection>
      <StyledHero forwardedAs="h2" noMargin size="small">
        {formatMessage({ id: 'CARDS_SECTION_TITLE' })}
      </StyledHero>
      <StyledSubline forwardedAs="p" size="medium" sizeConfined="large">
        {formatMessage({ id: 'CARDS_SECTION_DESCRIPTION' })}
      </StyledSubline>
      <InspirationalCardsList />
      <LowerSectionWrapper>
        <StyledHeadline size="xxlarge">
          {formatMessage({ id: 'CARDS_SECTION_REGISTRATION_TEXT' })}
        </StyledHeadline>
        <a onClick={handleAnchorClick} href={signUpUrl} tabIndex={-1}>
          <Button variant="primary" size="large">
            {formatMessage({ id: 'CARDS_SECTION_REGISTRATION_BUTTON_TEXT' })}
          </Button>
        </a>
      </LowerSectionWrapper>
    </StyledSection>
  );
};
