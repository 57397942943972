/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useTimedCallback } from './use-timed-callback';

const TYPE_SPEED_MS = 38;
const TYPE_PAUSE_MS = 2500;
const DELETE_SPEED_MS = 0;
const DELETE_PAUSE_MS = 500;

type UseAnimatedPlaceholderOptions = {
  disable?: boolean;
};

export const useAnimatedPlaceholder = (
  ref: React.RefObject<HTMLTextAreaElement>,
  { disable }: UseAnimatedPlaceholderOptions = {}
) => {
  const { formatMessage } = useIntl();
  const indexRef = useRef<number | null>(null);
  const charIndexRef = useRef<number | null>(null);
  const { run, cancel } = useTimedCallback({ speed: TYPE_SPEED_MS });

  const placeholders = [
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_1' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_2' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_3' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_4' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_5' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_6' }),
    formatMessage({ id: 'JOBS_SEARCH_AI_PLACEHOLDER_7' }),
  ];

  const getNextOptions = (delay?: number) => ({
    delay,
    cancel: Boolean(ref.current?.value),
  });

  const type = () => {
    // If there is a value, we cut the setTimeout chain
    if (ref.current?.value || disable) return;

    indexRef.current ??= 0;
    charIndexRef.current ??= 0;

    const placeholder = placeholders[indexRef.current];
    const newPlaceholder = placeholder.slice(0, charIndexRef.current);
    ref.current?.setAttribute('placeholder', newPlaceholder);

    if (charIndexRef.current < placeholder.length) {
      ++charIndexRef.current;
      run(type);
    } else {
      charIndexRef.current = 0;
      run(remove(placeholder), getNextOptions(TYPE_PAUSE_MS));
    }
  };

  const remove = (placeholder: string) => () => {
    // If there is a value, we cut the setTimeout chain
    if (ref.current?.value) return;

    indexRef.current ??= 0;
    charIndexRef.current ??= 0;

    const newPlaceholder = placeholder.slice(0, -1);
    ref.current?.setAttribute('placeholder', newPlaceholder);

    if (newPlaceholder.length > 0) {
      run(remove(newPlaceholder), getNextOptions(DELETE_SPEED_MS));
    } else {
      indexRef.current = (indexRef.current + 1) % placeholders.length;
      run(type, getNextOptions(DELETE_PAUSE_MS));
    }
  };

  useEffect(() => {
    const shouldDisable =
      disable && (indexRef.current !== null || charIndexRef.current !== null);
    if (shouldDisable) {
      cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);

  return type;
};
