import { useLazyQuery, makeVar } from '@apollo/client';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { FabiJobSearchQueryGenerationUseCase } from '@xing-com/crate-common-graphql-types';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import {
  AI_FORCE_SWIMP_ANIMATION_PARAM,
  AI_SEARCH_AUTOFOCUS_PARAM,
  AI_SWIMP_TRACKING_TOKEN_PARAM,
} from '@xing-com/crate-jobs-constants';
import {
  IconSWIMP,
  SearchSuggestion,
} from '@xing-com/crate-jobs-domain-search-search-suggestions';
import { JobSearchQueryGenerationDocument } from '@xing-com/crate-jobs-graphql';
import { IconCross } from '@xing-com/icons';

import { ErrorBanner } from '../error-banner';
import { SearchBarInput } from '../input';
import { useLoadingPlaceholder } from '../use-loading-placeholder';
import { ANIMATION_CONTAINER_ID } from './constants';
import * as S from './suggestions.styles';
import { VARIABLES } from './suggestions.styles';
import { useAnimatedValue } from './use-animated-suggestion';
import { useSuggestionsTracking } from './use-suggestions-tracking';

const WRAPPER_ID = `${ANIMATION_CONTAINER_ID}-wrapper`;
const OPTIONS_IDS = {
  SWIMP: 'suggestion-search-with-profile',
};

type Props = Parameters<typeof SearchBarInput>[0];

/**
 * We need this cached variable in case the user clicks find jobs before the
 * query has finished the animation. As fixed in:
 * {@link https://github.com/xing-com/crate/pull/13306|#13306}, we are adding word
 * by word to the `textarea`, and therefore updating the `useSearchBar` word by
 * word. This means that when `onSubmit` is called, the value of the `textarea`
 * is not updated yet with the entire query. In order to solve it, we need to
 * let the onSubmit know which is the entire query. Therefore, if the
 * `cachedSuggestionQuery` is not `null`, it should be the actual query sent to
 * the server.
 */
export const cachedSuggestionQuery = makeVar<string | null>(null);

/**
 * Trackers used to know if the user has done certain actions
 */
type Trackers = {
  /**
   * Whether the animation has been executed once
   */
  animation: boolean;
  /**
   * Whether the user has focused the input once
   */
  focus: boolean;
};
/**
 * The `InputSuggestions` component should only be used after the we've checked
 * if the user has the feature flag enabled. Furthermore, LO users will never
 * have such FF enabled, so we can safely assume that the user is LI.
 *
 * In case we are getting the init_animation=true query param, on loading the
 * page the animation will be triggered
 */
export const InputSuggestions: React.FC<Props> = (props) => {
  const [showMenu, setShowMenu] = useState(Boolean(props.defaultValue));
  const [notEnoughData, setNotEnoughData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { getSearchParameter, setSearchParameter } = useSearchParameter();
  const initAnimationOnLoad =
    getSearchParameter(AI_FORCE_SWIMP_ANIMATION_PARAM) === 'true';
  const autofocusOnLoad =
    getSearchParameter(AI_SEARCH_AUTOFOCUS_PARAM) === 'true';

  const trackers = useRef<Trackers>({ animation: false, focus: false });
  const queryRef = useRef('');
  const errorBannerRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const searchBarRef = useRef<HTMLTextAreaElement | null>(null);

  const trackSuggestionClick = useSuggestionsTracking();
  const runLoadingAnimation = useLoadingPlaceholder(searchBarRef);
  const { animate, cancel, isAnimating } = useAnimatedValue({
    onIterate: (_, index, split) => {
      props.onChangeKeywords(split.slice(0, index).join(' '));
      const container = document.getElementById(ANIMATION_CONTAINER_ID);
      if (!searchBarRef.current || !container) {
        return;
      }
      const containerHeight = container.getBoundingClientRect().height;
      const { minHeight } = searchBarRef.current.style;
      const minHeightValue = Number(minHeight?.match(/(\d+)/)?.[0] ?? 0);
      if (minHeightValue < containerHeight) {
        searchBarRef.current.style.minHeight = `${containerHeight}px`;
      }
    },
    onAnimationEnd: () => {
      if (queryRef.current) {
        props.onChangeKeywords(queryRef.current);
      }
      if (searchBarRef.current) {
        searchBarRef.current.style.minHeight = '';
        // Ensure the scroll is at the bottom of the texatea, otherwise if the
        // input is too long, the element will not be scrolled to the bottom
        // and the cursor may not be visible to the user
        searchBarRef.current.scrollTo(0, searchBarRef.current.scrollHeight);
        // Force the focus to the textarea when the animation finishes
        searchBarRef.current.focus();
      }

      // We can safely reset the cached query
      cachedSuggestionQuery(null);
    },
  });
  const [getSuggestions, { loading: areSuggestionsLoading }] = useLazyQuery(
    JobSearchQueryGenerationDocument,
    {
      variables: {
        input: {
          consumer: 'loggedin.web.jobs.conv_search_landing.center',
          // We'll have to handle other use cases in the future
          useCase: FabiJobSearchQueryGenerationUseCase.SearchWithProfile,
          userQuery: '',
        },
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setHasError(true);
      },
    }
  );

  const handleOnFocus = (): void => {
    if (!props.defaultValue) {
      setShowMenu(true);
      trackers.current.focus = true;
    }
  };

  const execAnimation = async (): Promise<void> => {
    runLoadingAnimation();

    if (searchBarRef.current) {
      searchBarRef.current.blur();
    }

    const { data } = await getSuggestions();
    const result = data?.fabiJobSearchQueryGeneration;
    if (result?.__typename !== 'FabiJobSearchQueryGenerationResult') {
      cachedSuggestionQuery(null);
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }

      return;
    }

    if (!result.enoughProfileData) {
      setNotEnoughData(true);
      setShowMenu(true);
      cachedSuggestionQuery(null);
      return;
    }

    const query = result.queries[0]?.query;
    if (!query) {
      cachedSuggestionQuery(null);
      return;
    }

    cachedSuggestionQuery(query);
    animate(query);
    setSearchParameter(
      AI_SWIMP_TRACKING_TOKEN_PARAM,
      result.requestTrackingToken
    );
    queryRef.current = query;
    if (!trackers.current.animation) {
      trackers.current.animation = true;
    }
  };

  const handleOnSearchWithProfile = (): void => {
    trackSuggestionClick();
    execAnimation();
  };

  const handleOnClickContainer = (): void => {
    if (!isAnimating) {
      return;
    }

    cancel();
  };

  const handleOnCloseNotEnoughDataBanner = (e: MouseEvent): void => {
    e.preventDefault();
    setNotEnoughData(false);
  };

  const handleOnCloseErrorBanner = (): void => {
    setHasError(false);
  };

  const handleOnChangeKeywords = (value: string): void => {
    // If the user manually changes the keywords, need to clear the cached query to avoid issues
    if (cachedSuggestionQuery()) {
      cachedSuggestionQuery(null);
    }

    props.onChangeKeywords(value);
  };

  useLayoutEffect(() => {
    const handleOnMouseDown = (e: MouseEvent): void => {
      // Check if the user has clicked away of the container
      const container = document.getElementById(WRAPPER_ID);
      if (!container || !e.target || !(e.target instanceof HTMLElement)) {
        return;
      }

      if (!container.contains(e.target) && !e.defaultPrevented) {
        // User has clicked away from the input, so we can close the suggestions
        // and unfocus the input - which is triggered automatically
        setShowMenu(false);
      } else {
        // Check if it has clicked on a suggestion
        if (e.target.id === OPTIONS_IDS.SWIMP) {
          handleOnSearchWithProfile();
          e.preventDefault();
          e.stopPropagation();
          setShowMenu(false);
        }
      }
    };

    // Using useLayoutEffect to ensure that document is defined
    // Nothing to be done in the server
    document.addEventListener('mousedown', handleOnMouseDown, true);

    if (initAnimationOnLoad) {
      execAnimation();
    }

    return () => {
      document.removeEventListener('mousedown', handleOnMouseDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.defaultValue && !showMenu && trackers.current.focus) {
      setShowMenu(true);
    } else if (props.defaultValue && showMenu) {
      setShowMenu(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue]);

  useEffect(() => {
    if (!notEnoughData || !errorBannerRef.current) {
      return;
    }

    const container = document.getElementById(ANIMATION_CONTAINER_ID);
    if (!container) {
      return;
    }

    // Update the padding of the textarea from the container
    const { height } = errorBannerRef.current.getBoundingClientRect();
    container.style.setProperty(VARIABLES.ERROR_BANNER_HEIGHT, `${height}px`);
  }, [notEnoughData, errorBannerRef]);

  return (
    <>
      <S.SuggestionsContainer
        id={WRAPPER_ID}
        onClick={handleOnClickContainer}
        $withErrorBanner={notEnoughData}
      >
        <S.AnimationContainer id={ANIMATION_CONTAINER_ID} />
        <SearchBarInput
          {...props}
          onChangeKeywords={handleOnChangeKeywords}
          ref={searchBarRef}
          onFocus={handleOnFocus}
          autofocus={trackers.current.animation}
          autofocusOnLoad={autofocusOnLoad}
          disableDefaultAnimation={areSuggestionsLoading}
          isAnimating={isAnimating}
          nested
        />
        {notEnoughData ? (
          <S.ErrorBannerContainer>
            <S.ErrorBanner ref={errorBannerRef}>
              {formatMessage({ id: 'JOBS_SEARCH_AI_SUGGESTION_ERROR' })}
            </S.ErrorBanner>
            <S.ErrorClose
              icon={IconCross}
              // @ts-expect-error FIXME: SC6
              onClick={handleOnCloseNotEnoughDataBanner}
              size="medium"
              type="button"
            />
          </S.ErrorBannerContainer>
        ) : null}
        {showMenu ? (
          <S.FloatingCard>
            <SearchSuggestion
              id={OPTIONS_IDS.SWIMP}
              Icon={IconSWIMP}
              title={formatMessage({ id: 'JOBS_SEARCH_AI_SWIMP_SUGGESTION' })}
              subtitle={formatMessage({
                id: 'JOBS_SEARCH_AI_SWIMP_SUGGESTION_SUBTITLE',
              })}
            />
          </S.FloatingCard>
        ) : null}
      </S.SuggestionsContainer>

      <ErrorBanner show={hasError} onClose={handleOnCloseErrorBanner} />
    </>
  );
};
