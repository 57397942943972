import { Marker } from '@xing-com/marker';
import { ProfileImage } from '@xing-com/profile-image';
import { xdlColorMarkerHighlightText } from '@xing-com/tokens';

import * as Styled from './entity-card.styled';
import type { EntityCardTypes } from './entity-card.types';
import { getStyleConfig } from './variations-config';

const defaultLayout = 'A';
const defaultSize = 'large';

export const EntityCard: React.FC<EntityCardTypes> = ({
  className,
  layout = defaultLayout,
  size = defaultSize,
  width,
  profileImage,
  markers,
  headline,
  copy1,
  copy2,
  button,
  description,
  descriptionIcon: Icon,
  companyLogo,
  to,
  onClick,
  ...rest
}) => {
  const styleConfig = getStyleConfig(
    layout,
    size,
    description?.length ? 'hasDescription' : undefined
  );

  return (
    <Styled.Card
      className={className}
      $width={width}
      to={to}
      aria-label={headline}
      $styleConfig={styleConfig}
      variant="elevated"
      // @ts-expect-error FIXME: SC6
      $hasButton={button?.children}
      onClick={onClick}
      {...rest}
    >
      <Styled.MainArea $styleConfig={styleConfig}>
        <Styled.ProfileLink
          to={to}
          aria-label={headline}
          $styleConfig={styleConfig}
        >
          <ProfileImage size={styleConfig.profileImageSize} {...profileImage} />
        </Styled.ProfileLink>
        <Styled.TitleArea $styleConfig={styleConfig}>
          <Styled.MarkersContainer
            $styleConfig={styleConfig}
            $hidden={!markers?.length && styleConfig.markersCollapseWhenEmpty}
          >
            {markers?.map((marker, index) => (
              <Marker key={index} {...marker} />
            ))}
          </Styled.MarkersContainer>
          <Styled.Title
            size={layout === 'A' ? 'xlarge' : 'large'}
            $styleConfig={styleConfig}
          >
            {headline}
          </Styled.Title>
          <Styled.SubtitleContainer $styleConfig={styleConfig}>
            {styleConfig.showCompanyLogo && companyLogo && (
              <Styled.CompanyLogo src={companyLogo} size="small" />
            )}
            <span>
              <Styled.Subtitle size="medium" lineHeight="dense">
                {copy1}
              </Styled.Subtitle>
              <Styled.Subtitle size="medium" lineHeight="dense">
                {copy2}
              </Styled.Subtitle>
            </span>
          </Styled.SubtitleContainer>
        </Styled.TitleArea>
      </Styled.MainArea>
      {styleConfig.showDescription && (
        <Styled.DescriptionContainer $styleConfig={styleConfig}>
          {Icon && (
            <Styled.DescriptionIconContainer $styleConfig={styleConfig}>
              <Icon
                width={24}
                height={24}
                color={xdlColorMarkerHighlightText}
              />
            </Styled.DescriptionIconContainer>
          )}
          <Styled.Description
            lineHeight="dense"
            size={styleConfig.descriptionSize}
            noMargin
            $styleConfig={styleConfig}
          >
            {description}
          </Styled.Description>
        </Styled.DescriptionContainer>
      )}
      {styleConfig.showButton && button?.children && (
        <Styled.Button size="medium" variant="tertiary" {...button} />
      )}
    </Styled.Card>
  );
};
