import shuffle from 'lodash/shuffle';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';

import { line1, line2 } from './data';
import { StyledKeywords, StyledTag, StyledLink } from './keywords.styled';

export const Keywords: React.FC = () => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [keywordsPrepared, setKeywordsPrepared] = useState(false);
  const [keywordsData, setKeywordsData] = useState({
    line1,
    line2,
  });
  const keywordsRef = useRef<HTMLDivElement>(null);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });
  const isTouchDevice = useMatchMedia('(pointer: coarse)');
  const handleDragStart = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!keywordsRef.current) return;
    const keywords = keywordsRef.current;
    const startX = event.pageX - keywords.offsetLeft;
    const scrollLeft = keywords.scrollLeft;

    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    document.body.style.cursor = 'grabbing';
  };
  const handleDragEnd = (): void => {
    setIsMouseDown(false);
    if (!keywordsRef.current) return;
    document.body.style.cursor = 'default';
    setIsDragging(false);
  };
  const handleDrag = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!isMouseDown || !keywordsRef.current) return;
    event.preventDefault();

    const keywords = keywordsRef.current;
    const currentX = event.pageX - keywords.offsetLeft;
    const distanceX = (currentX - mouseCoords.current.startX) * 1.5;

    keywords.scrollLeft = mouseCoords.current.scrollLeft - distanceX;
    distanceX > 2 && setIsDragging(true);
  };

  useEffect(() => {
    const keywords = keywordsRef.current;

    // Scroll to the middle of the keywords and shuffle them
    if (keywords) {
      const scrollWidthHalf =
        keywords.scrollWidth / 2 - keywords.clientWidth / 2;

      keywords.scrollLeft = scrollWidthHalf;

      setKeywordsData({
        line1: shuffle(line1),
        line2: shuffle(line2),
      });
      setKeywordsPrepared(true);
    }
  }, [keywordsRef]);

  return (
    <StyledKeywords
      ref={keywordsRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      onMouseLeave={handleDragEnd}
      $keywordsPrepared={keywordsPrepared}
      $isTouchDevice={isTouchDevice}
      className="is-light-theme"
    >
      <div>
        {keywordsData.line1.map(({ id, href }) => (
          <StyledLink
            draggable="false"
            href={href}
            $isDragging={isDragging}
            key={href}
          >
            <StyledTag hideBorder selected>
              <FormattedMessage id={id} />
            </StyledTag>
          </StyledLink>
        ))}
      </div>
      <div>
        {keywordsData.line2.map(({ id, href }) => (
          <StyledLink
            draggable="false"
            href={href}
            $isDragging={isDragging}
            key={href}
          >
            <StyledTag hideBorder selected>
              <FormattedMessage id={id} />
            </StyledTag>
          </StyledLink>
        ))}
      </div>
    </StyledKeywords>
  );
};
