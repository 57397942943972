/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetJobRoleSuggestionsQueryVariables = Types.Exact<{
  consumer: Types.Scalars['String']['input'];
  text: Types.Scalars['String']['input'];
  limit: Types.Scalars['Int']['input'];
}>;

export type GetJobRoleSuggestionsQuery = {
  __typename?: 'Query';
  autocompletionProfileJobRole?: {
    __typename?: 'AutocompletionProfileJobRoleSuggestions';
    collection: Array<{
      __typename?: 'AutocompletionProfileJobRoleSuggestion';
      suggestion?: string | null;
    }>;
  } | null;
};

export const GetJobRoleSuggestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobRoleSuggestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'consumer' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autocompletionProfileJobRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'consumer' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'consumer' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'text' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'AutocompletionProfileJobRoleSuggestion',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutocompletionProfileJobRoleSuggestion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AutocompletionProfileJobRoleSuggestion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'suggestion' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetJobRoleSuggestionsQuery,
  GetJobRoleSuggestionsQueryVariables
>;
