import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { mediaSmallOrTiny, mediaWide } from '@xing-com/layout-tokens';

type MediaBreakpoints = {
  isMediaSmallOrTiny: boolean;
  isMediaWide: boolean;
};

// Important: Do not use this hook in SSR pages
export const useBreakpoints = (): MediaBreakpoints => {
  const isMediaSmallOrTiny = useMatchMedia(mediaSmallOrTiny);
  const isMediaWide = useMatchMedia(mediaWide);

  return { isMediaSmallOrTiny, isMediaWide };
};
