import { useIntl } from 'react-intl';
import styled from 'styled-components';

import type { JobRecentSearchFragment } from '@xing-com/crate-jobs-graphql';
import { useJoinFilters } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { IconClock } from '@xing-com/icons';
import { Link as BaseLink } from '@xing-com/link';
import { spaceXL, xdlColorText } from '@xing-com/tokens';
import { BodyCopy, Meta } from '@xing-com/typography';

const Link = styled(BaseLink)`
  display: flex;
  gap: 10px;
  color: ${xdlColorText};

  &:hover {
    text-decoration: none;
  }
`;

const TextWrapper = styled.div`
  overflow: hidden;
`;

const Icon = styled(IconClock)`
  flex-shrink: 0;
`;

type Props = {
  recentSearch: JobRecentSearchFragment;
};

const RecentSearch: React.FC<Props> = ({ recentSearch }) => {
  const { formatMessage } = useIntl();

  const searchQuery = recentSearch.searchQuery;
  const body = searchQuery?.body;
  const joinedFilters = useJoinFilters(body?.filterCollection ?? [], {
    location: body?.location?.city?.name || body?.location?.text,
    radius: body?.location?.radius,
  });

  const title =
    body?.keywords ?? formatMessage({ id: 'JOBS_RECENT_SEARCH_EMPTY' });
  const fullText = [title, joinedFilters].join(' + ');

  const href = `${ROUTES.search}/${searchQuery?.guid}?sc_o=jobs_recent_searches`;

  return (
    <Link to={href} title={fullText}>
      <Icon width={spaceXL} height={spaceXL} />
      <TextWrapper>
        <BodyCopy ellipsis noMargin>
          {title}
        </BodyCopy>
        {joinedFilters ? (
          <Meta size="xsmall" ellipsis noMargin>
            {joinedFilters}
          </Meta>
        ) : null}
      </TextWrapper>
    </Link>
  );
};

export const recentSearchRenderer = (
  item: JobRecentSearchFragment
): React.ReactNode => <RecentSearch recentSearch={item} />;
