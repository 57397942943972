import { preferencesData } from './data';

export function getPreferenceKey(
  preference: string,
  value?: string
): string | undefined {
  const possibleValues = preferencesData.options.find(
    (option) => option.preference === preference
  )?.possibleValues;
  if (!possibleValues || !value) {
    return undefined;
  }
  return Object.keys(possibleValues).find(
    (key) => parseInt(possibleValues?.[key]) === parseInt(value)
  );
}
