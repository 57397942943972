import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import { GetJobRoleSuggestionsDocument } from '@xing-com/crate-jobs-graphql';

type UseJobRoleAutocompletion = {
  jobRoleSuggestions: string[];
  fetchJobRoleSuggestions: (query: string) => void;
};

const DEFAULT_LIMIT = 5;
const DEFAULT_DEBOUNCE_TIME_MS = 250;
const CONSUMER = 'react.keywords.search.jobs.form';

export const useJobRoleAutocompletion = ({
  limit = DEFAULT_LIMIT,
  debounceTime = DEFAULT_DEBOUNCE_TIME_MS,
} = {}): UseJobRoleAutocompletion => {
  const [jobRoleSuggestions, setJobRoleSuggestions] = useState<string[]>([]);
  const [getSuggestions, { data, loading }] = useLazyQuery(
    GetJobRoleSuggestionsDocument
  );

  const collection = data?.autocompletionProfileJobRole?.collection;

  const fetchJobRoleSuggestions = useMemo(
    () =>
      debounce((query: string): void => {
        getSuggestions({
          variables: {
            consumer: CONSUMER,
            text: query,
            limit,
          },
        });
      }, debounceTime),
    [debounceTime, limit, getSuggestions]
  );

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!collection || collection.length === 0) {
      setJobRoleSuggestions([]);
      return;
    }

    const suggestions = collection.reduce(
      (current: string[], { suggestion }) =>
        suggestion ? [...current, suggestion] : current,
      []
    );

    setJobRoleSuggestions(suggestions);
  }, [loading, collection, limit]);

  return { jobRoleSuggestions, fetchJobRoleSuggestions };
};
