import { useCombobox } from 'downshift';

import { FormField, InputBar } from '@xing-com/text-field';
import type { FormFieldProps, InputBarProps } from '@xing-com/text-field';

import * as Styled from './auto-complete.styles';

type OmitTextFieldProps<T> = Omit<T, 'multiline' | 'onChange' | 'variant'>;

type FormFieldVariant = {
  variant?: 'formField';
  textFieldVariant?: FormFieldProps['variant'];
} & OmitTextFieldProps<FormFieldProps>;

type InputBarVariant = {
  variant: 'inputBar';
  textFieldVariant?: InputBarProps['variant'];
} & OmitTextFieldProps<InputBarProps>;

type CommonProps<T> = {
  id?: string;
  suggestions?: T[];
  inputClassName?: string;
  ariaLabel?: string;
  onChange?: (inputValue: string) => void;
  onSelect?: (selectedItem: T) => void;
  itemRenderer?: (item: T) => JSX.Element | string;
} & (T extends string
  ? {
      suggestionToString?: (item: T | null) => string;
    }
  : {
      suggestionToString: (item: T | null) => string;
    });

export type AutoCompleteProps<T = string> = CommonProps<T> &
  (FormFieldVariant | InputBarVariant);

export const AutoComplete = <T,>({
  variant,
  textFieldVariant,
  id,
  suggestions,
  value,
  className,
  inputClassName,
  ariaLabel,
  onChange,
  onSelect,
  suggestionToString,
  innerRef,
  itemRenderer,
  ...inputRestProps
}: AutoCompleteProps<T>): React.ReactElement => {
  const {
    isOpen,
    inputValue,
    highlightedIndex,
    getInputProps,
    getMenuProps,
    getItemProps,
    getLabelProps,
  } = useCombobox<T>({
    id,
    items: suggestions ?? [],
    inputValue: value,
    ...(suggestionToString && { itemToString: suggestionToString }),
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          // We need to remove the selected item from the downshift internal state,
          // so we can select the same item consecutively (onSelectedItemChange won't be called otherwise)
          return { ...changes, selectedItem: undefined };
        default:
          return changes;
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSelect) onSelect(selectedItem);
    },
  });

  const defaultItemRenderer = (
    suggestionValue: string
  ): JSX.Element | string => {
    const position = inputValue
      ? suggestionValue.toLowerCase().indexOf(inputValue.toLowerCase())
      : -1;

    if (position >= 0) {
      const left = suggestionValue.substring(0, position);
      const middle = suggestionValue.substring(
        position,
        position + inputValue.length
      );
      const right = suggestionValue.substring(position + inputValue.length);

      return (
        <>
          {left}
          <strong>{middle}</strong>
          {right}
        </>
      );
    }

    return suggestionValue;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME: SC6
  const hasLabel = inputRestProps?.label;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore FIXME: SC6
  const autoCompleteAriaLabel = ariaLabel ?? inputRestProps?.placeholder ?? '';

  const InputComponent = variant === 'inputBar' ? InputBar : FormField;

  return (
    <Styled.AutoComplete className={className}>
      {hasLabel ? null : (
        <label hidden {...getLabelProps()}>
          {autoCompleteAriaLabel}
        </label>
      )}
      <InputComponent
        {...getInputProps({
          refKey: 'innerRef',
          className: inputClassName,
          variant: textFieldVariant,
          // Workaround to fix the cursor jump
          // See: https://github.com/downshift-js/downshift/tree/59d3498faafd3c3b4832a20dab7485502ce67284/src/hooks/useCombobox#inputvalue
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) onChange(event.target.value);
          },
          // Prevent Downshift's default 'Enter' behavior, if no element is highlighted we want to submit the form
          // See: https://github.com/downshift-js/downshift/tree/59d3498faafd3c3b4832a20dab7485502ce67284/src/hooks/useCombobox#customizing-handlers
          onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && highlightedIndex === -1) {
              // @ts-expect-error preventDownshiftDefault is a custom property
              event.nativeEvent.preventDownshiftDefault = true;
            }
          },
          ...inputRestProps,
          ...(hasLabel && { 'aria-labelledby': undefined }),
          ...(innerRef && { ref: innerRef }),
        })}
      />
      <Styled.Menu
        {...getMenuProps({
          ...(hasLabel && { 'aria-labelledby': undefined }),
        })}
      >
        {isOpen &&
          suggestions?.map((item, index) => {
            const suggestionValue = suggestionToString
              ? suggestionToString(item)
              : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                (item as string);

            return (
              <Styled.MenuItem
                key={`${suggestionValue}-${index}`}
                $isHighlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {itemRenderer
                  ? itemRenderer(item)
                  : defaultItemRenderer(suggestionValue)}
              </Styled.MenuItem>
            );
          })}
      </Styled.Menu>
    </Styled.AutoComplete>
  );
};

AutoComplete.displayName = 'AutoComplete';
