import styled from 'styled-components';

import {
  SkeletonBodyCopy,
  SkeletonCard,
  SkeletonHeadline,
} from '@xing-com/skeleton';
import { spaceL, spaceM, spaceXL } from '@xing-com/tokens';

const SkeletonSummaryCard = styled(SkeletonCard)`
  width: 100%;
  padding: ${spaceL} ${spaceXL};
  margin-bottom: ${spaceL};
`;

const StyledSkeletonHeadline = styled(SkeletonHeadline).attrs({
  size: 'large',
  amountRows: 1,
})`
  margin-bottom: ${spaceM};
`;

export const JobSummarySkeleton: React.FC = () => (
  <SkeletonSummaryCard>
    <StyledSkeletonHeadline />
    <SkeletonBodyCopy />
  </SkeletonSummaryCard>
);
