import styled from 'styled-components';

import { mediaConfined, mediaWideNavless } from '@xing-com/layout-tokens';
import { scale050, scale100 } from '@xing-com/tokens';
import { Hero, Headline } from '@xing-com/typography';

export const Title = styled(Hero)`
  @media ${mediaConfined} {
    margin-bottom: ${scale050};
  }
`;

export const Subtitle = styled(Headline)`
  font-weight: 400;

  @media ${mediaConfined} {
    margin-bottom: calc(2 * ${scale100});
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const RegisterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${scale100};

  @media ${mediaWideNavless} {
    flex-direction: row;
    gap: ${scale050};
  }
`;
