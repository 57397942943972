import { useRef, useEffect } from 'react';

type NextCallback = () => void;
type NextOptions = {
  delay?: number;
  cancel?: boolean;
};

type UseTimedCallbackArgs = {
  speed: number;
};
type UseTimedCallbackResult = {
  run: (callback: NextCallback, options?: NextOptions) => void;
  cancel: () => void;
};

export const useTimedCallback = ({
  speed,
}: UseTimedCallbackArgs): UseTimedCallbackResult => {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  const run = (
    callback: NextCallback,
    { delay, cancel }: NextOptions = {}
  ): void => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    if (cancel) {
      return;
    }

    timeoutRef.current = window.setTimeout(callback, delay ?? speed);
  };

  const cancel = (): void => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  };

  return { run, cancel };
};
