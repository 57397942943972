import type { RuntimeHost, Xinglet } from '@xing-com/crate-xinglet';
import { XingletLoader } from '@xing-com/crate-xinglet';

type XingletNoProps = Record<string, never>;

export interface XingletInterface<Props = XingletNoProps> extends Xinglet {
  getComponent: (host: RuntimeHost) => React.ComponentType<Props>;
}

type XingletName = `@xing-com/crate-jobs-${string}`;
type XingletWrapper = <Props = XingletNoProps>(
  name: XingletName,
  fallback?: React.ReactElement,
  error?: React.ReactElement
) => React.ComponentType<Props>;

export const JobsXingletWrapper: XingletWrapper =
  (name, fallback?, error?) => (props) => (
    <XingletLoader name={name} fallback={fallback} error={error} {...props} />
  );
