import {
  IconPublic,
  IconClock,
  IconSync,
  IconSalary,
  IconPartTime,
  IconMiniJob,
} from '@xing-com/icons';
import {
  xdlColorCardInspire03,
  xdlColorCardInspireText03,
  xdlPalettePetrol02,
  xdlPalettePetrol09,
  xdlPaletteMint10,
  xdlPaletteLime02,
} from '@xing-com/tokens';

export const INSPIRATIONAL_CARDS_DATA = [
  {
    icon: IconPublic,
    title: 'TITLE_CARD_1',
    description: 'DESCRIPTION_CARD_1',
    link: '/jobs/search?remoteOption=FULL_REMOTE.050e26',
    backgroundColor: xdlColorCardInspire03,
    textColor: xdlColorCardInspireText03,
    trackingParam:
      '&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_remote_work',
  },
  {
    icon: IconClock,
    title: 'TITLE_CARD_2',
    description: 'DESCRIPTION_CARD_2',
    link: '/jobs/search?benefit=1.795d28',
    backgroundColor: xdlPalettePetrol02,
    textColor: xdlPalettePetrol09,
    trackingParam:
      '&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_flexible_work',
  },
  {
    icon: IconSync,
    title: 'TITLE_CARD_3',
    description: 'DESCRIPTION_CARD_3',
    link: '/jobs/t-quereinsteiger?keywords=Quereinsteiger ',
    backgroundColor: xdlPaletteLime02,
    textColor: xdlPaletteMint10,
    trackingParam:
      '&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_career_change',
  },
  {
    icon: IconSalary,
    title: 'TITLE_CARD_4',
    description: 'DESCRIPTION_CARD_4',
    link: 'https://login.xing.com/',
    backgroundColor: xdlPalettePetrol02,
    textColor: xdlPalettePetrol09,
    trackingParam:
      '?sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_top_salary',
  },
  {
    icon: IconPartTime,
    title: 'TITLE_CARD_5',
    description: 'DESCRIPTION_CARD_5',
    link: '/jobs/search?employmentType=PART_TIME.58889d',
    backgroundColor: xdlPaletteLime02,
    textColor: xdlPaletteMint10,
    trackingParam:
      '&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_part_time',
  },
  {
    icon: IconMiniJob,
    title: 'TITLE_CARD_6',
    description: 'DESCRIPTION_CARD_6',
    link: '/jobs/search?employmentType=TEMPORARY.8ff6ad',
    backgroundColor: xdlColorCardInspire03,
    textColor: xdlColorCardInspireText03,
    trackingParam:
      '&sc_o=losp_inspirational_card_click&sc_o_PropActionOrigin=losp_inspirational_card_minijobs',
  },
];
