import { useIntl } from 'react-intl';

import { DisabledPreferences } from '@xing-com/crate-startpage-logged-out-disabled-preferences';

import { preferencesData } from './data';
import {
  StyledOptionsContainer,
  StyledPillSelect,
  DisabledPreferencesContainer,
} from './preferences-section.styles';
import type { VariantProps } from './types';

export const PreferencesForm: React.FC<VariantProps> = ({
  preferences,
  onChange,
  onFencedClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledOptionsContainer>
        {preferencesData.options.map((option) => (
          <StyledPillSelect
            key={option.preference}
            header={formatMessage({ id: option.header })}
            options={Object.keys(option.possibleValues).map((value) => ({
              copy: formatMessage({ id: value }),
              value,
            }))}
            onChange={(value) => onChange && onChange(option.preference, value)}
            value={preferences?.[option.preference] || ''}
            onPlusClick={() =>
              onFencedClick && onFencedClick(option.preference)
            }
          />
        ))}
      </StyledOptionsContainer>

      <DisabledPreferencesContainer>
        <DisabledPreferences
          header={formatMessage({ id: preferencesData.disabled.header })}
          items={preferencesData.disabled.items.map((item) => ({
            ...item,
            copy: formatMessage({ id: item.copy }),
          }))}
          more={preferencesData.disabled.more}
          onClick={onFencedClick}
        />
      </DisabledPreferencesContainer>
    </>
  );
};
