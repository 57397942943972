import type * as React from 'react';
import styled from 'styled-components';

import { NUM_COLS } from '@xing-com/layout-primitives';

import {
  MEDIA_QUERIES,
  PROPS_START,
  PROPS_SPAN,
  MEDIA_QUERY_IDS,
} from './legacy-constants';

export type SpanSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type StartSizes = 'auto' | SpanSizes;

type ItemStylesProps = {
  // start
  $startXSmall: StartSizes;
  $start: /* Small */ StartSizes;
  $startMedium: StartSizes;
  $startLarge: StartSizes;
  $startXLarge: StartSizes;
  // span
  $spanXSmall: SpanSizes;
  $span: /* Small */ SpanSizes;
  $spanMedium: SpanSizes;
  $spanLarge: SpanSizes;
  $spanXLarge: SpanSizes;
};

type ItemProps = {
  children?: React.ReactNode | undefined;
  // start
  /** @deprecated use startXSmall instead */
  startTiny?: ItemStylesProps['$startXSmall'];
  startXSmall?: ItemStylesProps['$startXSmall'];
  start /* Small */?: ItemStylesProps['$start'];
  startMedium?: ItemStylesProps['$startMedium'];
  startLarge?: ItemStylesProps['$startLarge'];
  startXLarge?: ItemStylesProps['$startXLarge'];
  // span
  /** @deprecated use spanXSmall instead */
  spanTiny?: ItemStylesProps['$spanXSmall'];
  spanXSmall?: ItemStylesProps['$spanXSmall'];
  span /* Small */?: ItemStylesProps['$span'];
  spanMedium?: ItemStylesProps['$spanMedium'];
  spanLarge?: ItemStylesProps['$spanLarge'];
  spanXLarge?: ItemStylesProps['$spanXLarge'];
};

const StyledItem = styled.div<ItemStylesProps>`
  min-width: 0;
  grid-column: ${({ $start, $span }) => `${$start} / span ${$span}`};
  ${(props) =>
    // start and span based on each media query
    MEDIA_QUERY_IDS.map((media) => {
      // fetch props names from data
      const $start = PROPS_START[media];
      const $span = PROPS_SPAN[media];
      return `${MEDIA_QUERIES[media]} {
            grid-column: ${props[$start]} / span ${props[$span]};
          }`;
    }).join('\n')}
`;

/**
 * Throw if start plus span value exceeds number of columns
 */
const checkExceedsNumberOfColumns = (
  start: StartSizes,
  span: SpanSizes
): void => {
  const startNum = start === 'auto' ? 1 : start;
  // startNum is index 1 based so we do the startNum - 1
  const startNumZeroIndex = startNum - 1;
  if (startNumZeroIndex + span > NUM_COLS) {
    throw new Error(
      `Please provide a valid combination of start and span parameters to fit in a ${NUM_COLS} column grid. Provided start: ${start} span: ${span}`
    );
  }
};

export const GridItem: React.FC<ItemProps> = ({
  children,
  startTiny,
  startXSmall,
  start,
  startMedium,
  startLarge,
  startXLarge,
  spanTiny,
  spanXSmall,
  span,
  spanMedium,
  spanLarge,
  spanXLarge,
  ...props
}) => {
  const startValue = start ?? 'auto';
  const startXSmallValue = startXSmall ?? startTiny ?? startValue;
  const startMediumValue = startMedium ?? startValue;
  const startLargeValue = startLarge ?? startMediumValue;
  const startXLargeValue = startXLarge ?? startLargeValue;

  const spanValue = span ?? NUM_COLS;
  const spanXSmallValue = spanXSmall ?? spanTiny ?? spanValue;
  const spanMediumValue = spanMedium ?? spanValue;
  const spanLargeValue = spanLarge ?? spanMediumValue;
  const spanXLargeValue = spanXLarge ?? spanLargeValue;

  checkExceedsNumberOfColumns(startValue, spanValue);
  checkExceedsNumberOfColumns(startXSmallValue, spanXSmallValue);
  checkExceedsNumberOfColumns(startMediumValue, spanMediumValue);
  checkExceedsNumberOfColumns(startLargeValue, spanLargeValue);
  checkExceedsNumberOfColumns(startXLargeValue, spanXLargeValue);

  return (
    <StyledItem
      $start={startValue}
      $startXSmall={startXSmallValue}
      $startMedium={startMediumValue}
      $startLarge={startLargeValue}
      $startXLarge={startXLargeValue}
      $span={spanValue}
      $spanXSmall={spanXSmallValue}
      $spanMedium={spanMediumValue}
      $spanLarge={spanLargeValue}
      $spanXLarge={spanXLargeValue}
      {...props}
    >
      {children}
    </StyledItem>
  );
};
