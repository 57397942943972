// import AirbusPhoto from '../assets/airbus-photo.webp';
import AmazonPhoto from '../assets/amazon-photo.webp';
import AokPhoto from '../assets/aok-photo.webp';
// import AudiPhoto from '../assets/audi-photo.webp';
import BasfPhoto from '../assets/basf-photo.webp';
import BmwPhoto from '../assets/bmw-photo.webp';
// import BoschPhoto from '../assets/bosch-photo.webp';
import DeutscheBahnPhoto from '../assets/db-photo.webp';
// import DeutscheBankPhoto from '../assets/deutsche-bank-photo.webp';
import DeutschePostPhoto from '../assets/deutsche-post.webp';
import DmPhoto from '../assets/dm-photo.webp';
import DuerenhoffPhoto from '../assets/duerenhoff-photo.webp';
import IkeaPhoto from '../assets/ikea-photo.webp';
import KauflandPhoto from '../assets/kaufland-photo.webp';
// import MeinestadtPhoto from '../assets/meinestadt-photo.webp';
import OttobockPhoto from '../assets/ottobock-photo.webp';
import PorschePhoto from '../assets/porsche-photo.webp';
import RewePhoto from '../assets/rewe-photo.webp';
import RossmanPhoto from '../assets/rossman-photo.webp';
import SapPhoto from '../assets/sap-photo.webp';
import SiemensPhoto from '../assets/siemens-photo.webp';

export interface Company {
  id: string;
  position: number;
  photoUrl: string;
  flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES';
  rating?: number;
  name: string;
  jobTitle: string;
  insight: string;
  profileUrl: string;
}

export const CompaniesData: Company[] = [
  {
    position: 22,
    id: '25777.4aa45e',
    photoUrl: AmazonPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.3,
    name: 'Amazon',
    jobTitle: '> 10.000 Mitarbeitende; 24.390 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AMAZON',
    profileUrl: 'https://www.xing.com/pages/amazon',
  },
  {
    position: 23,
    id: '26904.0ec123',
    photoUrl: DmPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.6,
    name: 'dm',
    jobTitle: '> 10.000 Mitarbeitende; 12.155 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DM',
    profileUrl: 'https://www.xing.com/pages/dm',
  },
  {
    position: 25,
    id: '28940.ea73ac',
    photoUrl: DeutscheBahnPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.8,
    name: 'Deutsche Bahn',
    jobTitle: '> 10.000 Mitarbeitende; 72.157 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_BAHN',
    profileUrl: 'https://www.xing.com/pages/deutschebahn',
  },
  {
    position: 26,
    id: '29098.96165f',
    photoUrl: PorschePhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    name: 'Porsche',
    jobTitle: '> 10.000 Mitarbeitende; 56.698 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_PORSCHE',
    profileUrl: 'https://www.xing.com/pages/dr-ing-h-c-f-porscheag',
  },
  {
    position: 27,
    id: '49539.9088dd',
    photoUrl: RossmanPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.5,
    name: 'ROSSMANN',
    jobTitle: '> 10.000 Mitarbeitende; 6.766 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_ROSSMANN',
    profileUrl: 'https://www.xing.com/pages/dirkrossmanngmbh',
  },
  {
    position: 28,
    id: '27300.104c4c',
    photoUrl: BmwPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.9,
    name: 'BMW',
    jobTitle: '> 10.000 Mitarbeitende; 95.398 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BMW',
    profileUrl: 'https://www.xing.com/pages/bmwgroup',
  },
  {
    position: 29,
    id: '27112.bfd31a',
    photoUrl: RewePhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.4,
    name: 'REWE',
    jobTitle: '> 10.000 Mitarbeitende; 19.527 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_REWE',
    profileUrl: 'https://www.xing.com/pages/rewe',
  },
  {
    position: 30,
    id: '25802.5e4ac9',
    photoUrl: SiemensPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.7,
    name: 'Siemens',
    jobTitle: '> 10.000 Mitarbeitende; 117.771 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SIEMENS',
    profileUrl: 'https://www.xing.com/pages/siemensag',
  },
  // {
  //   position: 31,
  //   photoUrl: MeinestadtPhoto,
  //   flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
  //   rating: 3.2,
  //   name: 'meinestadt.de',
  //   jobTitle: '201-500 Mitarbeitende; 1.049 Follower',
  //   insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_MEINESTADT_DE',
  //   profileUrl: 'https://www.xing.com/pages/meinestadt-de',
  // },
  // {
  //   position: 32,
  //   photoUrl: BoschPhoto,
  //   flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
  //   rating: 3.9,
  //   name: 'Bosch',
  //   jobTitle: '> 10.000 Mitarbeitende; 94.783 Follower',
  //   insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BOSCH',
  //   profileUrl: 'https://www.xing.com/pages/boschgruppe',
  // },
  {
    position: 33,
    id: '32491.0ce9ee',
    photoUrl: KauflandPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.2,
    name: 'Kaufland',
    jobTitle: '> 10.000 Mitarbeitende; 14.661 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_KAUFLAND',
    profileUrl: 'https://www.xing.com/pages/kaufland',
  },
  // {
  //   position: 34,
  //   photoUrl: AirbusPhoto,
  //   flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
  //   rating: 3.7,
  //   name: 'Airbus',
  //   jobTitle: '> 10.000 Mitarbeitende; 37.311 Follower',
  //   insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AIRBUS',
  //   profileUrl: 'https://www.xing.com/pages/airbus',
  // },
  {
    position: 35,
    id: '25982.1b3161',
    photoUrl: DeutschePostPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.8,
    name: 'Deutsche Post',
    jobTitle: '> 10.000 Mitarbeitende; 31.815 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_POST',
    profileUrl: 'https://www.xing.com/pages/deutschepostag',
  },
  {
    position: 36,
    id: '34095.d67191',
    photoUrl: BasfPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.6,
    name: 'BASF',
    jobTitle: '> 10.000 Mitarbeitende; 35.560 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_BASF',
    profileUrl: 'https://www.xing.com/pages/basf',
  },
  {
    position: 37,
    id: '27766.201f32',
    photoUrl: IkeaPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.6,
    name: 'IKEA',
    jobTitle: '> 10.000 Mitarbeitende; 9.755 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_IKEA',
    profileUrl: 'https://www.xing.com/pages/ikeadeutschland',
  },
  {
    position: 38,
    id: '34095.d67191',
    photoUrl: SapPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 4.4,
    name: 'SAP',
    jobTitle: '> 10.000 Mitarbeitende; 45.608 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_SAP',
    profileUrl: 'https://www.xing.com/pages/sap',
  },
  // {
  //   position: 39,
  //   photoUrl: AudiPhoto,
  //   flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
  //   rating: 3.9,
  //   name: 'Audi',
  //   jobTitle: '> 10.000 Mitarbeitende; 61.318 Follower',
  //   insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AUDI',
  //   profileUrl: 'https://www.xing.com/pages/audiag',
  // },
  {
    position: 40,
    id: '34095.d67191',
    photoUrl: AokPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.9,
    name: 'AOK',
    jobTitle: '> 10.000 Mitarbeitende; 10.306 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_AOK',
    profileUrl: 'https://www.xing.com/pages/aok',
  },
  {
    position: 41,
    id: '274329.e5aa05',
    photoUrl: DuerenhoffPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 4.8,
    name: 'duerenhoff GmbH',
    jobTitle: '11-50 Mitarbeitende; 607 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DUERENHOFF_GMBH',
    profileUrl: 'https://www.xing.com/pages/duerenhoffgmbh',
  },
  // {
  //   position: 42,
  //   photoUrl: DeutscheBankPhoto,
  //   flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
  //   rating: 3.5,
  //   name: 'Deutsche Bank',
  //   jobTitle: '> 10.000 Mitarbeitende; 30.387 Follower',
  //   insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_DEUTSCHE_BANK',
  //   profileUrl: 'https://www.xing.com/pages/deutschebankag',
  // },
  {
    position: 43,
    id: '1870653.c9bc3e',
    photoUrl: OttobockPhoto,
    flagText: 'RECRUITERS_SECTION_FLAG_COMPANIES',
    rating: 3.7,
    name: 'Ottobock',
    jobTitle: '> 5.000 Mitarbeitende; 3.097 Follower',
    insight: 'LOSP_RECRUITERS_SECTION_INSIGHTS_FOR_OTTOBOCK',
    profileUrl: 'https://www.xing.com/pages/ottobockse-co-kgaa',
  },
];
