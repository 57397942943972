import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import { Divider } from '@xing-com/divider';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceXS,
  spaceM,
  spaceXL,
  spaceXXL,
  scale010,
  scale150,
  scale160,
  scale350,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const StyledSection = styled.section`
  margin-bottom: ${scale350};
`;

export const Title = styled(Headline)`
  font-weight: normal;
  margin-bottom: calc(${scale010} + ${scale150});
`;

export const SerpTypeButtonContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  @media ${mediaConfined} {
    overflow-x: visible;
    white-space: normal;
    width: auto;
  }
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: ${scale160};
`;

export const SerpTypeButtonWrapper = styled.span`
  @media ${mediaConfined} {
    margin-right: ${spaceXXL};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SerpTypeButton = styled(TextButton)<{ isActive?: boolean }>`
  color: ${(props) =>
    props.isActive ? `${xdlColorText}` : `${xdlColorTextSecondary}`};
  margin: 0 ${scale150} ${spaceM} 0;

  @media ${mediaConfined} {
    margin: 0;
  }
`;

export const StyledAnchor = styled.a<{ isSerpActive: boolean }>`
  color: ${xdlColorText};
  display: ${(props) => (props.isSerpActive ? 'inline-block' : 'none')};
  margin: 0 ${spaceXS} ${spaceXL} 0;

  &:hover {
    text-decoration: none;
  }

  & > p {
    display: inline;
  }
`;
