export const trendingJobsStaticData = {
  queryTime: 1721027177870,
  data: [
    {
      id: 121519288,
      url: 'https://www.xing.com/jobs/koeln-136900-steuerberater-zusatzqualifikation-wirtschaftspruefer-fuehrender-koelner-kanzlei-gesucht-121519288',
      country: 'DE',
      hotnessScore: 11.27371273712737,
    },
    {
      id: 122231688,
      url: 'https://www.xing.com/jobs/nuernberg-kalkulator-all-gender-verpackungsloesungen-luxusgueterbereich-122231688',
      country: 'DE',
      hotnessScore: 4.444444444444445,
    },
    {
      id: 122171942,
      url: 'https://www.xing.com/jobs/muenchen-mitarbeiter-zentrales-belegungsmanagement-vollzeit-40-std-woche-teilzeit-122171942',
      country: 'DE',
      hotnessScore: 3.992773261065944,
    },
    {
      id: 115357178,
      url: 'https://www.xing.com/jobs/siegburg-senior-net-entwickler-full-remote-115357178',
      country: 'DE',
      hotnessScore: 3.0386178861788617,
    },
    {
      id: 121910394,
      url: 'https://www.xing.com/jobs/rosenheim-spezialist-121910394',
      country: 'DE',
      hotnessScore: 1.905511811023622,
    },
    {
      id: 122174500,
      url: 'https://www.xing.com/jobs/bremen-mitarbeiter-arbeitsvorbereitung-fertigungssteuerung-122174500',
      country: 'DE',
      hotnessScore: 1.494898772517137,
    },
    {
      id: 122082768,
      url: 'https://www.xing.com/jobs/langenzenn-vertriebsmitarbeiter-aussendienst-deutschland-europa-122082768',
      country: 'DE',
      hotnessScore: 1.0926829268292684,
    },
    {
      id: 122222021,
      url: 'https://www.xing.com/jobs/braunschweig-teamlead-produktionsleiter-schichtleiter-fertigung-anlagen-mechanik-122222021',
      country: 'DE',
      hotnessScore: 1.08130081300813,
    },
    {
      id: 122037309,
      url: 'https://www.xing.com/jobs/wien-hr-business-partner-122037309',
      country: 'AT',
      hotnessScore: 0.9577149739751366,
    },
    {
      id: 121598777,
      url: 'https://www.xing.com/jobs/bensheim-senior-marketing-manager-121598777',
      country: 'DE',
      hotnessScore: 0.768733850129199,
    },
    {
      id: 122453743,
      url: 'https://www.xing.com/jobs/augsburg-gebietsleiter-aussendienst-sueddeutschland-122453743',
      country: 'DE',
      hotnessScore: 0.44986449864498645,
    },
    {
      id: 121909654,
      url: 'https://www.xing.com/jobs/koeln-mitarbeiter-partnermanagement-121909654',
      country: 'DE',
      hotnessScore: 0.44876758291392443,
    },
    {
      id: 122189695,
      url: 'https://www.xing.com/jobs/berlin-systemadministrator-122189695',
      country: 'DE',
      hotnessScore: 0.3902439024390244,
    },
    {
      id: 122117436,
      url: 'https://www.xing.com/jobs/albstadt-key-account-manager-im-technischen-vertrieb-122117436',
      country: 'DE',
      hotnessScore: 0.3774680603948897,
    },
    {
      id: 122356307,
      url: 'https://www.xing.com/jobs/goslar-sozialpaedagoge-sozialarbeiter-landkreis-goslar-122356307',
      country: 'DE',
      hotnessScore: 0.3712737127371274,
    },
    {
      id: 122133513,
      url: 'https://www.xing.com/jobs/neustadt-wied-assistenz-geschaeftsfuehrung-122133513',
      country: 'DE',
      hotnessScore: 0.35772357723577236,
    },
    {
      id: 122083285,
      url: 'https://www.xing.com/jobs/regensburg-projektleiter-hlks-122083285',
      country: 'DE',
      hotnessScore: 0.34146341463414637,
    },
    {
      id: 122141740,
      url: 'https://www.xing.com/jobs/berlin-teamleiter-immobilien-mietverwaltung-property-management-122141740',
      country: 'DE',
      hotnessScore: 0.32103397957056495,
    },
    {
      id: 121858374,
      url: 'https://www.xing.com/jobs/grossheubach-technischer-mitarbeiter-angebotskalkulation-121858374',
      country: 'DE',
      hotnessScore: 0.32103397957056495,
    },
    {
      id: 122442527,
      url: 'https://www.xing.com/jobs/bremen-personalsachbearbeiter-122442527',
      country: 'DE',
      hotnessScore: 0.3035230352303523,
    },
  ],
};
