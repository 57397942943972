import { useRef, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TextButton } from '@xing-com/button';
import type { JobRecommendationFragment } from '@xing-com/crate-jobs-graphql';
import { IconArrowDown, IconArrowUp } from '@xing-com/icons';

const ShowMoreButton = styled(TextButton)`
  grid-column: 1 / -1;
`;

type Props = {
  recommendations: JobRecommendationFragment[];
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
};

const INITIAL_ITEMS_TO_SHOW = 3;

export const JobsRecommendationsGrid: React.FC<PropsWithChildren<Props>> = ({
  children,
  recommendations,
  isCollapsed,
  setIsCollapsed,
}) => {
  const [initialShowMoreY, setInitialShowMoreY] = useState(0);
  const showMoreRef = useRef<HTMLButtonElement>(null);

  const isShowMoreShown = recommendations.length > INITIAL_ITEMS_TO_SHOW;

  const onShowToggle = (): void => {
    setIsCollapsed(!isCollapsed);

    if (!showMoreRef.current) {
      return;
    }

    const currentShowMoreY =
      showMoreRef.current.getBoundingClientRect().y + window.scrollY;

    if (isCollapsed) {
      setInitialShowMoreY(currentShowMoreY);
    } else {
      // If we collapse the grid, we want to scroll back to the initial position
      // so the user is not confused.
      window.scrollBy({
        top: initialShowMoreY - currentShowMoreY,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {children}
      {isShowMoreShown ? (
        <ShowMoreButton
          innerRef={showMoreRef}
          onClick={onShowToggle}
          icon={isCollapsed ? IconArrowDown : IconArrowUp}
          iconAfter
        >
          <FormattedMessage
            id={
              isCollapsed
                ? 'JOBS_FIND_JOBS_SIMILAR_JOBS_SHOW_MORE'
                : 'JOBS_FIND_JOBS_SIMILAR_JOBS_SHOW_LESS'
            }
          />
        </ShowMoreButton>
      ) : null}
    </>
  );
};
