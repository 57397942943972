import type React from 'react';

import { TeaserCard } from './teaser-card';
import { TeaserCardsData } from './teaser-cards-data';
import { StyledSection } from './teaser-section.styles';

export const TeaserSection: React.FC = () => (
  <StyledSection>
    {TeaserCardsData.map((teaserCard) => (
      <TeaserCard key={`${teaserCard.id}-card`} {...teaserCard} />
    ))}
  </StyledSection>
);
