export const trendingJobsStaticData = {
  queryTime: 1716276035710,
  data: [
    {
      id: 68555640,
      url: 'https://www.xing.com/jobs/duesseldorf-fachkraefte-fuer-systemadministration-120031000',
      country: 'DE',
      companyId: 1150353,
      companyUrl:
        'https://www.xing.com/pages/landeskriminalamt-nordrhein-westfalen',
      companyHasLogo: 1,
      numVisitors: 708,
      numVisitorsBefore: 8,
      hotnessScore: 60.67580803134182,
    },
    {
      id: 7912340,
      url: 'https://www.xing.com/jobs/nuernberg-produktions-lagermitarbeiter-120073947',
      country: 'DE',
      companyId: 408875,
      companyUrl:
        'https://www.xing.com/pages/h-o-tharte-undoberflachentechnikgmbh-co-kg',
      companyHasLogo: 1,
      numVisitors: 101,
      numVisitorsBefore: 1,
      hotnessScore: 9.892262487757101,
    },
    {
      id: 67909988,
      url: 'https://www.xing.com/jobs/wien-account-manager-120044117',
      country: 'AT',
      companyId: 141096,
      companyUrl: 'https://www.xing.com/pages/kununu',
      companyHasLogo: 1,
      numVisitors: 111,
      numVisitorsBefore: 4,
      hotnessScore: 2.9081782566111656,
    },
    {
      id: 67881147,
      url: 'https://www.xing.com/jobs/cham-assistenz-geschaeftsfuehrung-bueroassistenz-buerokauffrau-119980032',
      country: 'DE',
      companyId: 1961237,
      companyUrl: 'https://www.xing.com/pages/elegance-hair',
      companyHasLogo: 1,
      numVisitors: 197,
      numVisitorsBefore: 22,
      hotnessScore: 1.53481435312973,
    },
    {
      id: 67917834,
      url: 'https://www.xing.com/jobs/bremen-mitarbeiter-partnership-sales-120134291',
      country: 'DE',
      companyId: 308479,
      companyUrl: 'https://www.xing.com/pages/svwerderbremengmbh-cokgaa',
      companyHasLogo: 1,
      numVisitors: 1021,
      numVisitorsBefore: 0,
      hotnessScore: 1,
    },
    {
      id: 67946861,
      url: 'https://www.xing.com/jobs/hamburg-sachbearbeiter-vertriebsinnendienst-119893399',
      country: 'DE',
      companyId: 1972389,
      companyUrl: 'https://www.xing.com/pages/theodor-rumoeller-e-k',
      companyHasLogo: 1,
      numVisitors: 227,
      numVisitorsBefore: 44,
      hotnessScore: 0.9246950405128662,
    },
    {
      id: 68555638,
      url: 'https://www.xing.com/jobs/salmtal-technischer-vertriebskoordinator-119950616',
      country: 'DE',
      companyId: 1933421,
      companyUrl: 'https://www.xing.com/pages/coboworxgmbh',
      companyHasLogo: 1,
      numVisitors: 53,
      numVisitorsBefore: 3,
      hotnessScore: 0.8651648710414628,
    },
    {
      id: 67894519,
      url: 'https://www.xing.com/jobs/hamburg-office-manager-119966578',
      country: 'DE',
      companyId: 1540344,
      companyUrl: 'https://www.xing.com/pages/deutsche-investment',
      companyHasLogo: 1,
      numVisitors: 59,
      numVisitorsBefore: 5,
      hotnessScore: 0.6240940254652302,
    },
    {
      id: 67912644,
      url: 'https://www.xing.com/jobs/willich-leitung-soziale-dienste-119466711',
      country: 'DE',
      companyId: 1973925,
      companyUrl: 'https://www.xing.com/pages/onlyfy-talentservice',
      companyHasLogo: 1,
      numVisitors: 107,
      numVisitorsBefore: 17,
      hotnessScore: 0.5548193812294752,
    },
    {
      id: 67938351,
      url: 'https://www.xing.com/jobs/meerbusch-product-owner-119934846',
      country: 'DE',
      companyId: 69443,
      companyUrl: 'https://www.xing.com/pages/riegesoftwareinternationalgmbh',
      companyHasLogo: 1,
      numVisitors: 66,
      numVisitorsBefore: 7,
      hotnessScore: 0.5448439904855183,
    },
    {
      id: 67943709,
      url: 'https://www.xing.com/jobs/hildesheim-personalsachbearbeiter-119928439',
      country: 'DE',
      companyId: 15847,
      companyUrl:
        'https://www.xing.com/pages/hawkhochschulefurangewandtewissenschaftundkunst',
      companyHasLogo: 1,
      numVisitors: 125,
      numVisitorsBefore: 28,
      hotnessScore: 0.42412900517699736,
    },
    {
      id: 68547294,
      url: 'https://www.xing.com/jobs/bad-bramstedt-sachbearbeiter-stammdatenmanagement-119926396',
      country: 'DE',
      companyId: 442997,
      companyUrl: 'https://www.xing.com/pages/bauxpertgmbh',
      companyHasLogo: 1,
      numVisitors: 104,
      numVisitorsBefore: 22,
      hotnessScore: 0.37966343157332383,
    },
    {
      id: 67917435,
      url: 'https://www.xing.com/jobs/villingen-schwenningen-sachbearbeiter-vertriebsinnendienst-119919877',
      country: 'DE',
      companyId: 1997215,
      companyUrl: 'https://www.xing.com/pages/sf-filter-group',
      companyHasLogo: 1,
      numVisitors: 83,
      numVisitorsBefore: 15,
      hotnessScore: 0.3685275873326804,
    },
    {
      id: 67936884,
      url: 'https://www.xing.com/jobs/karlsruhe-minijob-im-kundensupport-homeoffice-quereinsteiger-120197056',
      country: 'DE',
      companyId: 1220825,
      companyUrl: 'https://www.xing.com/pages/linconag',
      companyHasLogo: 1,
      numVisitors: 260,
      numVisitorsBefore: 0,
      hotnessScore: 0.2546523016650343,
    },
    {
      id: 67918198,
      url: 'https://www.xing.com/jobs/bremen-versandmitarbeiter-120124417',
      country: 'DE',
      companyId: 1993687,
      companyUrl: 'https://www.xing.com/pages/marahrens-group',
      companyHasLogo: 1,
      numVisitors: 215,
      numVisitorsBefore: 0,
      hotnessScore: 0.21057786483839372,
    },
    {
      id: 67946390,
      url: 'https://www.xing.com/jobs/hamburg-manager-compliance-geldwaeschepraevention-teilzeit-119934847',
      country: 'DE',
      companyId: 1907578,
      companyUrl: 'https://www.xing.com/pages/mennewisch-co-gmbh',
      companyHasLogo: 1,
      numVisitors: 51,
      numVisitorsBefore: 10,
      hotnessScore: 0.2047992164544564,
    },
    {
      id: 68553973,
      url: 'https://www.xing.com/jobs/berlin-it-support-mitarbeiter-teilzeit-20-30-wochenstunden-berlin-120136359',
      country: 'DE',
      companyId: 2004285,
      companyUrl:
        'https://www.xing.com/pages/deutsche-psychotherapeutenvereinigung-e-v',
      companyHasLogo: 1,
      numVisitors: 186,
      numVisitorsBefore: 0,
      hotnessScore: 0.1821743388834476,
    },
    {
      id: 67903297,
      url: 'https://www.xing.com/jobs/duesseldorf-experte-produktion-montage-qualitaet-team-betriebsleitung-119872683',
      country: 'DE',
      companyId: 1999310,
      companyUrl: 'https://www.xing.com/pages/ekd-systems-gmbh',
      companyHasLogo: 1,
      numVisitors: 79,
      numVisitorsBefore: 25,
      hotnessScore: 0.16713026444662096,
    },
    {
      id: 67908420,
      url: 'https://www.xing.com/jobs/hamburg-international-sales-business-development-manager-consumer-health-care-119872685',
      country: 'DE',
      companyId: 913868,
      companyUrl: 'https://www.xing.com/pages/eurovitalpharmagmbh',
      companyHasLogo: 1,
      numVisitors: 89,
      numVisitorsBefore: 31,
      hotnessScore: 0.16309121354775521,
    },
    {
      id: 67924664,
      url: 'https://www.xing.com/jobs/hamburg-php-react-full-stack-developer-senior-level-all-genders-119876924',
      country: 'DE',
      companyId: 1932481,
      companyUrl: 'https://www.xing.com/pages/pirateshipsoftwaregmbh',
      companyHasLogo: 1,
      numVisitors: 90,
      numVisitorsBefore: 35,
      hotnessScore: 0.13851965859801316,
    },
  ],
};
