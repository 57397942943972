import styled from 'styled-components';

import { Marker } from '@xing-com/marker';
import {
  xdlPaletteOrange06,
  xdlPalettePurpleRain05,
  xdlPalettePurpleRain07,
} from '@xing-com/tokens';

export const GradientMarker = styled(Marker)`
  background: radial-gradient(
      74.24% 367.5% at 8.59% 87.5%,
      ${xdlPaletteOrange06} 0%,
      rgba(138, 44, 232, 0) 100%
    ),
    radial-gradient(
      41.7% 190% at 93.43% 10%,
      ${xdlPalettePurpleRain05} 0%,
      ${xdlPalettePurpleRain07} 100%
    );
`;
