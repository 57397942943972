import { navigate } from '@reach/router';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { AI_SEARCH_AUTOFOCUS_PARAM } from '@xing-com/crate-jobs-constants';
import { useAnimatedPlaceholder } from '@xing-com/crate-jobs-domain-search-conversational-search-bar';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { IconSearch } from '@xing-com/icons';
import {
  mediaConfined,
  mediaSmallOrTiny,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { InputBar } from '@xing-com/text-field';
import { LightMode } from '@xing-com/theme-mode-switcher';
import { scale080, scale090, spaceL, spaceXL } from '@xing-com/tokens';

const Input = styled(InputBar)`
  margin: 0 auto;
  max-width: 648px;
  padding: 0 ${spaceL};

  & input {
    cursor: pointer;
    transition: border 0.18s ease-in-out;

    &::placeholder {
      font-size: ${scale080};
    }
  }

  @media ${mediaConfined} {
    padding: 0;

    & input {
      height: 90px;
      border-radius: 45px;

      &::placeholder {
        font-size: ${scale090};
      }
    }
  }

  @media ${mediaWideNavless} {
    margin-bottom: -${spaceXL};
  }
`;

export const FakeInput: React.FC = () => {
  const isSmallScreen = useMatchMedia(mediaSmallOrTiny);
  const inputRef = useRef(null);
  const runPlaceholderAnimation = useAnimatedPlaceholder(inputRef);

  useEffect(() => {
    runPlaceholderAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAiSearch = (): void => {
    navigate(`${ROUTES.searchAiLanding}?${AI_SEARCH_AUTOFOCUS_PARAM}=true`);
  };

  const handleOnKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      navigateToAiSearch();
    }
  };

  return (
    <LightMode>
      <Input
        // @ts-expect-error FIXME: SC6
        icon={isSmallScreen ? null : IconSearch}
        size="xlarge"
        variant="plain"
        value=""
        onClick={navigateToAiSearch}
        onKeyDown={handleOnKeyDown}
        innerRef={inputRef}
      />
    </LightMode>
  );
};
