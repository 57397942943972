/* eslint-disable @typescript-eslint/no-empty-function */
import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

import { Observed } from '@xing-com/crate-communication-in-view';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';

import type { NwtTrackingProps } from '../hooks/use-jobs-nwt-tracking';
import { useJobsNwtTracking } from '../hooks/use-jobs-nwt-tracking';

type Props = {
  nwtTrackingOptions?: NwtTrackingProps;
  adobeTrackingOptions?: InteractionTrackingEvent<{ PropTrackAction: string }>;
  onEnter?: () => void;
  skip?: boolean;
};

// The element is positioned to the left most corner so that
// that are inside a carousel or similar, will only be tracked
// when they are completely viewed. This takes the assumption that
// the carousel will be rtl
const PositionedObserved = styled(Observed)`
  float: right;
`;

export const InViewTracking: React.FC<PropsWithChildren<Props>> = ({
  children,
  nwtTrackingOptions,
  adobeTrackingOptions,
  onEnter,
  skip,
}) => {
  const isTrackedRef = useRef(false);
  const trackNwt = useJobsNwtTracking();
  const { track } =
    useTracking<InteractionTrackingEvent<{ PropTrackAction: string }>>();

  if (skip) return children;

  const handleOnEnter = (): void => {
    onEnter?.();

    if (isTrackedRef.current) {
      return;
    }

    if (nwtTrackingOptions) {
      trackNwt(nwtTrackingOptions);
    }
    if (adobeTrackingOptions) {
      track(adobeTrackingOptions);
    }
    isTrackedRef.current = true;
  };

  return (
    <>
      {children}
      <PositionedObserved
        renderAs="span"
        onEnter={handleOnEnter}
        onLeave={() => {}}
      />
    </>
  );
};
