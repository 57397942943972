import styled, { css } from 'styled-components';

import { zIndexElevatedLayer4 } from '@xing-com/layout-tokens';
import {
  cornerRadiusL,
  cornerRadiusXL,
  dropShadowM,
  scale005,
  spaceL,
  spaceM,
  spaceS,
  xdlColorBackground,
  xdlColorHover,
  xdlColorModalBorder,
  xdlColorText,
} from '@xing-com/tokens';

type MenuItemTransientProps = {
  $isHighlighted?: boolean;
};

// *** Components ***
export const AutoComplete = styled.div`
  position: relative;
`;

export const Menu = styled.ul`
  // pop-over styles
  background: ${xdlColorBackground};
  border-color: ${xdlColorModalBorder};
  border-radius: ${cornerRadiusXL};
  border-style: solid;
  border-width: ${scale005};
  box-shadow: ${dropShadowM};
  position: absolute;
  z-index: ${zIndexElevatedLayer4};

  // menu pop-over styles
  padding: ${spaceM} ${spaceL};

  // custom
  list-style: none;
  margin: 0;
  width: 100%;

  &:empty {
    border: none;
    box-shadow: none;
    padding: 0;
  }
`;

export const MenuItem = styled.li<MenuItemTransientProps>`
  color: ${xdlColorText};
  border-radius: ${cornerRadiusL};
  cursor: pointer;
  padding: ${spaceM} ${spaceS};

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background: ${xdlColorHover};
    `}
`;
