import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { mediaConfined, mediaSmallOrTiny } from '@xing-com/layout-tokens';
import {
  spaceXS,
  spaceM,
  spaceL,
  scale010,
  scale030,
  scale040,
  scale050,
  scale060,
  scale110,
  xdlColorBeta,
  xdlPalettePetrol02,
  xdlPalettePetrol09,
  scale200,
  scale170,
  cornerRadiusXL,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';
import { Column } from '@xing-com/xing-grid';

export const Container = styled(Column)`
  position: relative;
  display: inline;
  margin-bottom: calc(${scale200} + ${scale110});

  @media ${mediaConfined} {
    margin-right: ${scale170};
    margin-bottom: ${scale170};
  }
`;

export const Image = styled.img`
  border-radius: ${cornerRadiusXL};
  height: 233px;
  width: 288px;

  @media ${mediaConfined} {
    height: 324px;
    width: 380px;
  }
`;

export const StyledCard = styled(Card)`
  position: absolute;
  right: -${scale170};
  bottom: -${scale200};
  width: 242px;

  @media ${mediaConfined} {
    width: 300px;
  }
`;

export const UpperSectionCardContainer = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${spaceM} ${spaceL} ${scale050} ${spaceXS};
`;

export const IconWrapper = styled.span`
  align-items: center;
  display: flex;
  gap: ${spaceXS};

  & > svg {
    color: ${xdlColorBeta};
  }
`;

export const LowerSectionCardContainer = styled.div`
  padding: 0 ${spaceM} ${spaceL} calc(${scale010} + ${scale110});
`;

export const PillWrapper = styled.div`
  align-items: center;
  background: ${xdlPalettePetrol02};
  border-radius: ${scale060};
  color: ${xdlPalettePetrol09};
  display: inline-flex;
  gap: ${scale010};
  margin-bottom: ${spaceXS};
  padding: 0 ${scale040} 0 ${scale030};

  & * {
    vertical-align: middle;
  }

  & svg {
    margin-right: ${scale010};
  }

  &:nth-child(5) {
    margin: 0;
  }
`;

export const PillTag = styled(BodyCopy)`
  @media ${mediaSmallOrTiny} {
    font-size: ${scale050};
  }
`;
