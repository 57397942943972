import styled, { css } from 'styled-components';

import { AutoComplete } from '@xing-com/auto-complete';
import { Button, TextButton } from '@xing-com/button';
import { Divider as BaseDivider } from '@xing-com/divider';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { Menu, SingleSelect as BaseSingleSelect } from '@xing-com/menu';
import {
  scale090,
  scale100,
  scale180,
  spaceL,
  spaceXL,
  xdlColorBorderStrong,
  xdlColorButtonStrong,
  xdlColorButtonStrongHover,
  xdlColorDisabledStrong,
  xdlColorTextInvert,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
  xdlColorTextTertiaryOnLight,
  xdlPaletteGrayPlain02,
  xdlPaletteGrayPlain04,
  xdlPaletteGrayPlain11,
  xdlPaletteWhite,
  scale080,
  spaceM,
  spaceS,
  scale130,
} from '@xing-com/tokens';

export const Form = styled.form`
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    'key'
    'div'
    'loc';

  @media ${mediaTopBarHidden} {
    padding-left: 0;
    padding-right: 0;
    gap: ${spaceL};
    grid-template-columns: 1fr auto;
    grid-template-areas:
      'key key'
      'loc but';
  }
`;

export const Divider = styled(BaseDivider)`
  grid-area: div;

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;

export const LocationFieldsWrapper = styled.div`
  grid-area: loc;
  display: flex;
  position: relative;
`;

export const LocationAutoComplete = styled(AutoComplete)`
  && {
    flex: 1 1 auto;

    @media ${mediaTopBarVisible} {
      & ul {
        width: 100%;
      }

      & input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 48px;

        &,
        &::placeholder {
          line-height: 1.5;
          font-size: ${scale080};
        }

        &::-webkit-search-cancel-button {
          display: none;
        }
      }

      & button[type='reset'] {
        height: ${scale130};
        top: ${spaceS};
        left: ${spaceM};
        right: auto;
        background-color: ${xdlPaletteWhite};
      }
    }

    @media ${mediaTopBarHidden} {
      width: calc(100% - 200px);

      & ul {
        width: calc(100% + 200px);
      }

      & input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid ${xdlPaletteGrayPlain04};

        &:hover,
        &:focus {
          border-right: 1px solid ${xdlColorBorderStrong};
        }

        &,
        &::placeholder {
          line-height: 1.5;
          font-size: ${scale100};
        }

        &::-webkit-search-cancel-button {
          display: none;
        }
      }
    }
  }
`;

export const RadiusWrapper = styled.div`
  display: none;

  @media ${mediaTopBarHidden} {
    display: block;
    width: 200px;
    position: relative;
  }
`;

export const RadiusButton = styled(TextButton)<{ $isFilled?: boolean }>`
  && {
    height: ${scale180};
    font-size: ${scale100};
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0 20px;
    background-color: ${xdlPaletteWhite};
    color: ${xdlColorTextOnLight};
    border: 1px solid transparent;

    &:disabled > div {
      & > :last-child {
        color: ${xdlColorDisabledStrong};
      }

      & > :first-child {
        color: ${xdlColorDisabledStrong};
      }
    }

    &:hover {
      background-color: ${xdlPaletteGrayPlain02};
      border: 1px solid ${xdlColorBorderStrong};
    }

    & > div {
      width: 100%;
      justify-content: space-between;

      ${({ $isFilled }) =>
        !$isFilled &&
        css`
          & > :last-child {
            color: ${xdlColorTextTertiaryOnLight};
          }
        `}
    }
  }
`;

export const RadiusMenu = styled(Menu)`
  min-width: 100%;
  white-space: nowrap;
  right: 0;
`;

export const SingleSelect = styled(BaseSingleSelect)<{ selected?: boolean }>`
  div {
    width: 100%;
    justify-content: space-between;
  }
`;

export const SubmitButton = styled(Button)`
  grid-area: but;
  color: ${xdlColorTextInvert};
  flex-shrink: 0;

  &:after {
    background-color: ${xdlColorButtonStrong};
  }

  &:hover:after {
    background-color: ${xdlColorButtonStrongHover};
  }

  &:active {
    color: ${xdlColorTextOnDark};

    &:after {
      background-color: ${xdlPaletteGrayPlain11};
    }
  }
`;

export const SubmitButtonBig = styled(SubmitButton)`
  display: none;

  @media ${mediaTopBarHidden} {
    display: block;
    height: ${scale180};
    font-size: ${scale090};
    padding-inline: ${spaceXL};
  }
`;

export const SubmitButtonSmall = styled(SubmitButton)`
  position: absolute;
  right: ${spaceM};
  top: ${spaceS};

  @media ${mediaTopBarHidden} {
    display: none;
  }
`;
