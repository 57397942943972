/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconSkills = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconSkills"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14 2-.205.006A4.006 4.006 0 0 0 10 6H6v4l-.205.005A4.006 4.006 0 0 0 2 14l.006.205A4.006 4.006 0 0 0 6 18v4h16v-6h-2a2 2 0 1 1 0-4h2V6h-4l-.006-.205A4.006 4.006 0 0 0 14 2m-.118 2.003L14 4a2 2 0 0 1 1.997 1.882l.003.147V8h4v2a4 4 0 0 0-4 4l.005.2A4 4 0 0 0 20 18v2H8v-4H6a2 2 0 0 1-1.997-1.882L4 13.971c0-1.032.828-1.907 1.882-1.968L6.029 12H8V8h4V6a2 2 0 0 1 1.882-1.997"
    />
  </svg>
);
export default IconSkills;
