import styled from 'styled-components';

import { GridItem } from '@xing-com/grid';
import { mediaConfined, mediaWide } from '@xing-com/layout-tokens';
import { SkeletonBodyCopy, SkeletonHeadline } from '@xing-com/skeleton';
import { spaceL, spaceS, spaceXL, scale150 } from '@xing-com/tokens';

import { JobTeaserCardSkeleton } from '../../components/job-teaser-card';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceL};

  @media ${mediaConfined} {
    gap: ${spaceXL};
  }
`;

export const HeadlineContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: ${spaceS};
  margin-bottom: ${spaceL};
`;

export const Headline = styled(SkeletonHeadline)`
  width: 40%;
`;

export const BodyCopy = styled(SkeletonBodyCopy)`
  width: 30%;
`;

export const Carousel = styled.div`
  display: flex;
  overflow-x: auto;
  gap: ${spaceS};
  margin-right: -20px;
  padding-right: 20px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${mediaConfined} {
    margin-right: -18px;
    padding-right: 18px;
  }

  @media ${mediaWide} {
    gap: ${spaceL};
    margin-right: 0;
    padding-right: 0;
  }
`;

export const JobTeaser = styled(JobTeaserCardSkeleton)`
  min-width: 80%;
  min-height: 262px; // most common height

  @media ${mediaConfined} {
    min-width: 40%;
    min-height: 286.5px; // most common height
  }

  @media ${mediaWide} {
    min-width: calc((100% - ${spaceL}*2) / 3);
    min-height: 310.5px; // most common height
  }
`;

export const ShowMoreButton = styled.div`
  display: none;
  margin: 0 auto;

  @media ${mediaWide} {
    display: block;
    height: ${scale150};
    display: flex;
    align-items: center;
  }
`;
