import * as React from 'react';
import type { FC, CSSProperties } from 'react';
import styled from 'styled-components';

type ScrollDirection = 'UP' | 'DOWN';

const StyledPadder = styled.div``;

const StyledContainer = styled.div`
  position: sticky;
  display: flow-root; // contain margins of children
`;

type ScrollDirectionChangeHandler = (
  newScrollDirection: ScrollDirection
) => void;

const useScrollDirectionListener = (
  handleScrollDirectionChange: ScrollDirectionChangeHandler
): void => {
  const lastScrollTop = React.useRef(0);
  const scrollDirection = React.useRef<ScrollDirection>('UP');

  React.useEffect(() => {
    const onScroll = (): void => {
      const scrollTop = window.pageYOffset;

      const newScrollDirection =
        scrollTop > lastScrollTop.current ? 'DOWN' : 'UP';

      if (scrollDirection.current !== newScrollDirection) {
        scrollDirection.current = newScrollDirection;
        handleScrollDirectionChange(newScrollDirection);
      }

      lastScrollTop.current = scrollTop;
    };

    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [handleScrollDirectionChange]);
};

export const ScrollableSticky: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [padderStyles, setPadderStyles] = React.useState<CSSProperties>({});
  const [containerStyles, setContainerStyles] = React.useState<CSSProperties>(
    {}
  );

  const handleScrollDirectionChange = React.useCallback(
    (newScrollDirection: ScrollDirection) => {
      const containerHeight =
        containerRef.current?.getBoundingClientRect?.()?.height || 0;
      const containerY =
        (containerRef.current?.getBoundingClientRect()?.top || 0) +
        window.pageYOffset;
      const parentY =
        (containerRef.current?.parentElement?.getBoundingClientRect()?.top ||
          0) + window.pageYOffset;
      const windowHeight = window.innerHeight;
      const stickyPosition = windowHeight - containerHeight;

      if (containerHeight < windowHeight) {
        setContainerStyles({ top: parentY });
        setPadderStyles({ marginTop: 0 });
        return;
      }

      setContainerStyles(
        newScrollDirection === 'DOWN'
          ? { top: stickyPosition }
          : { bottom: stickyPosition - parentY }
      );

      setPadderStyles({
        marginTop: Math.max(0, containerY - parentY),
      });
    },
    []
  );

  React.useEffect(() => {
    handleScrollDirectionChange('UP');
  }, [handleScrollDirectionChange]);

  useScrollDirectionListener(handleScrollDirectionChange);

  return (
    <React.Fragment>
      <StyledPadder style={padderStyles} />
      <StyledContainer ref={containerRef} style={containerStyles}>
        {children}
      </StyledContainer>
    </React.Fragment>
  );
};
