import { useNavigate } from '@reach/router';
import { useIntl } from 'react-intl';

import { AI_FORCE_SWIMP_ANIMATION_PARAM } from '@xing-com/crate-jobs-constants';
import {
  IconSWIMP,
  SearchSuggestion,
} from '@xing-com/crate-jobs-domain-search-search-suggestions';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useJobsNwtTracking } from '@xing-com/crate-jobs-tracking';

export type SuggestionValues = 'SWIMP';

const SWIMP: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const trackNwt = useJobsNwtTracking();

  const handleOnClick = (): void => {
    trackNwt({
      event: 'clicked',
      element: 'search_with_my_profile',
      componentName: 'ai_search',
      eventSchema: 'aicomponent',
      page: 'jobs/index',
    });

    const params = new URLSearchParams({
      [AI_FORCE_SWIMP_ANIMATION_PARAM]: 'true',
      sc_o: 'search_with_my_profile_click',
      sc_o_PropActionOrigin: 'jobs_find_jobs',
    });
    navigate(`${ROUTES.searchAiLanding}?${params.toString()}`);
  };

  return (
    <SearchSuggestion
      Icon={IconSWIMP}
      title={formatMessage({ id: 'JOBS_SEARCH_AI_SWIMP_SUGGESTION' })}
      subtitle={formatMessage({
        id: 'JOBS_SEARCH_AI_SWIMP_SUGGESTION_SUBTITLE',
      })}
      onClick={handleOnClick}
      condensed
    />
  );
};

type Props = { suggestion: SuggestionValues };
export const Suggestions: React.FC<Props> = ({ suggestion }) => {
  switch (suggestion) {
    case 'SWIMP':
      return <SWIMP />;
    default:
      return null;
  }
};
