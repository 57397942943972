import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';

export type SearchAlertsJobPreferencesBannerProps = {
  itemCount: number;
  trackingToken: string;
};

export type SearchAlertsJobPreferencesBannerXinglet =
  XingletInterface<SearchAlertsJobPreferencesBannerProps>;

export const SearchAlertsJobPreferencesBanner =
  JobsXingletWrapper<SearchAlertsJobPreferencesBannerProps>(
    '@xing-com/crate-jobs-domain-search-alerts-job-preferences-banner'
  );
