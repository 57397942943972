import {
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  MEDIA_SMALL,
  MEDIA_XLARGE,
  MEDIA_XSMALL,
} from '@xing-com/layout-primitives';

export const PADDING_LOGGED_OUT = 20;

const propsNames = <T extends string>(
  name: T
): {
  MEDIA_XSMALL: `${T}XSmall`;
  MEDIA_SMALL: T;
  MEDIA_MEDIUM: `${T}Medium`;
  MEDIA_LARGE: `${T}Large`;
  MEDIA_XLARGE: `${T}XLarge`;
  MEDIA_EXPANDED: `${T}XLarge`;
} => ({
  MEDIA_XSMALL: `${name}XSmall`,
  MEDIA_SMALL: name, // small is default
  MEDIA_MEDIUM: `${name}Medium`,
  MEDIA_LARGE: `${name}Large`,
  MEDIA_XLARGE: `${name}XLarge`, // not used?
  MEDIA_EXPANDED: `${name}XLarge`, // not used?
});

// hash maps for props $start and $span
export const PROPS_START = propsNames('$start');
export const PROPS_SPAN = propsNames('$span');

export const MEDIA_QUERIES = {
  MEDIA_XSMALL,
  MEDIA_SMALL,
  MEDIA_MEDIUM,
  MEDIA_LARGE,
  MEDIA_XLARGE,
} as const;

export const MEDIA_QUERY_IDS = Object.keys(MEDIA_QUERIES).filter(
  (k): k is keyof typeof MEDIA_QUERIES => true
);
