import {
  scale040,
  scale100,
  spaceXS,
  spaceS,
  spaceM,
  spaceL,
  spaceXL,
} from '@xing-com/tokens';

import type { EntityCardTypes } from './entity-card.types';

export type StyleConfigType = {
  height: string;
  heightWithoutButton?: string;
  minWidth: string;
  maxWidth: string;
  marginTop?: string;
  direction: 'column' | 'row';
  containerPadding: string;
  profileImageSize: 'medium' | 'large' | 'xlarge';
  titleSize: 'xlarge' | 'large';
  titleMarginBottom: string;
  descriptionSize?: 'small' | 'medium';
  showDescription: boolean;
  showButton: boolean;
  showCompanyLogo?: boolean;
  descriptionMarginBottom?: string;
  descriptionIconMargin?: string;
  textAlign: 'center' | 'left';
  markersHeight: string;
  markersMarginBottom: string;
  markersCollapseWhenEmpty?: boolean;
  lineClampTitle: number;
  lineClampDescription: number;
};

const baseConfigs: {
  [key: string]: StyleConfigType;
} = {
  Asmall: {
    height: '386px',
    heightWithoutButton: '342px',
    minWidth: '224px',
    maxWidth: '392px',
    direction: 'column',
    containerPadding: spaceXL,
    profileImageSize: 'large',
    titleSize: 'xlarge',
    titleMarginBottom: spaceM,
    descriptionSize: 'small',
    descriptionMarginBottom: spaceL,
    descriptionIconMargin: spaceXS,
    showDescription: true,
    showButton: true,
    textAlign: 'left',
    markersHeight: scale100,
    markersMarginBottom: spaceM,
    lineClampTitle: 1,
    lineClampDescription: 2,
  },
  Alarge: {
    height: '432px',
    heightWithoutButton: '388px',
    minWidth: '288px',
    maxWidth: '410px',
    direction: 'column',
    containerPadding: spaceXL,
    profileImageSize: 'xlarge',
    titleSize: 'large',
    titleMarginBottom: spaceM,
    showDescription: true,
    descriptionSize: 'medium',
    descriptionMarginBottom: spaceXL,
    descriptionIconMargin: spaceS,
    showButton: true,
    showCompanyLogo: true,
    textAlign: 'left',
    markersHeight: scale100,
    markersMarginBottom: spaceM,
    lineClampTitle: 1,
    lineClampDescription: 2,
  },
  B: {
    height: '310px',
    minWidth: '186px',
    maxWidth: '392px',
    direction: 'column',
    marginTop: spaceM,
    containerPadding: spaceXL,
    profileImageSize: 'large',
    titleSize: 'large',
    titleMarginBottom: scale040,
    showDescription: false,
    showButton: false,
    textAlign: 'center',
    markersHeight: scale100,
    markersMarginBottom: scale040,
    lineClampTitle: 2,
    lineClampDescription: 2,
  },
  C: {
    height: '154px',
    minWidth: '320px',
    maxWidth: '440px',
    direction: 'row',
    containerPadding: spaceL,
    profileImageSize: 'medium',
    titleSize: 'large',
    titleMarginBottom: spaceXS,
    showDescription: false,
    showButton: false,
    textAlign: 'left',
    markersHeight: scale100,
    markersMarginBottom: spaceXS,
    markersCollapseWhenEmpty: true,
    lineClampTitle: 2,
    lineClampDescription: 1,
  },
};

const variations: {
  [key: string]: StyleConfigType;
} = {
  CwithDescription: {
    ...baseConfigs['C'],
    height: '184px',
    descriptionSize: 'small',
    descriptionMarginBottom: spaceM,
    descriptionIconMargin: spaceXS,
    showDescription: true,
  },
};

export const getStyleConfig = (
  layout: NonNullable<EntityCardTypes['layout']> = 'A',
  size: EntityCardTypes['size'],
  variation?: EntityCardTypes['variation']
): StyleConfigType => {
  if (layout === 'A') {
    return size === 'small' ? baseConfigs['Asmall'] : baseConfigs['Alarge'];
  }
  if (layout === 'C' && variation === 'hasDescription') {
    return variations['CwithDescription'];
  }
  return baseConfigs[layout];
};
