import styled from 'styled-components';

import { GridContainer, GridItem } from '@xing-com/grid';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  SkeletonBodyCopy,
  SkeletonCard,
  SkeletonHeadline,
  SkeletonImage,
  SkeletonRectangle,
} from '@xing-com/skeleton';
import { spaceL, spaceS, spaceXL, spaceXS } from '@xing-com/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceL};

  @media ${mediaConfined} {
    gap: ${spaceXL};
  }
`;

export const HeadlineContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: ${spaceS};
  margin-bottom: ${spaceL};
`;

export const Headline = styled(SkeletonHeadline)`
  width: 40%;
`;

export const BodyCopy = styled(SkeletonBodyCopy)`
  width: 30%;
`;

export const Carousel = styled.div`
  display: flex;
  overflow-x: auto;
  gap: ${spaceS};
  margin-right: -20px;
  padding-right: 20px;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${mediaConfined} {
    display: none;
  }
`;

export const CarouselCard = styled(SkeletonCard)`
  min-width: calc((100% / 3) * 2);
`;

export const ConfinedContainer = styled(GridContainer)`
  display: none;

  @media ${mediaConfined} {
    display: grid;
  }
`;

export const Image = styled(SkeletonImage)`
  margin-bottom: ${spaceXS};
`;

export const ContentHeadline = styled(SkeletonHeadline)`
  margin-bottom: ${spaceS};
`;

export const ShowMoreButton = styled(SkeletonRectangle)`
  display: none;

  @media ${mediaConfined} {
    display: block;
    margin: 0 auto;
  }
`;
