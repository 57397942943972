import type {
  TrackingEvent,
  InteractionTrackingEvent,
} from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';

export type TrackingType = {
  PropTrackAction?: string;
  PropActionOrigin?: string;
  PropChannel?: string;
  pageName?: string;
};

export type PageView = {
  channel?: string;
  page: string;
  PropContextDimension1?: string;
  PropTrackAction?: string;
};

export type XingTrackType =
  | TrackingEvent<PageView>
  | InteractionTrackingEvent<TrackingType>;

export type UsePageView = {
  track: XingTrackType;
  page: string;
  PropContextDimension1?: string;
  PropTrackAction?: string;
};

export function useTrackPageView(): () => void {
  const { track } = useTracking<XingTrackType>();

  return () => {
    track({
      type: 'pageview',
      page: 'wbm/Welcome/start/index',
      channel: 'wbm/Welcome',
      PropContextDimension1: 'welcome_lo_startpage_signup_view',
    });
  };
}

export function useTrackClickStoreButton(): (action: string) => void {
  const { track } = useTracking<XingTrackType>();

  return (action: string) => {
    track({
      event: 'PropTrackAction',
      type: 'interaction',
      PropTrackAction: action,
      channel: undefined,
      PropContextDimension1: undefined,
      PropChannel: undefined,
    });
  };
}

export function useTrackSignUpGoogle(): () => void {
  const { track } = useTracking<XingTrackType>();

  return () =>
    track({
      event: 'PropTrackAction',
      pageName: 'wbm/Welcome/start/index/google',
      type: 'interaction',
      PropTrackAction: 'lp_google_click',
      channel: undefined,
      PropContextDimension1: undefined,
      PropChannel: 'wbm/Welcome',
    });
}

export function useTrackClickSerpCategory(): (categoryName: string) => void {
  const { track } = useTracking<XingTrackType>();

  return (categoryName: string) =>
    track({
      event: 'PropTrackAction',
      type: 'interaction',
      PropTrackAction: `losp_serp_category_${categoryName}_header_click`,
    });
}

// LOSP PREFERENCES EXPERIMENT

export function useTrackClickFenced(): (item: string) => void {
  const { track } = useTracking<XingTrackType>();

  return (item: string) =>
    track({
      event: 'PropTrackAction',
      type: 'interaction',
      PropTrackAction: `losp_preferences_fenced_${item}_click`,
    });
}

export function useTrackSignupExperimentClick(): () => void {
  const { track } = useTracking<XingTrackType>();

  return () =>
    track({
      event: 'PropTrackAction',
      type: 'interaction',
      PropTrackAction: `losp_preferences_modal_sign_up_button_click`,
    });
}

export function useTrackSaveClick(): (isEmpty: boolean) => void {
  const { track } = useTracking<XingTrackType>();

  return (isEmpty: boolean) =>
    track({
      event: 'PropTrackAction',
      type: 'interaction',
      PropTrackAction: 'losp_preferences_save_click',
      PropActionOrigin: isEmpty
        ? 'losp_preferences_not_selected'
        : 'losp_preferences_selected',
    });
}
