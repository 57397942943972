export const toSnakeCase = (value: string): string => {
  const match = value.match(/\S+/g);

  if (!match) {
    return value;
  }

  return match.map((word) => word.toLowerCase()).join('_');
};

export const truncate = (value: string, limit: number): string => {
  if (value.length > limit) {
    return value.slice(0, limit) + '...';
  } else {
    return value;
  }
};
