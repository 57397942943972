export const SERP_ROUTES_IDENTIFIERS = {
  discipline: 'd',
  city: 'jobs-in',
  location: 'l',
  employmentType: 'k',
  role: 't',
  company: 'c',
  skill: 'skill',
};

export const SERPS_ROUTES = {
  // Multipart
  disciplineCity: `/jobs/${SERP_ROUTES_IDENTIFIERS.discipline}-:categoryValue/${SERP_ROUTES_IDENTIFIERS.location}-:subcategoryValue`,
  disciplineRole: `/jobs/${SERP_ROUTES_IDENTIFIERS.discipline}-:categoryValue/${SERP_ROUTES_IDENTIFIERS.role}-:subcategoryValue`,
  employmentTypeCity: `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-:categoryValue/${SERP_ROUTES_IDENTIFIERS.location}-:subcategoryValue`,
  employmentTypeDiscipline: `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-:categoryValue/${SERP_ROUTES_IDENTIFIERS.discipline}-:subcategoryValue`,
  employmentTypeRole: `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-:categoryValue/${SERP_ROUTES_IDENTIFIERS.role}-:subcategoryValue`,
  roleCity: `/jobs/:categoryValue-${SERP_ROUTES_IDENTIFIERS.city}-:subcategoryValue`,
  skillCity: `/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/:categoryValue-${SERP_ROUTES_IDENTIFIERS.city}-:subcategoryValue`,

  // Singlepart
  discipline: `/jobs/${SERP_ROUTES_IDENTIFIERS.discipline}-:categoryValue`,
  city: `/jobs/${SERP_ROUTES_IDENTIFIERS.city}-:categoryValue`,
  employmentType: `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-:categoryValue`,
  role: `/jobs/${SERP_ROUTES_IDENTIFIERS.role}-:categoryValue`,
  company: `/jobs/${SERP_ROUTES_IDENTIFIERS.company}-:categoryValue`,
  skill: `/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/:categoryValue`,

  // Keep `any` in last place always as this is the "catch-all" route!
  // 'any' is treated like "role" before, as this is a "catch-all" to be backwards compatible
  any: '/jobs/:categoryValue',
} as const;

export const ROUTES = {
  findJobs: '/jobs/find',
  searchAi: '/jobs/search/ki',
  searchAiLanding: '/jobs/search/ki/start',
  myJobs: '/jobs/my-jobs',
  myJobsSearchAlerts: '/jobs/my-jobs?active_view=SEARCH_ALERTS',
  myJobsApplications: '/jobs/my-jobs?active_view=APPLICATIONS',
  jobDetails: '/jobs/:slug',
  jobContacts: '/jobs/:slug/contacts',
  search: '/jobs/search',
  searchId: '/jobs/search/:id',
  searchAlertEmailSubscribe: '/jobs/search/confirm/:token',
  searchAlertEmailUnsubscribe: '/jobs/search/unsubscribe/:token',

  // External (jobs-frontend)
  jobs: '/jobs',
  jobsDirectory: '/jobs/directory',
  jobsCompanyDirectory: '/jobs/c-directory',

  // External (Crate)
  jobseekerCriteria: '/jobseeker-criteria',
  jobseekerCriteriaSalary: '/jobseeker-criteria/salary',
  privacy: '/privacy',
  profile: '/profile',
  signup: '/signup',
  terms: '/terms',
  updateProfileWithCv: '/profile/my_profile/cv-update/edit',
};

export const XING_PRIVACY_LINK_DE =
  'https://privacy.xing.com/de/datenschutzerklaerung';

export const XING_PRIVACY_LINK_EN =
  'https://privacy.xing.com/en/privacy-policy';
