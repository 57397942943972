import type React from 'react';

import { ImageSubsection } from './image-subsection';
import { RegisterButtonsSubsection } from './register-buttons-subsection';
import { StyledSection, StyledGridContainer } from './register-section.styles';

export const RegisterSection: React.FC = () => (
  <StyledSection>
    <StyledGridContainer type="wide">
      <ImageSubsection />
      <RegisterButtonsSubsection />
    </StyledGridContainer>
  </StyledSection>
);
