import styled from 'styled-components';

import { PillSelect } from '@xing-com/crate-startpage-logged-out-pill-select';
import { Section } from '@xing-com/layout-primitives';
import { mediaConfined, mediaNavExtended } from '@xing-com/layout-tokens';
import {
  spaceXS,
  spaceL,
  space4XL,
  scale010,
  scale110,
  scale130,
  scale140,
  scale150,
  scale220,
  scale230,
  space3XL,
  scale120,
  xdlColorMarkerMatch,
  spaceXXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy, Headline, Hero } from '@xing-com/typography';

export const StyledSection = styled(Section)`
  background: ${xdlColorMarkerMatch};
  padding-top: calc(${scale010} + ${scale150});
  padding-bottom: ${space4XL};

  @media ${mediaNavExtended} {
    padding-top: calc(${spaceXS} + ${scale220});
    padding-bottom: calc(${scale230} - ${scale010});
  }
`;

export const UpperContainer = styled.div`
  margin-bottom: ${space3XL};
`;

export const Title = styled(Hero)`
  font-size: ${scale110};
  margin-bottom: ${spaceXS};

  @media ${mediaConfined} {
    font-size: ${scale130};
  }
`;

export const Subtitle = styled(Headline)`
  font-weight: 400;
  max-width: 676px;
  color: ${xdlColorTextSecondary};
`;

export const StyledOptionsContainer = styled.div`
  margin-bottom: ${space3XL};

  @media ${mediaNavExtended} {
    display: flex;
    margin-bottom: ${scale120};
  }
`;

export const StyledPillSelect = styled(PillSelect)`
  display: block;
  margin: 0;
  margin-bottom: ${scale140};

  @media ${mediaNavExtended} {
    display: inline-block;
    margin: 0;
    margin-right: ${spaceL};
  }
`;

export const DisabledPreferencesContainer = styled.div`
  margin-bottom: ${space3XL};
`;

export const HideMobile = styled.span`
  display: none;

  @media ${mediaConfined} {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const StyledIllustrationContainer = styled.div`
  margin-bottom: ${spaceXXL};
`;

export const StyledPopupHeadline = styled(Headline)`
  text-align: center;
`;

export const StyledPopupBodyCopy = styled(BodyCopy)`
  text-align: center;
`;
