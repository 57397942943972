import { IconPlus } from '@xing-com/icons';
import { BodyCopy } from '@xing-com/typography';

import {
  StyledPillContainer,
  StyledFirstPill,
  StyledLastPill,
  StyledMiddlePill,
  StyledHeader,
  StyledPill,
  HideMobile,
  HideDesktop,
} from './pill-select.styles';

type PillSelectOption = {
  copy: string;
  value: string;
};

export type PillSelectProps = {
  header: React.ReactNode;
  options: PillSelectOption[];
  value?: string;
  onChange?: (value: string) => void;
  onPlusClick?: () => void;
  className?: string;
};

export const PillSelect: React.FC<PillSelectProps> = ({
  header,
  options,
  value,
  onChange,
  onPlusClick,
  className,
}) => {
  const firstPill = options[0];
  const middlePills = options.slice(1);

  const changeValue = (newValue: string): void => {
    if (value === newValue) {
      onChange && onChange('');
    } else {
      onChange && onChange(newValue);
    }
  };

  return (
    <span className={className}>
      {/* @ts-expect-error FIXME: SC6 */}
      <StyledHeader noMargin small>
        {header}
      </StyledHeader>

      <HideMobile>
        <StyledPillContainer>
          <StyledFirstPill
            selected={value === firstPill.value}
            data-qa={value === firstPill.value ? 'selected' : ''}
            onClick={() => changeValue(firstPill.value)}
          >
            <BodyCopy noMargin>{firstPill.copy}</BodyCopy>
          </StyledFirstPill>

          {middlePills.map((option) => {
            return (
              <StyledMiddlePill
                key={option.value}
                onClick={() => changeValue(option.value)}
                selected={value === option.value}
                data-qa={value === option.value ? 'selected' : ''}
              >
                <BodyCopy noMargin>{option.copy}</BodyCopy>
              </StyledMiddlePill>
            );
          })}

          <StyledLastPill
            data-qa="plus-sign"
            onClick={() => onPlusClick && onPlusClick()}
          >
            <IconPlus />
          </StyledLastPill>
        </StyledPillContainer>
      </HideMobile>

      <HideDesktop>
        <StyledPillContainer>
          {options.map((option) => {
            return (
              <StyledPill
                key={option.value}
                onClick={() => changeValue(option.value)}
                selected={value === option.value}
                data-qa={value === option.value ? 'selected' : ''}
              >
                <BodyCopy noMargin>{option.copy}</BodyCopy>
              </StyledPill>
            );
          })}
        </StyledPillContainer>
      </HideDesktop>
    </span>
  );
};
