/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AdAuctionWinnersQueryVariables = Types.Exact<{
  auctionWinnersInput: Types.AuctionAttributes;
}>;

export type AdAuctionWinnersQuery = {
  __typename?: 'Query';
  adAuctionWinners?: {
    __typename?: 'AdAuctionWinners';
    collection: Array<
      | { __typename?: 'AuctionEventAd' }
      | {
          __typename?: 'AuctionLeadAd';
          sid: string;
          type: Types.AdTypes;
          title: string;
          description?: string | null;
          trackingToken: string;
          surn: string;
          buttonCopy: string;
          leadAdFormId: string;
          image: {
            __typename?: 'AdImage';
            large?: string | null;
            small: string;
          };
          sender: {
            __typename?: 'AdSender';
            name: string;
            link: string;
            image: string;
            urn: string;
            followersCount?: number | null;
            jobDescription?: string | null;
          };
        }
      | { __typename?: 'AuctionPageAd' }
      | { __typename?: 'AuctionPostingAd' }
      | {
          __typename?: 'AuctionVideoAd';
          sid: string;
          type: Types.AdTypes;
          surn: string;
          title: string;
          siteName: string;
          trackingToken: string;
          description?: string | null;
          targetUrl: string;
          videoId: string;
          sender: {
            __typename?: 'AdSender';
            name: string;
            link: string;
            image: string;
            urn: string;
            followersCount?: number | null;
            jobDescription?: string | null;
          };
        }
      | {
          __typename?: 'AuctionWebsiteAd';
          sid: string;
          type: Types.AdTypes;
          surn: string;
          title: string;
          trackingToken: string;
          description?: string | null;
          targetUrl: string;
          siteName: string;
          image: {
            __typename?: 'AdImage';
            large?: string | null;
            small: string;
          };
          sender: {
            __typename?: 'AdSender';
            name: string;
            link: string;
            image: string;
            urn: string;
            followersCount?: number | null;
            jobDescription?: string | null;
          };
        }
    >;
  } | null;
};

export type AdSenderFieldsFragment = {
  __typename?: 'AdSender';
  name: string;
  link: string;
  image: string;
  urn: string;
  followersCount?: number | null;
  jobDescription?: string | null;
};

export type AdImageFieldsFragment = {
  __typename?: 'AdImage';
  large?: string | null;
  small: string;
};

export type WebsiteAdFieldsFragment = {
  __typename?: 'AuctionWebsiteAd';
  sid: string;
  type: Types.AdTypes;
  surn: string;
  title: string;
  trackingToken: string;
  description?: string | null;
  targetUrl: string;
  siteName: string;
  image: { __typename?: 'AdImage'; large?: string | null; small: string };
  sender: {
    __typename?: 'AdSender';
    name: string;
    link: string;
    image: string;
    urn: string;
    followersCount?: number | null;
    jobDescription?: string | null;
  };
};

export type VideoAdFieldsFragment = {
  __typename?: 'AuctionVideoAd';
  sid: string;
  type: Types.AdTypes;
  surn: string;
  title: string;
  siteName: string;
  trackingToken: string;
  description?: string | null;
  targetUrl: string;
  videoId: string;
  sender: {
    __typename?: 'AdSender';
    name: string;
    link: string;
    image: string;
    urn: string;
    followersCount?: number | null;
    jobDescription?: string | null;
  };
};

export type LeadAdFieldsFragment = {
  __typename?: 'AuctionLeadAd';
  sid: string;
  type: Types.AdTypes;
  title: string;
  description?: string | null;
  trackingToken: string;
  surn: string;
  buttonCopy: string;
  leadAdFormId: string;
  image: { __typename?: 'AdImage'; large?: string | null; small: string };
  sender: {
    __typename?: 'AdSender';
    name: string;
    link: string;
    image: string;
    urn: string;
    followersCount?: number | null;
    jobDescription?: string | null;
  };
};

export const AdImageFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdImageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'large' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdImageFieldsFragment, unknown>;
export const AdSenderFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSenderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdSender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdSenderFieldsFragment, unknown>;
export const WebsiteAdFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebsiteAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionWebsiteAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdImageFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdImageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'large' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSenderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdSender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WebsiteAdFieldsFragment, unknown>;
export const VideoAdFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionVideoAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSenderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdSender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoAdFieldsFragment, unknown>;
export const LeadAdFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionLeadAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdImageFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonCopy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadAdFormId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdImageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'large' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSenderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdSender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDescription' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadAdFieldsFragment, unknown>;
export const AdAuctionWinnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdAuctionWinners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'auctionWinnersInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AuctionAttributes' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adAuctionWinners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'auctionAttributes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'auctionWinnersInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'collection' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'WebsiteAdFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'VideoAdFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LeadAdFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdImageFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdImage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'large' } },
          { kind: 'Field', name: { kind: 'Name', value: 'small' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdSenderFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AdSender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'followersCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobDescription' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WebsiteAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionWebsiteAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdImageFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VideoAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionVideoAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siteName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadAdFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AuctionLeadAd' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingToken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surn' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdImageFields' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdSenderFields' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'buttonCopy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadAdFormId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdAuctionWinnersQuery,
  AdAuctionWinnersQueryVariables
>;
