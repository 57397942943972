import { useCallback } from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';
import type { NWTEvent } from '@xing-com/crate-communication-tracking';

type SharedEventProps =
  | 'application'
  | 'channel'
  | 'sentBy'
  | 'userId'
  | 'type';

export const defaultNwtProps: Pick<NWTEvent, SharedEventProps> = {
  type: 'nwt',
  application: 'wbm',
  sentBy: 'jobs',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OmitFromUnion<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PickFromUnion<T, K extends keyof any> = T extends any
  ? Pick<T, Extract<keyof T, K>>
  : never;
export type NwtTrackingProps = OmitFromUnion<NWTEvent, SharedEventProps>;
type UseJobsNwtTrackingResult = (props: NwtTrackingProps) => void;

/**
 * `useJobsNwtTracking` returns a function to track a nwt event, and it already
 * fulfills some of the fields such as the userId, channel and similar
 */
export const useJobsNwtTracking = (): UseJobsNwtTrackingResult => {
  const { track } = useTracking<NWTEvent>();

  const trackNwt = useCallback(
    (nwtTrackingProps: NwtTrackingProps) => {
      track({
        ...defaultNwtProps,
        ...nwtTrackingProps,
      });
    },
    [track]
  );

  return trackNwt;
};
