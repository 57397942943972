import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { Tag } from '@xing-com/tag';
import {
  spaceS,
  xdlColorTextSecondary,
  scale030,
  xdlColorTextTertiary,
  spaceM,
  scale040,
  scale080,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const StyledHeader = styled(BodyCopy)`
  color: ${xdlColorTextSecondary};

  &&& {
    margin-bottom: ${scale030};
  }
`;

export const StyledItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${spaceS};

  @media ${mediaConfined} {
    row-gap: ${spaceM};
  }
`;

export const StyledTag = styled(Tag)`
  &&& {
    border-radius: 100px;
    color: ${xdlColorTextTertiary};
    background: #f8fcfc;
    height: 44px;
    padding: ${scale040} ${scale080};
    margin-right: ${spaceS};

    @media ${mediaConfined} {
      margin-right: ${spaceM};
    }
  }
`;
