import styled, { css } from 'styled-components';

import {
  background_01_1x1_a,
  background_01_4x3_a,
  background_01_7x2_a,
} from '@xing-com/background-images';
import {
  mediaWideNavless,
  mediaXWide,
  mediaTiny,
  mediaConfined,
} from '@xing-com/layout-tokens';
import { cornerRadiusXL, scale200 } from '@xing-com/tokens';

export const OVERLAP_MOBILE = cornerRadiusXL;
export const OVERLAP_DESKTOP = scale200;

export const Background = styled.section<{ $spacious: boolean }>`
  background-image: url(${background_01_7x2_a});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 15%;

  @media ${mediaTiny} {
    background-image: url(${background_01_1x1_a});
    background-position-y: 77%;
  }

  @media ${mediaWideNavless} {
    background-size: 110%;
    background-position-x: 55%;
    padding-bottom: ${OVERLAP_MOBILE};
  }

  @media ${mediaXWide} {
    padding-bottom: ${OVERLAP_DESKTOP};
  }

  ${({ $spacious }) =>
    $spacious &&
    css`
      background-image: url(${background_01_4x3_a});

      @media ${mediaConfined} {
        padding-top: ${scale200};
      }

      @media ${mediaWideNavless} {
        background-image: url(${background_01_7x2_a});
        background-size: cover;
        padding-bottom: 0;
      }
    `};
`;
