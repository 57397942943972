import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceS,
  spaceXS,
  xdlColorHover,
  cornerRadiusL,
  xdlColorTextSecondary,
  xdlColorText,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Suggestion = styled.button<{ $condensed?: boolean }>`
  background: transparent;
  border-radius: ${cornerRadiusL};
  border: none;
  color: ${xdlColorText};
  cursor: pointer;
  display: flex;
  gap: 10px;
  outline: none;
  padding: ${({ $condensed }) => ($condensed ? 0 : `${spaceM} ${spaceS}`)};
  text-align: left;
  width: 100%;

  &:active,
  &:focus,
  &:hover {
    background-color: ${({ $condensed }) =>
      $condensed ? 'transparent' : xdlColorHover};
  }

  @media ${mediaConfined} {
    &:hover {
      background-color: ${xdlColorHover};
    }
  }

  & > * {
    pointer-events: none;
  }
`;

export const SuggestionText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SuggestionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceXS};
`;

export const SuggestionSubtitle = styled(BodyCopy)`
  color: ${xdlColorTextSecondary};
`;
