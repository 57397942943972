import type { ComponentProps, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import {
  CONTAINER_NARROW,
  Container,
  GUTTER,
  MEDIA_MEDIUM,
  NUM_COLS,
  MAIN_GAP,
  ASIDE_MIN,
  type ContainerVariant,
} from '@xing-com/layout-primitives';

import { MEDIA_QUERIES } from './legacy-constants';
import {
  LegacyGridContainer,
  type Variant as LegacyVariant,
} from './legacy-containers';
import { ScrollableSticky } from './scrollable-sticky';

export type Variant =
  | LegacyVariant // deprecated
  | ContainerVariant
  | 'two-col'; // new notation

export const GridMain: FC<
  PropsWithChildren<ComponentProps<typeof StyledGridMain>>
> = ({ children, ...props }) => {
  return (
    <StyledGridMain {...props}>
      <Grid>{children}</Grid>
    </StyledGridMain>
  );
};

export const GridAside: FC<
  { sticky?: boolean } & ComponentProps<typeof StyledGridAside>
> = ({ sticky, children, ...props }) => {
  return sticky ? (
    <StyledGridAside {...props}>
      <ScrollableSticky>{children}</ScrollableSticky>
    </StyledGridAside>
  ) : (
    <StyledGridAside {...props}>{children}</StyledGridAside>
  );
};

const isLegacyVariant = (variant: Variant): variant is LegacyVariant =>
  ['Narrow', 'TwoCol', 'FullSize', 'LoggedOut'].includes(variant);

export const GridContainer: React.FC<
  React.PropsWithChildren<{
    variant?: Variant;
  }>
> = ({ children, variant = 'FullSize', ...props }) => {
  // FullSize stays the standard for now

  // for now, we keep the old names and refer to them as "Legacy Containers"
  // for compatibility reasons
  if (isLegacyVariant(variant)) {
    return (
      <LegacyGridContainer variant={variant} {...props}>
        {children}
      </LegacyGridContainer>
    );
  }

  let containerVariant: ContainerVariant;
  switch (variant) {
    case 'expanded':
      containerVariant = 'expanded';
      break;
    case 'wide':
      containerVariant = 'wide';
      break;
    case 'narrow':
      containerVariant = 'narrow';
      break;
    default: // two-col and regular
      containerVariant = 'regular';
  }

  return (
    <Container variant={containerVariant} {...props}>
      {variant === 'two-col' ? (
        <GridTwoCol>{children}</GridTwoCol>
      ) : (
        <Grid>{children}</Grid>
      )}
    </Container>
  );
};

const Grid = styled.div`
  display: grid;
  gap: ${GUTTER};
  grid-template-columns: repeat(${NUM_COLS}, 1fr);
  width: 100%;
  margin: 0 auto;
`;

const GridTwoCol = styled.div`
  display: block;
  gap: ${GUTTER};
  grid-template-rows: auto;
  // note fr declaration nr needs to match
  // template areas nr of declarations
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'main main aside';
  width: 100%;
  ${MEDIA_MEDIUM} {
    display: grid;
  }
`;

const StyledGridAside = styled.aside`
  display: none;
  min-width: ${ASIDE_MIN}px;
  grid-area: aside;
  grid-column: 3 / span 1;
  ${MEDIA_QUERIES.MEDIA_MEDIUM} {
    display: block;
  }
`;

const StyledGridMain = styled.section`
  grid-area: main;
  grid-column: 1 / span 2;
  max-width: ${CONTAINER_NARROW}px;
  margin: 0 auto;
  box-sizing: content-box;
  ${MEDIA_MEDIUM} {
    padding-right: calc(${MAIN_GAP}px - ${GUTTER});
  }
`;
