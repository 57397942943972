import { useQuery } from '@apollo/client';
import type React from 'react';

import * as Types from '@xing-com/crate-common-graphql-types';
import { ActionButtons } from '@xing-com/crate-jobs-components-job-teaser-options';
import type {
  JobTeaserVisibleJobFragment,
  JobMatchingHighlightsV2Fragment,
} from '@xing-com/crate-jobs-graphql';
import { GetSimilarJobByUserIdDocument } from '@xing-com/crate-jobs-graphql';
import {
  useBreakpoints,
  useJobBookmarkToggle,
} from '@xing-com/crate-jobs-hooks';
import { getMatchingHighlightsFlags } from '@xing-com/crate-jobs-tracking';
import { XingletLoader } from '@xing-com/crate-xinglet';

import { CardSkeleton } from './card.styled';

type CardProps = {
  job: JobTeaserVisibleJobFragment;
  matchingHighlights?: Types.Maybe<JobMatchingHighlightsV2Fragment>;
};

export const Card: React.FC<CardProps> = ({ job, matchingHighlights }) => {
  const { isMediaSmallOrTiny } = useBreakpoints();

  const { data } = useQuery(GetSimilarJobByUserIdDocument, {
    variables: { jobId: job.id },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
  });

  const bookmarkState =
    data?.job?.__typename === 'VisibleJob' &&
    data.job.userInteractions?.bookmark?.state;

  const isBookmarked = bookmarkState
    ? bookmarkState !== Types.JobBookmarkState.NotBookmarked
    : false;

  const nwtTrackingOptions = {
    element: 'job_posting',
    elementDetail: 'losp_job_section',
    eventSchema: 'searchresult',
    flags: getMatchingHighlightsFlags(matchingHighlights),
    itemUrn: job.globalId,
    page: 'index',
  } as const;

  const onBookmark = useJobBookmarkToggle({
    jobId: job.id,
    isBookmarked,
    nwtTrackingOptions,
  });

  const params = new URLSearchParams({
    sc_o: 'jobs_losp_trending_jobs_recommendations-jdp',
    sc_o_PropActionOrigin: 'jobs_losp',
  }).toString();

  const url = `/jobs/${job.slug}?${params}`;

  return (
    <XingletLoader
      name="@xing-com/crate-jobs-components-job-teaser-card"
      fallback={<CardSkeleton />}
      job={job}
      matchingHighlights={matchingHighlights}
      url={url}
      headingLevel="h3"
      showRatingMarker
      data-cy="similar-job-recommendation-card"
      actions={
        <ActionButtons
          iconsOnly={isMediaSmallOrTiny}
          size={isMediaSmallOrTiny ? 'medium' : 'small'}
          isBookmarked={isBookmarked}
          onBookmark={onBookmark}
          bottom={!isMediaSmallOrTiny}
          noMargin
        />
      }
    />
  );
};
