import styled from 'styled-components';

import { Card } from '@xing-com/card';
import {
  mediaConfined,
  mediaWide,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  xdlPaletteGrayCool01,
  scale120,
  scale130,
  scale140,
  scale150,
  scale160,
  cornerRadiusXL,
  xdlColorTextOnLight,
} from '@xing-com/tokens';

export const StyledAnchor = styled.a`
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${xdlColorTextOnLight};
  }

  @media ${mediaConfined} {
    flex: 1;
  }
`;

export const StyledCard = styled(Card)`
  border-radius: ${cornerRadiusXL};
  background: ${xdlPaletteGrayCool01};
  border: 0;

  height: 210px;
  padding: ${scale120} ${scale130} ${scale140};
  display: flex;

  & > div {
    justify-content: space-between;
    height: 100%;
  }

  @media ${mediaConfined} {
    height: 340px;
    padding: ${scale150} ${scale130} ${scale160};
  }

  @media ${mediaWide} {
    height: 300px;
  }

  @media ${mediaWideNavless} {
    height: 224px;
    padding: ${scale150} ${scale130} ${scale160};
  }
`;
