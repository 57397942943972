import React from 'react';
import styled from 'styled-components';

import { IconWarning } from '@xing-com/icons';
import { SkeletonBodyCopy } from '@xing-com/skeleton';

import { useAd } from '../hooks/use-ad';
import type { AdFields } from '../types';
import { SiteSections } from '../types';
import ads from './ads';
import { getAdComponent } from './section';

interface Props {
  adId: number;
}

const Center = styled.div`
  text-align: center;
`;

const Section: React.FC<Props> = ({ adId }: Props) => {
  const { data, loading, error } = useAd(adId);

  if (loading) {
    return <SkeletonBodyCopy amountRows={4} />;
  } else if (error || !data?.adAuctionWinnerAdPreview) {
    return (
      <Center>
        <IconWarning width={64} height={64} />
      </Center>
    );
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const adData = data.adAuctionWinnerAdPreview as AdFields;
  const Ad = getAdComponent(ads[SiteSections.DiscoUpdates], adData.type);

  return (
    <div data-qa={SiteSections.DiscoUpdates}>
      <React.Suspense fallback={<SkeletonBodyCopy amountRows={4} />}>
        <Ad {...adData} />
      </React.Suspense>
    </div>
  );
};

export default Section;
