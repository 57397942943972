import * as React from 'react';
import { FormattedMessage, FormattedNumber, FormattedPlural } from 'react-intl';
import styled from 'styled-components';

import { spaceXS } from '@xing-com/tokens';

import type { AdSenderFieldsFragment } from '../auction-ads.gql-types';

const SubheaderEntity = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledSpacer = styled.span`
  padding: 0 ${spaceXS};
`;

export const getFollowersSubline = (
  followersCount: number | undefined
): JSX.Element => {
  if (followersCount && followersCount > 0) {
    return (
      <React.Fragment key={`followersCount${followersCount}`}>
        <FormattedNumber
          key={`followersNumber${followersCount}`}
          value={followersCount}
          style="decimal"
        />{' '}
        <FormattedPlural
          key={followersCount}
          value={followersCount}
          one={<FormattedMessage id="DISCO_OBJECTS_FOLLOWER.one" />}
          other={<FormattedMessage id="DISCO_OBJECTS_FOLLOWER.other" />}
        />
      </React.Fragment>
    );
  }
  return <></>;
};

export const getDISCOUserActorSubline = (
  sender: AdSenderFieldsFragment
): JSX.Element | null => {
  const sublineFragments: (JSX.Element | string)[] = [];
  const followers = sender.followersCount ?? '';
  const jobDescription = sender.jobDescription ?? '';
  const spacer = <StyledSpacer key="subHeadlineSpacer">•</StyledSpacer>;
  const sponsoredLabel = (
    <FormattedMessage key="sponsored-label" id={'NATIVE_ADS_SPONSORED_LABEL'} />
  );

  if (jobDescription) {
    sublineFragments.push(
      <SubheaderEntity key="sub-header">{jobDescription}</SubheaderEntity>,
      <span key="sponsored-label-wrapper">{sponsoredLabel}</span>
    );
  }

  if (followers) {
    sublineFragments.push(getFollowersSubline(followers), sponsoredLabel);
  }

  if (sublineFragments.length >= 2) {
    sublineFragments.splice(1, 0, spacer);
  }

  return sublineFragments.length ? (
    <React.Fragment>{sublineFragments}</React.Fragment>
  ) : (
    sponsoredLabel
  );
};

export const getSUPIUserActorSubline = (
  sender: AdSenderFieldsFragment
): JSX.Element | null => {
  const sublineFragments: (JSX.Element | string)[] = [];
  const senderName = sender.name ?? '';
  const spacer = <StyledSpacer key="subHeadlineSpacer">•</StyledSpacer>;
  const sponsoredLabel = (
    <FormattedMessage key="sponsored-label" id={'NATIVE_ADS_SPONSORED_LABEL'} />
  );

  if (senderName) {
    sublineFragments.push(
      <SubheaderEntity key="sub-header">{senderName}</SubheaderEntity>,
      <span key="sponsored-label-wrapper">{sponsoredLabel}</span>
    );
  }

  if (sublineFragments.length >= 2) {
    sublineFragments.splice(1, 0, spacer);
  }

  return sublineFragments.length ? (
    <React.Fragment>{sublineFragments}</React.Fragment>
  ) : (
    sponsoredLabel
  );
};
