import { useNavigate } from '@reach/router';
import { useState } from 'react';
import styled from 'styled-components';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { AiSearchBanner } from '@xing-com/crate-jobs-domain-search-ai-search-banner';
import { SearchBox } from '@xing-com/crate-jobs-domain-search-components-search-box';
import { useSearchLocation } from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import { useExperiment } from '@xing-com/hub';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceM } from '@xing-com/tokens';

import {
  Background,
  Box,
  FakeInput,
  SearchboxStartpageMetadata,
  Filters,
  Teaser,
  Title,
  Keywords,
} from './components';

type Props = {
  withTeaser?: boolean;
  withRecentSearches?: boolean;
  withFilters?: boolean;
  spacious?: boolean;
  autofillBusinessCity?: boolean;
  isStartPage?: boolean;
  withKeywords?: boolean;
};

const FULL_REMOTE_FILTER_VALUE = 'FULL_REMOTE.050e26';
const PART_TIME_FILTER_VALUE = 'PART_TIME.58889d';
const FLEXITIME_FILTER_VALUE = '1.795d28';

const StyledBanner = styled(AiSearchBanner)`
  margin: 0 auto ${spaceM};

  @media ${mediaConfined} {
    display: block;
    width: fit-content;
  }
`;

export const FindJobsSearchBar: React.FC<Props> = ({
  withTeaser = false,
  withRecentSearches = false,
  withFilters = false,
  spacious = false,
  autofillBusinessCity = false,
  isStartPage,
  withKeywords = false,
}) => {
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState('');
  const {
    location,
    radius,
    cityId,
    onChangeLocation,
    onSelectLocation,
    onSelectRadius,
  } = useSearchLocation({ skipBusinessCityQuery: !autofillBusinessCity });

  const { isLoggedOut } = useLoginState();
  const isAiSearchForced = useExperiment('ABACUS-468') === 'B';

  const trackingParameter = isStartPage
    ? '?sc_o=losp_jobs_search_button_click&sc_o_PropActionOrigin=losp_job_search'
    : '?sc_o=jobs_search_button';

  // Extra filters
  const [isFullRemote, setIsFullRemote] = useState(false);
  const [isPartTime, setIsPartTime] = useState(false);
  const [isFlexitime, setIsFlexitime] = useState(false);

  const onSubmit = (): void => {
    const params = new URLSearchParams(trackingParameter);

    if (keywords) {
      params.set('keywords', keywords);
    }
    if (location) {
      params.set('location', location);
    }
    if (cityId) {
      params.set('cityId', cityId);
    }
    if (location && radius) {
      params.set('radius', radius.toString());
    }

    // Extra filters
    if (isFullRemote) {
      params.set('remoteOption', FULL_REMOTE_FILTER_VALUE);
    }
    if (isPartTime) {
      params.set('employmentType', PART_TIME_FILTER_VALUE);
    }
    if (isFlexitime) {
      params.set('benefit', FLEXITIME_FILTER_VALUE);
    }

    navigate(`${ROUTES.search}?${params.toString()}`);
  };

  const filterProps = {
    isFullRemote,
    setIsFullRemote,
    isPartTime,
    setIsPartTime,
    isFlexitime,
    setIsFlexitime,
  };

  const showBanner = !isAiSearchForced && !isLoggedOut;

  return (
    <Background $spacious={spacious}>
      {isStartPage ? <SearchboxStartpageMetadata /> : null}
      <Box>
        {showBanner ? <StyledBanner propActionOrigin="jobs_find_jobs" /> : null}
        {withTeaser ? <Teaser withBanner={showBanner} /> : null}
        <Title spacious={spacious} isAiSearchForced={isAiSearchForced} />
        {isAiSearchForced ? (
          <FakeInput />
        ) : (
          <SearchBox
            keywordsInput={keywords}
            locationInput={location}
            radiusInput={radius}
            onChangeKeywords={setKeywords}
            onSelectKeywords={setKeywords}
            onChangeLocation={onChangeLocation}
            onSelectLocation={onSelectLocation}
            onSelectRadius={onSelectRadius}
            onSubmit={onSubmit}
            withRecentSearches={withRecentSearches}
            withFilters={withFilters}
            landingFilters={<Filters {...filterProps} />}
          />
        )}
      </Box>
      {withKeywords ? <Keywords /> : null}
    </Background>
  );
};
