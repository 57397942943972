import { IconIndustries, IconJobs, IconSkills } from '@xing-com/icons';

import type { PreferencesData } from './types';

export const preferencesData: PreferencesData = {
  options: [
    {
      preference: 'preferred_locations',
      header: 'LOSP_PREFERENCES_EXPERIMENT_CITIES_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_CITIES_HAMBURG: '2911298.ce939c',
        LOSP_PREFERENCES_EXPERIMENT_CITIES_BERLIN: '2950159.e2912c',
        LOSP_PREFERENCES_EXPERIMENT_CITIES_MUNICH: '2867714.724c7f',
      },
    },
    {
      preference: 'career_level',
      header: 'LOSP_PREFERENCES_EXPERIMENT_LEVEL_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_ENTRY: '2.24d1f6',
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_PROFESSIONAL: '3.2ebf16',
        LOSP_PREFERENCES_EXPERIMENT_LEVEL_MANAGER: '4.83b992',
      },
    },
    {
      preference: 'salary_expectations',
      header: 'LOSP_PREFERENCES_EXPERIMENT_SALARY_HEADLINE',
      possibleValues: {
        LOSP_PREFERENCES_EXPERIMENT_SALARY_FORTY: '40000.944a62',
        LOSP_PREFERENCES_EXPERIMENT_SALARY_SIXTY: '60000.6661f8',
        LOSP_PREFERENCES_EXPERIMENT_SALARY_EIGHTY: '80000.bb326d',
      },
    },
  ],
  disabled: {
    header: 'LOSP_PREFERENCES_EXPERIMENT_OTHERS_HEADLINE',
    items: [
      {
        icon: IconJobs,
        copy: 'LOSP_PREFERENCES_EXPERIMENT_OTHERS_JOBTITLE',
        id: 'ideal_positions',
      },
      {
        icon: IconSkills,
        copy: 'LOSP_PREFERENCES_EXPERIMENT_OTHERS_DISCIPLINES',
        id: 'preferred_discipline',
      },
      {
        icon: IconIndustries,
        copy: 'LOSP_PREFERENCES_EXPERIMENT_OTHERS_INDUSTRIES',
        id: 'preferred_industry',
      },
    ],
    more: 3,
  },
};
