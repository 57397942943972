import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { Tag } from '@xing-com/tag';
import {
  spaceXS,
  spaceL,
  spaceXL,
  xdlColorTextOnLight,
  xdlColorHoverSecondary,
} from '@xing-com/tokens';

export const StyledKeywords = styled.div<{
  $keywordsPrepared: boolean;
  $isTouchDevice: boolean;
}>`
  display: flex;
  white-space: nowrap;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 calc(${spaceL} - ${spaceXS}) ${spaceXL};
  overflow-x: hidden;
  scrollbar-width: none;
  ${({ $keywordsPrepared }) => !$keywordsPrepared && 'visibility: hidden;'}
  ${({ $isTouchDevice }) => $isTouchDevice && 'overflow-x: auto;'}
  
  @media ${mediaConfined} {
    flex-wrap: nowrap;
    padding: ${spaceXL} calc(${spaceL} - ${spaceXS});
  }
`;

export const StyledLink = styled(Link)<{
  $isDragging: boolean;
}>`
  display: inline-flex;
  margin: ${spaceXS};
  color: ${xdlColorTextOnLight};
  ${({ $isDragging }) => $isDragging && 'pointer-events: none;'}

  &:hover {
    text-decoration: none;
  }
`;

export const StyledTag = styled(Tag)`
  user-select: none;

  &:hover {
    background-color: ${xdlColorHoverSecondary};
    border-color: ${xdlColorHoverSecondary};
    color: ${xdlColorTextOnLight};
  }
`;
