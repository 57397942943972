import styled, { css } from 'styled-components';

import { AiSearchBanner } from '@xing-com/crate-jobs-domain-search-ai-search-banner';
import { Section } from '@xing-com/layout-primitives';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  spaceM,
  space4XL,
  scale010,
  scale020,
  scale150,
  scale220,
  scale230,
  xdlColorBackground,
} from '@xing-com/tokens';

export const Container = styled.div`
  display: block;
`;

export const Wrapper = styled.div`
  padding: 0 ${spaceM};

  @media ${mediaConfined} {
    padding: 0;
  }
`;

export const StyledCarouselSection = styled(Section)<{ $bgColor?: string }>`
  ${({ $bgColor }) => css`
    background: ${$bgColor || xdlColorBackground};
  `}

  padding-top: calc(${scale010} + ${scale150});
  padding-bottom: ${space4XL};

  @media ${mediaConfined} {
    padding-top: calc(${scale020} + ${scale220});
    padding-bottom: calc(${scale230} - ${scale010});
  }
`;

export const StyledAiSearchBanner = styled(AiSearchBanner)`
  margin: 0 auto ${spaceM};

  @media ${mediaConfined} {
    position: absolute;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;
