import { JobBookmarkState } from '@xing-com/crate-common-graphql-types';
import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';

import type { NwtTrackingProps, OmitFromUnion } from './use-jobs-nwt-tracking';
import { useJobsNwtTracking } from './use-jobs-nwt-tracking';

type TrackingProps = {
  PropInteractionType: string;
  EventBookmark?: string;
  EventUnbookmark?: string;
  PropContextDimension1?: string;
};

type UseBookmarkTrackingResult = {
  trackNwtJobsBookmark: (isBookmarked: boolean) => void;
  trackJobsBookmark: (
    isBookmarked: boolean,
    currentState?: JobBookmarkState,
    recommendationType?: string
  ) => void;
};

export const useBookmarkTracking = (
  nwtTrackingOptions: OmitFromUnion<NwtTrackingProps, 'event'>,
  adobeTrackingOptions?: Record<string, string | number>
): UseBookmarkTrackingResult => {
  const { track } = useTracking<InteractionTrackingEvent<TrackingProps>>();
  const nwtTrack = useJobsNwtTracking();

  const trackNwtJobsBookmark: UseBookmarkTrackingResult['trackNwtJobsBookmark'] =
    (isBookmarked) => {
      nwtTrack({
        ...nwtTrackingOptions,
        event: isBookmarked ? 'unbookmarked' : 'bookmarked',
      });
    };

  const trackJobsBookmark: UseBookmarkTrackingResult['trackJobsBookmark'] = (
    isBookmarked,
    currentState,
    recommendationType
  ) => {
    const eventName = isBookmarked ? 'EventUnbookmark' : 'EventBookmark';
    const interactionType = isBookmarked ? 'jobs_unbookmark' : 'jobs_bookmark';
    const contextDimension =
      currentState && currentState !== JobBookmarkState.Saved
        ? { PropContextDimension1: `job_status_${currentState.toLowerCase()}` }
        : recommendationType
          ? {
              PropContextDimension1: `jobs_recommendations_${recommendationType}`,
            }
          : {};

    track({
      type: 'interaction',
      event: eventName,
      PropInteractionType: interactionType,
      [eventName]: '1',
      ...contextDimension,
      ...adobeTrackingOptions,
    });
  };

  return {
    trackNwtJobsBookmark,
    trackJobsBookmark,
  };
};
