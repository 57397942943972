import styled, { css } from 'styled-components';

import { Button as InnerButton } from '@xing-com/button';
import { Card as InnerCard } from '@xing-com/card';
import { lineClamp } from '@xing-com/crate-utils-line-clamp';
import { Link } from '@xing-com/link';
import { SuperEllipse } from '@xing-com/super-ellipse';
import { spaceXS, spaceS, spaceL, spaceXL, scale150 } from '@xing-com/tokens';
import { Headline, BodyCopy, Meta } from '@xing-com/typography';

import { type StyleConfigType } from './variations-config';

export const Card = styled(InnerCard)<{
  $styleConfig: StyleConfigType;
  $width?: string;
  $hasButton?: boolean;
}>`
  ${({ $styleConfig, $hasButton }) => css`
    min-width: ${$styleConfig.minWidth};
    max-width: ${$styleConfig.maxWidth};
    height: ${$hasButton
      ? $styleConfig.height
      : $styleConfig.heightWithoutButton || $styleConfig.height};
    flex-direction: ${$styleConfig.direction};
    padding: ${$styleConfig.containerPadding};
  `};
  ${({ $width }) => css`
    width: ${$width ?? '100%'};
  `};

  & > div {
    justify-content: space-between;
    flex: 1;
  }
`;

export const MainArea = styled.div<{
  $styleConfig: StyleConfigType;
}>`
  ${({ $styleConfig }) => css`
    display: flex;
    flex-direction: ${$styleConfig.direction};
  `};
`;

export const TitleArea = styled.div<{
  $styleConfig: StyleConfigType;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  ${({ $styleConfig }) => css`
    text-align: ${$styleConfig.textAlign};
    align-items: ${$styleConfig.textAlign};
    margin-left: ${$styleConfig.direction === 'row' && spaceXL};
  `}
`;

export const ProfileLink = styled(Link)<{
  $styleConfig: StyleConfigType;
}>`
  display: flex;
  margin: 0 auto;
  margin-bottom: ${spaceXL};
  ${({ $styleConfig }) =>
    $styleConfig.marginTop &&
    css`
      margin-top: ${$styleConfig.marginTop};
    `}
`;

export const MarkersContainer = styled.div<{
  $styleConfig: StyleConfigType;
  $hidden?: boolean;
}>`
  flex-wrap: wrap;
  flex-shrink: 0;
  max-width: 100%;
  overflow: hidden;
  gap: ${spaceXS};

  ${({ $styleConfig, $hidden }) => css`
    display: ${$hidden ? 'none' : 'flex'};
    justify-content: ${$styleConfig.textAlign};
    height: ${$styleConfig.markersHeight};
    margin-bottom: ${$styleConfig.markersMarginBottom};
  `}
`;

export const Title = styled(Headline)<{
  $styleConfig: StyleConfigType;
}>`
  ${({ $styleConfig }) => lineClamp($styleConfig.lineClampTitle)}
  ${({ $styleConfig }) => css`
    margin-bottom: ${$styleConfig.titleMarginBottom};
    word-break: ${$styleConfig.lineClampTitle === 1
      ? 'break-all'
      : 'break-word'};
  `}
`;

export const SubtitleContainer = styled.div<{
  $styleConfig: StyleConfigType;
}>`
  min-height: ${scale150};
  display: flex;
  align-items: center;
  ${({ $styleConfig }) =>
    $styleConfig.showDescription &&
    css`
      margin-bottom: ${$styleConfig.descriptionMarginBottom};
    `};
`;

export const Subtitle = styled(BodyCopy)`
  margin: 0;
  word-break: break-all;
  ${lineClamp(1)};
`;

export const CompanyLogo = styled(SuperEllipse)`
  margin-right: ${spaceS};
  flex-shrink: 0;
`;

export const DescriptionContainer = styled.div<{
  $styleConfig: StyleConfigType;
}>`
  display: flex;
  flex-grow: 1;

  ${({ $styleConfig }) =>
    $styleConfig.lineClampDescription === 1 &&
    css`
      line-height: 24px;
      height: 24px;
      flex-grow: 0;
      align-items: flex-end;
    `}
`;

export const DescriptionIconContainer = styled.div<{
  $styleConfig: StyleConfigType;
}>`
  height: 100%;
  ${({ $styleConfig }) =>
    $styleConfig.descriptionIconMargin &&
    css`
      margin-right: ${$styleConfig.descriptionIconMargin};
    `};
`;
export const Description = styled(Meta)<{
  $styleConfig: StyleConfigType;
}>`
  ${({ $styleConfig }) => lineClamp($styleConfig.lineClampDescription)}
  ${({ $styleConfig }) => css`
    word-break: ${$styleConfig.lineClampDescription === 1
      ? 'break-all'
      : 'break-word'};
  `}
  line-height: inherit;
  margin-left: ${spaceS};
  flex: 1;
`;

export const Button = styled(InnerButton)`
  margin: 0 auto;
  display: block;
  margin-top: ${spaceL};
  & > div {
    height: 100%;
  }
`;
