/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconLocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconLocationPin"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3a7 7 0 0 1 7 7c0 2.538-2.097 6.034-6.256 10.668l-.744.83-.744-.83C7.098 16.034 5 12.538 5 10a7 7 0 0 1 7-7m0 2a5 5 0 0 0-5 5c0 1.755 1.657 4.633 5 8.489 3.343-3.856 5-6.735 5-8.489a5 5 0 0 0-5-5m0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6m0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
    />
  </svg>
);
export default IconLocationPin;
