import styled from 'styled-components';

import { LGapCol, MGapCol } from '@xing-com/crate-jobs-components-columns';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  SkeletonBody,
  SkeletonButton,
  SkeletonHeadline,
  SkeletonPillShape,
} from '@xing-com/skeleton';
import {
  scale070,
  scale090,
  scale130,
  scale150,
  scale200,
  scale220,
  scale240,
  scale250,
  scale330,
  scale350,
  spaceL,
  spaceM,
  spaceXL,
  spaceXXL,
  xdlColorBorderSoft,
} from '@xing-com/tokens';

const NUMBER_OF_LIST_ITEMS = 5;

export const SettingsButton = styled(SkeletonButton)`
  height: ${scale130};
  max-width: ${scale250};

  @media ${mediaConfined} {
    max-width: ${scale330};
    margin-bottom: ${spaceL};
    height: ${scale150};
  }
`;

export const ListItemContainer = styled.ul`
  list-style: none;
  margin: 0 0 ${spaceXXL};
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  border-bottom: 1px solid ${xdlColorBorderSoft};
  padding: ${spaceXL} 0;
`;

export const LeftColumn = styled.div`
  width: 100%;
`;

export const BodyHeadline = styled(SkeletonHeadline)`
  & > div {
    height: ${scale090};
    margin-bottom: ${spaceM};
    width: ${scale350};
  }
`;

export const Body = styled(SkeletonBody)`
  & > div {
    width: ${scale250};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: ${spaceXXL};
`;

export const Link = styled(SkeletonPillShape)`
  height: ${scale070};
  width: ${scale220};

  &:last-child {
    width: ${scale200};
  }
`;

export const JobsCount = styled(SkeletonBody)`
  width: ${scale240};

  & > div {
    height: ${scale090};
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceL};
  margin-left: ${scale200};
  align-items: flex-end;
`;

export const SearchAlertsListSkeleton: React.FC = () => {
  return (
    <div data-testid="search-alerts-list-skeleton">
      <SettingsButton size="medium" />
      <ListItemContainer>
        {[...Array(NUMBER_OF_LIST_ITEMS)].map((_, i) => (
          <ListItem key={i}>
            <LeftColumn>
              <LGapCol>
                <MGapCol>
                  <BodyHeadline amountRows={1} />
                  <Body amountRows={1} />
                </MGapCol>
                <LinkContainer>
                  <Link />
                  <Link />
                </LinkContainer>
              </LGapCol>
            </LeftColumn>
            <RightColumn>
              <JobsCount amountRows={1} />
            </RightColumn>
          </ListItem>
        ))}
      </ListItemContainer>
    </div>
  );
};
