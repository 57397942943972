import styled from 'styled-components';

import { SGapCol } from '@xing-com/crate-jobs-components-columns';
import {
  SkeletonCard,
  SkeletonProfileImage,
  SkeletonPillShape,
  SkeletonHeadline,
  SkeletonBody,
} from '@xing-com/skeleton';
import { scale090, spaceM, spaceS, spaceXS } from '@xing-com/tokens';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spaceM};
`;

const BodyHeadline = styled(SkeletonHeadline)`
  & > div {
    height: ${scale090};
    margin-bottom: ${spaceS};
  }
`;

const Body = styled(SkeletonBody)`
  & > div {
    width: 66%;
  }
`;

const PillContainer = styled.div`
  display: flex;
  gap: ${spaceXS};
  flex-wrap: wrap;
`;

type Props = {
  className?: string;
};

export const JobTeaserCardSkeleton: React.FC<Props> = ({ className }) => (
  <SkeletonCard data-testid="job-teaser-card-skeleton" className={className}>
    <Header>
      <SkeletonProfileImage size="xsmall" sizeConfined="small" />
      <SkeletonPillShape height="20px" width="33%" />
    </Header>
    <SGapCol>
      <BodyHeadline amountRows={2} />
      <Body amountRows={1} />
      <Body amountRows={1} />
      <PillContainer>
        <SkeletonPillShape height="20px" width="50%" />
        <SkeletonPillShape height="20px" width="33%" />
        <SkeletonPillShape height="20px" width="33%" />
      </PillContainer>
    </SGapCol>
  </SkeletonCard>
);
