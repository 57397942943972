import type { JobMatchingHighlightsV2Fragment } from '@xing-com/crate-jobs-graphql';
import { toSnakeCase } from '@xing-com/crate-jobs-helpers';

export const getMatchingHighlightsFlags = (
  matchingHighlights?: JobMatchingHighlightsV2Fragment | null
): string | undefined => {
  const { highlight, matchingFacts, nonMatchingFacts } =
    matchingHighlights || {};

  const flags = [];

  // Highlight
  const { type, localization } = highlight || {};
  if (type) {
    flags.push(type);
  }
  if (localization?.localizationValue) {
    flags.push(
      `job_highlight_translation_${toSnakeCase(localization.localizationValue)}`
    );
  }

  // Non matching facts
  nonMatchingFacts?.forEach((fact) => {
    const { type, localization } = fact;
    if (type && localization?.localizationValue) {
      flags.push(
        `${type}_non_matching_${toSnakeCase(localization.localizationValue)}`
      );
    }
  });

  // Matching facts
  matchingFacts?.forEach((fact) => {
    const { type, localization } = fact;
    if (type && localization?.localizationValue) {
      flags.push(
        `${type}_matching_${toSnakeCase(localization.localizationValue)}`
      );
    }
  });

  return flags.join(',') || undefined;
};
