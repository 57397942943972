export const trendingJobsStaticData = {
  queryTime: 1718698220253,
  data: [
    {
      id: 120031000,
      url: 'https://www.xing.com/jobs/berlin-level-1-3-support-weg-hausverwaltung-fachbezogenen-teams-buero-homeoffice-deutschlandweit-121243301',
      country: 'DE',
      hotnessScore: 97,
    },
    {
      id: 120073947,
      url: 'https://www.xing.com/jobs/hildesheim-berater-integration-121125646',
      country: 'DE',
      hotnessScore: 15.830357142857142,
    },
    {
      id: 3,
      url: 'https://www.xing.com/jobs/stuttgart-sachbearbeiter-logistik-121084278',
      country: 'DE',
      hotnessScore: 2.4865362811791383,
    },
    {
      id: 119980032,
      url: 'https://www.xing.com/jobs/merzig-betriebswirt-rendant-120990970',
      country: 'DE',
      hotnessScore: 2.2549019607843137,
    },
    {
      id: 120134291,
      url: 'https://www.xing.com/jobs/berlin-facility-manager-121133425',
      country: 'DE',
      hotnessScore: 2.229591836734694,
    },
    {
      id: 119893399,
      url: 'https://www.xing.com/jobs/butzbach-mitarbeiter-finance-accounting-teilzeit-20-25-std-121194547',
      country: 'DE',
      hotnessScore: 1.9788359788359788,
    },
    {
      id: 119950616,
      url: 'https://www.xing.com/jobs/hueckelhoven-kaufmaennischer-leiter-121129691',
      country: 'DE',
      hotnessScore: 1.7959183673469385,
    },
  ],
};
