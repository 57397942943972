/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconPartTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconPartTime"
    {...props}
  >
    <g clipPath="url(#clip0_6822_103)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 4a8 8 0 0 0-7.936 9.018l-1.984.252Q2 12.645 2 12C2 6.477 6.477 2 12 2c2.3 0 4.422.778 6.112 2.084l-1.224 1.583A7.96 7.96 0 0 0 12 4m1 3v3.132l1.445-.964 1.11 1.664L11 13.868V7zm5.375.166c.381.502.704 1.05.96 1.634l1.832-.8a10 10 0 0 0-1.2-2.044zm1.564 3.84a8 8 0 0 1 0 1.988l1.985.246a10 10 0 0 0 0-2.48zM5.63 16.84a8 8 0 0 1-1.003-1.73l-1.842.78c.328.775.75 1.501 1.254 2.162zm13.708-1.649a8 8 0 0 1-.955 1.633l1.595 1.207a10 10 0 0 0 1.194-2.04zm-2.498 3.18a8 8 0 0 1-1.73 1.002l.78 1.842a10 10 0 0 0 2.16-1.253zM8.905 19.38a8 8 0 0 1-1.733-1l-1.208 1.594c.662.502 1.39.923 2.167 1.25zm4.093.558a8 8 0 0 1-1.993 0l-.246 1.986a10 10 0 0 0 2.486 0z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_6822_103">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconPartTime;
