import styled from 'styled-components';

import { mediaConfined, mediaXWide } from '@xing-com/layout-tokens';
import { scale170, scale190, scale210, scale230 } from '@xing-com/tokens';
import { GridContainer } from '@xing-com/xing-grid';

export const StyledSection = styled.section`
  padding: ${scale170} 0;

  @media ${mediaConfined} {
    padding: ${scale210} 0;
  }
`;

export const StyledGridContainer = styled(GridContainer)`
  @media ${mediaConfined} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: ${scale190};
  }

  @media ${mediaXWide} {
    gap: ${scale230};
  }
`;
