/**
 * https://new-work.atlassian.net/browse/XMS-8283
 * This is a fix to ensure that our tracking fetch calls are
 * omitting the sub domain from the hostname.
 *
 * Example:
 * Transforms login.preview.xing.com to preview.xing.com
 *
 * TBD: Move tracking to endpoints to NW1
 */

const XB_HOST = '.oenv.xing.com';
const PREVIEW_HOST = 'login.preview.xing.com';
const PRODUCTION_HOST = 'login.xing.com';

const getXbName = (): string => {
  const partsBeforeXingboxIdentifier = location.hostname
    .split(XB_HOST)[0]
    .split('.');
  const lastItemIndex = partsBeforeXingboxIdentifier.length - 1;
  return partsBeforeXingboxIdentifier[lastItemIndex];
};

export const getTrackingHost = (): string => {
  const hostname = location.hostname;

  if (hostname.includes(XB_HOST)) {
    return `${location.protocol}//${getXbName()}${XB_HOST}`;
  }

  if (hostname === PREVIEW_HOST) {
    return `${location.protocol}//preview.xing.com`;
  }

  if (hostname === PRODUCTION_HOST) {
    return `${location.protocol}//www.xing.com`;
  }

  return '';
};
